import axios from 'axios'
import types from './ActionTypes'
import { getHmacTokenAndTimestamp } from './helper/hmacHelper'

const moment = require('moment-timezone')

let hmacTokenAndTimestamp = getHmacTokenAndTimestamp()
const http = axios.create({
  baseURL: '/',
  //baseURL: 'http://localhost:3000/dev/',
  responseType: 'json',
})

const API = {}
let headers = {
  Accept: 'application/json',
  'Asurion-Client': 'VODAFONE',
  'Asurion-channel': 'SitePortal',
  'Asurion-enduser': '',
  'Asurion-lineofbusiness': 'MOBILITY',
  'Asurion-region': 'APAC-SEA',
  'Content-Type': 'application/json',
  'x-api-key': process.env.API_KEY,
  'Asurion-Application': process.env.REACT_APP_TYPE,
}
API[types.GET_MASTERDATA_REQUEST] = (tokenHeaders) =>
  http.get('api/store/v3/masterdata', {
    headers: (headers = Object.assign({}, headers, {
      'x-asurion-cognito': tokenHeaders && tokenHeaders.IdToken,
      'Asurion-enduser': tokenHeaders && tokenHeaders.StoreAgentName,
    })),
  })

//API[types.GET_RESOURCE_REQUEST] = (data) => http.get('api/store/v3/masterdata', data, { headers })

API[types.FIND_TRADE_INFO_REQUEST] = (tradeid) => http.get(`api/v3/findtradeinfo/1C96EEDDC75811EA94010A787515D736/${tradeid}`, { headers })

API[types.VALIDATE_CUSTOMER_REQUEST] = (data) => http.post('api/store/v3/getelibilitydetails', data, { headers })

API[types.VALIDATE_CUSTOMER_REQUEST_V4] = (data) => http.post('api/store/v4/getelibilitydetails', data, { headers })

API[types.GET_CITY_STATE_REQUEST] = (data) => http.get(`api/v3/citystatezip/${data}`, { headers })

API[types.CHANGE_PASSWORD_REQUEST] = (data) =>
  http.post('/api/store/v3/changetemppassword', data, {
    headers: { ...headers, 'x-ct-authorization': hmacTokenAndTimestamp.hmacToken, 'x-ct-timestamp': hmacTokenAndTimestamp.hmacTimestamp },
  })

API[types.LOGIN_REQUEST] = (data) =>
  http.post('/api/store/v3/login', data, {
    headers: { ...headers, 'x-ct-authorization': hmacTokenAndTimestamp.hmacToken, 'x-ct-timestamp': hmacTokenAndTimestamp.hmacTimestamp },
  })

API[types.SUBMIT_TRADE_INFO] = (data) => http.put('api/store/v3/submittradeinfo', data, { headers })

API[types.GET_IMEI_VERIFICATION_REQUEST] = (tradeid) => http.get(`api/store/v3/imeiverifications/1C96EEDDC75811EA94010A787515D736/${tradeid}`, { headers })

API[types.GET_LOGISTICES_REQUEST] = (data) => http.post('api/store/v3/getdevicelist', data, { headers })

API[types.GET_LOGISTICES_BATCH_REQUEST] = (data) => http.post('api/store/v3/getbatchdetails', data, { headers })

API[types.CREATE_BATCH_REQUEST] = (data) => http.post('api/store/v3/createbatch', data, { headers })

API[types.CREATE_PARCEL_REQUEST] = (data) => http.post('api/parcel/v3/create', data, { headers })

API[types.UPDATE_STORE_REQUEST] = (data) => http.put('api/store/v3/updatestore', data, { headers })

API[types.GET_SEARCH_ITEMS_REQUEST] = (data) => http.post('api/store/v3/getdevicelist', data, { headers })

API[types.GET_FILE_REQUEST] = (data) => http.get('api/store/v3/tradeincarecreditreport', { headers })

API[types.DOWNLOAD_FILE_REQUEST] = (data) => http.get(`api/store/v3/filedownload/${data}`, { headers })

API[types.CREATE_CONTACT_POINT_REQUEST] = (data) => http.put(`api/store/v3/accounts`, data, { headers })

API[types.UPLOAD_FILE_REQUEST] = (data) => http.post(`api/store/v3/fileupload`, data, { headers })

API[types.PUSH_NOTIFICATION_REQUEST] = (data) => http.post('/api/store/v3/sendpushnotification', data, { headers })

API[types.SEND_EMAIL_LABEL_REQUEST] = (data) => http.get(`api/store/v3/sendemail/${data}`, { headers })

API[types.FORGORT_PASSWORD_REQUEST] = (data) =>
  http.post('api/store/v3/forgotpassword', data, {
    headers: { ...headers, 'x-ct-authorization': hmacTokenAndTimestamp.hmacToken, 'x-ct-timestamp': hmacTokenAndTimestamp.hmacTimestamp },
  })

API[types.CONFIRM_PASSWORD_REQUEST] = (data) =>
  http.post('api/store/v3/confirmpassword', data, {
    headers: { ...headers, 'x-ct-authorization': hmacTokenAndTimestamp.hmacToken, 'x-ct-timestamp': hmacTokenAndTimestamp.hmacTimestamp },
  })

API[types.GET_ID_DOCES_REQUEST] = (data) => http.get(`api/store/v3/getiddocs/${data}`, { headers })

API[types.CREATE_BATCH_ONSUBMIT] = (data) => http.post('api/store/v3/createbatch', data, { headers })

API[types.CREATE_PARCEL_ONSUBMIT_REQUEST] = (data) => http.post('api/parcel/v3/create', data, { headers })

API[types.GET_REPORTING_DETAILS_REQUEST] = (data) => http.post('api/store/v3/getreportsummary', data, { headers })

API[types.GET_EXCEPTION_DETAILS_REQUEST] = (data) => http.get(`api/store/v3/getexceptiondetails/${data}`, { headers })

API[types.GET_EXCEPTION_DETAILS_REQUEST] = (data) =>
  http.get(
    `api/store/v3/getescalations?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&tradeId=${data.tradeId}&startDate=${data.startDate}&endDate=${data.endDate}&escalationCode=${data.escalationCode}&agentId=${data.agentId}`,
    { headers }
  )

API[types.GET_DAX_IMAGE_REQUEST] = (data) => http.get(`api/store/v3/getdaximageurls?redemptionOrderId=${data}`, { headers })

API[types.UDPATE_EXCEPTION_ACTION_REQUEST] = (data) => http.put(`api/store/v3/updateaction`, data, { headers })
export default API
