const ExportVelocityColumns = [{
    header: 'Trade Id',
    key: 'TradeId',
    type: 'string'
},
{
    header: 'Raised Location',
    key: 'RaisedLocation',
    type: 'string'
},
{
    header: 'Primary Device IMEI',
    key: 'PrimaryDeviceIMEI',
    type: 'number'
},
{
    header: 'Secondary Device IMEI',
    key: 'SecondaryDeviceIMEI',
    type: 'number'
},
{
    header: 'Make',
    key: 'Make',
    type: 'string'
},
{
    header: 'Model',
    key: 'Model',
    type: 'string'
},
{
    header: 'Submitted Date',
    key: 'SubmittedDate',
    type: 'date'
},
{
    header: 'Last Updated',
    key: 'LastUpdated',
    type: 'string'
},
{
    header: 'Current Status',
    key: 'CurrentStatus',
    type: 'string'
},
{
    header: 'Submitted Location',
    key: 'CurrentLocation',
    type: 'string'
},
{
    header: 'Team Member Name',
    key: 'SubmmitedBy',
    type: 'date'
},
{
    header: 'Submitted At',
    key: 'SubmittedAt',
    type: 'string'
},
{
    header: 'Consignment Details',
    key: 'ConsignmentDetails',
    type: 'string'
},
{
    header: 'Consignment Return Details',
    key: 'ReturnConsignmentDetails',
    type: 'string'
},
{
    header: 'Job Type',
    key: 'JobType',
    type: 'string'
},
{
    header: 'Customer Tenure Credit',
    key: 'CustomerTenureCredit',
    type: 'string'
},
{
    header: 'Date Received',
    key: 'DateReceivedAtWarehouse',
    type: 'string'
},
{
    header: 'Service Order ID',
    key: 'ServiceOrderID',
    type: 'string'
},
{
    header: 'Parent Account Name',
    key: 'ParentAccountName',
    type: 'string'
},
{
    header: 'Store Code',
    key: 'StoreCode',
    type: 'string'
},
{
    header: 'IMEI In',
    key: 'IMEIIn',
    type: 'string'
},
{
    header: 'Total Credit',
    key: 'TotalCredit',
    type: 'string'
},
{
    header: 'Date Assessed',
    key: 'DateAssessed',
    type: 'string'
},
{
    header: 'Payment',
    key: 'PaymentToVHA',
    type: 'string'
},
{
    header: 'Store Name',
    key: 'StoreName',
    type: 'string'
},
{
    header: 'IMEI In Manufracturer',
    key: 'IMEIInManufracturer',
    type: 'string'
},
{
    header: 'Credit Application Period',
    key: 'CreditApplicationPeriod',
    type: 'string'
},
{
    header: 'Assessment Outcome',
    key: 'AssessmentOutcome',
    type: 'string'
},
{
    header: 'Payment Date',
    key: 'PaymentToVHADate',
    type: 'string'
},

{
    header: 'Team Member Name',
    key: 'TeamMemberName',
    type: 'string'
},
{
    header: 'IMEI IN Model / Varient',
    key: 'IMEIInModel',
    type: 'string'
},
{
    header: 'Batch Number',
    key: 'BatchNumber',
    type: 'string'
},
{
    header: 'Fault Code',
    key: 'FaultCode',
    type: 'string'
},
{
    header: 'Payment Varience',
    key: 'PaymentVariance',
    type: 'string'
},
{
    header: 'Team Member ID',
    key: 'TeamMemberID',
    type: 'string'
},
{
    header: 'IMEI Out',
    key: 'IMEIOut',
    type: 'string'
},
{
    header: 'Fault Reason',
    key: 'FaultReason',
    type: 'string'
},
{
    header: 'Sub Channel Name',
    key: 'SubChannelName',
    type: 'string'
},
{
    header: 'Current Job Status',
    key: 'CurrentJobStatus',
    type: 'string'
},
{
    header: 'IMEI Out Manufacturer',
    key: 'IMEIOutManufracturer',
    type: 'string'
},
{
    header: 'Validation Required',
    key: 'ValidationRequired',
    type: 'string'
},
{
    header: 'Days Pending in Store',
    key: 'DaysPendingInStore',
    type: 'string'
},
{
    header: 'Parent Code',
    key: 'ParentCode',
    type: 'string'
},
{
    header: 'Current Location',
    key: 'CurrentLocation',
    type: 'string'
},
{
    header: 'IMEI Out Model / Varient',
    key: 'IMEIOutModel',
    type: 'string'
},
{
    header: 'Date Created',
    key: 'DateCreated',
    type: 'string'
},
{
    header: 'Days Pending in Transit',
    key: 'DaysPendingInTransit',
    type: 'string'
},
{
    header: 'Region',
    key: 'Region',
    type: 'string'
},
{
    header: 'Customer Phone Mobile',
    key: 'CustomerPhoneMobile',
    type: 'string'
},
{
    header: 'Quote Price',
    key: 'QuotePrice',
    type: 'string'
},
{
    header: 'Date Batched',
    key: 'DateBatched',
    type: 'string'
},
{
    header: 'Days Pending Assessment',
    key: 'DaysPendingInAssessment',
    type: 'string'
},
{
    header: 'Trading Name',
    key: 'TradingName',
    type: 'string'
},
{
    header: 'Customer BAN',
    key: 'CustomerBAN',
    type: 'string'
},
{
    header: 'Bonus Credit',
    key: 'BonusCredit',
    type: 'string'
},
{
    header: 'Date Lodged for Pickup',
    key: 'DatePickupLodged',
    type: 'string'
},
{
    header: 'Overall Aging',
    key: 'OverallAging',
    type: 'string'
},
{
    header: 'Reporting State',
    key: 'ReportingState',
    type: 'string'
},
{
    header: 'App Condition',
    key: 'AppCondition',
    type: 'string'
},
{
    header: 'Graded Condition',
    key: 'GradedCondition',
    type: 'string'
},
{
    header: 'Graded Price',
    key: 'GradedPrice',
    type: 'string'
}
];

export default ExportVelocityColumns;