import React, { Component } from 'react';
import Files from 'react-files';

class FileUploader extends Component {
    constructor(context, props) {
        super(context, props);
        this.state = {
            filesCount: 0
        }
    }
    onFilesError(error) {
         const { actions } = this.props;
        actions.uploadFileError({
            error: error.message
        });
    }

    onFilesChange(files) {
        const { selectedFiles} = this.props;
        const newFiles = files.slice(this.state.filesCount);
        let numberOfFiles = selectedFiles && selectedFiles.length;

        newFiles.forEach(file => {
            let filePresent = false;
            selectedFiles && selectedFiles.forEach(f => {
                if (f.name === file.name && f.fileType === file.type) {
                    filePresent = true;
                }
            })
            if (!filePresent && numberOfFiles < 3) {
                numberOfFiles++;
                this.readFileAsDataURL(file);
            }
            if (selectedFiles && selectedFiles.length===3 && files && files.length > 3) {
                let error= {
                    message: "Can't upload more than 3 files"
                }
                this.onFilesError(error)
            }
        })

        this.setState({
            filesCount: files.length
        });
    }

    validFileExtension(fileExtension) {
         const { actions } = this.props;
        const validFileExtension = ['jpg', 'jpeg', 'csv', 'pdf', 'xls', 'xlsx', 'doc', 'docs', 'docx'];

        const invalidFileExtension = ['bat', 'exe'];
        if (invalidFileExtension.includes(fileExtension)) {
            actions.uploadFileError({
                error: `Invalid file type ${fileExtension}`
            });
            return false;
        }
        return true;
    }
    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    readFileAsDataURL(file) {
         const { actions } = this.props;
        let fileSize = this.bytesToSize(file.size);
        const reader = new FileReader();
        reader.onload = (upload) => {
            if (this.validFileExtension(file.extension)) {
                actions.uploadFileRequest({
                    //fileId: file.id,
                    FileName: file.name,
                    FileType: file.type,
                    //preview: file.preview,
                    //fileSize: fileSize,
                    Data: upload.target.result
                });
            }
        };
        reader.readAsDataURL(file);
    }

   removeAllFiles(e) {
        const { actions } = this.props;
        actions.resetUploadFile();
        e.stopPropagation();
    }

    removeSingleFile(e, index) {
        const { actions } = this.props;
        const files = selectedFiles.slice();
        files.splice(index, 1);
        actions.updateSelectedFiles(files);
        e.stopPropagation();
    }

    render() {
        return (
            <div className="file-upload">
                <div className="file-upload-header">
                </div>
                <div className="spacing"></div>

                <div className="fileDropArea">
                    <Files
                        ref='files'
                        className = 'files-dropzone-list '
                        dropActiveClassName='files-dropzone-active'
                        onChange={(e) => this.onFilesChange(e)}
                        onError={(e) => this.onFilesError(e)}
                        multiple
                        maxFileSize={1100000}
                        minFileSize={0}
                        clickable>
                        <div className="spacing">Upload</div>
                    </Files>
                </div>

            </div>
        )
    }
}
export default FileUploader;