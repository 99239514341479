import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import cross from '../../assets/ic-cross.png'
import LabeledInputField from '../../utils/custom-fields'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useContext, useEffect, useState } from 'react'

const actionPopUp = (props) => {
    const { state, dispatch } = useContext(store)
    const actions = useActions(state, dispatch)

    const contentStyleForDesktopView = {
        padding: '0px',
        alignSelf: 'center',
        width: '400px',
        height: '280px',
        "border-radius": '16px'
      }
      const { selectedTradeId, exceptionAction, exceptionList } = state
     // const exceptionActionList = exceptionAction && exceptionAction.exceptionList

      const { openActionPopUp } = props
      const { register, errors, triggerValidation, handleSubmit, formState } = useForm({ mode: 'onChange' })
    
      const closeActionPopUpOnNo = () => {
        exceptionList && exceptionList.map( (data) => {
          if(data.TradeId === selectedTradeId){
               data.actionException = ""
          }
           return data
      })
       actions.setExceptionAction(exceptionList)
        actions.closeActionPopUp()
      }

      const closeActionPopUpOnYes = () => {
        exceptionList && exceptionList.map( (data) => {
          if(data.TradeId === selectedTradeId){
            let  requestAction = {
              "UpdateActionRequest": {
                  "TradeInEscalationId": data.TradeInEscalationId,
                  "TradeInEscalationResolutionCode": data.actionException === 'Return To Customer' ? 'RTN' : 'AC'
              }
          }
            actions.updateAction({requestAction})
          }
           return data
      })
        actions.closeActionPopUp()
      } 
      return (
        <Popup
          contentStyle={contentStyleForDesktopView}
          open={openActionPopUp}
          closeOnDocumentClick={false}
          closeOnEscape={false}
          repositionOnResize={false}
          onClose={() => closeActionPopUpOnYes()}
          modal
        >
          <div>
            <div className="cross pos-rel cursor-pointer mt-15 mr-15" id="cross-img">
              <img src={cross} onClick={() => closeActionPopUpOnNo()} />
            </div>
            <div>
              <h1 className="popUp-heading pt-50">Disclaimer</h1>
              <div className="info">
                <div className="flex-container-exceptionpopup">
                  <div>
                    <label className="text-20 font-VodafoneRgBd pl-60">Are you sure you want to continue?</label>
                  </div>
                </div>
                <div className="actions-createitem mt-20">
                  <button className="btn-cancel black-button" onClick={() => closeActionPopUpOnNo()}>
                    No
                  </button>
                  <button
                    className='purple-button btn-search'
                    onClick={() => closeActionPopUpOnYes()}
                     >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup >
      )
}

export default actionPopUp