import React, { useContext, useEffect } from 'react';
import Popup from 'reactjs-popup'
import cross from '../../assets/ic-cross.png'
import { store } from '../../Store';


const AdditionalMessagePopUp = (props) => {

  const contentStyleForDesktopView = {
    padding: '0px',
    alignSelf: 'center',
    width: 'auto',
  }

  const { state } = useContext(store);
  const { additionalMsgPopUpContent, addMessageFlag, additionalMsg, resources } = state;
  const { additionalMsgPopUpOpen, actions } = props
  // if (additionalMsgPopUpContent === "") {
  //   actions.setAddMessageFlag(false);
  // }
  const setAddMessageFlag = (additionalMsg) => {
    actions.additionalMsgPopUpClose()
    actions.setAddMessageFlag(
      {
        additionalMsg: additionalMsg,
        addMessageFlag: true
      })
  }
  const additionalMsgPopUpClose = () => {
    actions.additionalMsgPopUpClose()
    actions.addPopUpData(additionalMsgPopUpContent)
  }
  return (
    <Popup
      contentStyle={contentStyleForDesktopView}
      open={additionalMsgPopUpOpen}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      repositionOnResize={false}
      onClose={() => additionalMsgPopUpClose()}
      modal>

      <div className="feature-modal-pop-up">
        <div class="cross">
          <img src={cross} onClick={() => additionalMsgPopUpClose()} />
        </div>
        <div className="featureinnerContent">
          <h1 className="feature-popUp-heading text-32 leading-37">{addMessageFlag ? 'Edit message' : 'Additional message'}</h1>
          <div className="feature-info">
            <textarea
              className="feature-textArea p-10"
              id='text'
              name="additionalMsgPopUpContent"
              placeholder="Type your message here"
              value={additionalMsg}
              onChange={(e) => actions.addPopUpData(e.target.value)}>
            </textarea>
            {additionalMsg && additionalMsg.length > 50 ? <div className="error-text">{resources.VODAFONE_ADDITONAL_MSG_ERROR}</div> : null}
            <div className="feature-actions">
              <button className="grey-button feature-buttons" onClick={() => additionalMsgPopUpClose()}>
                Cancel
              </button>
              <button
                className={`${((additionalMsg === "" || !additionalMsg) && !addMessageFlag) || (additionalMsg && additionalMsg.length > 50) ? 'disable-button' : 'purple-button'} feature-buttons`}
                disabled={((additionalMsg === "" || !additionalMsg) && !addMessageFlag) || (additionalMsg && additionalMsg.length > 50)}
                onClick={() => setAddMessageFlag(additionalMsg)}>
                {addMessageFlag ? 'Update' : 'Add'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
};
export default AdditionalMessagePopUp;
