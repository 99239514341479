import React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
const INVOKE_ONCE = true;

const ReportingCareCreditReport = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
let {Reports} = state
 useEffect(() => {
   actions.getReports()
 }, [INVOKE_ONCE])

const downloadFile=(fileName) =>{

  actions.downloadReport(fileName);
}

  return (
    <div className="ml-50 mr-50">
      <div class="flex items-center pt-50 ">
      </div>
              {
                    Reports && Reports.length > 0 ?
                        < table className = "thead mt-15 border border-gray-2 w-full h-50  text-xl font-normal  font-VodafoneRg " >
                            <tbody>
                                <tr className = "bg-grey-3 text-white h-50 text-left text-xl" >
                                    <th className="w-130 h-10 p-4 font-VodafoneLt">Trade In -Care Credit Report</th>
                                </tr>
                                {
                                   Reports.map((file, i) => {
                                         return (
                                            //(!file.IsDeleted)?
                                            <tr className = "text-left  h-10  border text-xl" >
                                              <td key={i} className="w-130 h-10 p-4 underline text-blue-800">
                                                  <span>
                                                      <a href="#" onClick={() =>downloadFile(file.FileName)}> {`${i + 1}. ${file.FileName}`}</a>
                                                  </span>
                                              </td>
                                            </tr>
                                            // :''
                                            );
                                      })
                                }
                            </tbody>
                        </table> : <div className="text-red-600 text-center text-2xl">No Data Found</div >
              }

    </div>
  )
}

export default ReportingCareCreditReport
