import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'
import downIcon from '../../assets/ic-chevron_down_sm.png'
import upIcon from '../../assets/ic-chevron_up_sm.png'

const moment = require('moment')
const invokeOnce = true;
const ReportSummary = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  let { ReportingDetailsInputFields, Login, masterReportSummaryData, masterReportData, reportDetailsSuccess } = state
  const ReportDetails = masterReportSummaryData;
  let GetReportSummaryRequest = {
    PartnerStoreId: (Login && Login.StoreAgentRole && Login.StoreAgentRole.toUpperCase() === 'STORE') ? Login.PartnerStoreId : "",
    TradeQuoteNbr: undefined,
    StartDate: undefined,
    EndDate: undefined,
    State: undefined,
    Status: undefined,
    StoreName: undefined,
    PageSize: 10,//sending just buz maindatory param
    PageNumber: 1,
  }
  useEffect(() => {
    actions.getReportingDetails({ GetReportSummaryRequest })
  }, [invokeOnce])

  let ConfirmedStatusData = ReportDetails && ReportDetails.filter(d => d.TradeInStatusCode && d.TradeInStatusCode.toUpperCase() === 'CONFIRMED' && d.TradeInMethod && d.TradeInMethod.toUpperCase() === 'IN-STORE')
  let BatchedStatusData = ReportDetails && ReportDetails.filter(d => d.TradeInStatusCode && d.TradeInStatusCode.toUpperCase() === 'BATCHED' && d.TradeInMethod && d.TradeInMethod.toUpperCase() === 'IN-STORE')

  let formattedData = function (filteredStatusData) {
    let UniqueStore = filteredStatusData &&
      [...new Set(filteredStatusData.map((item) => item.StoreName))]
    let FilteredData = [];
    UniqueStore && UniqueStore.map(d => {
      let StoreData = filteredStatusData && filteredStatusData.filter(c => c.StoreName === d)
      let DaysPendingInTransit = 0;
      let DaysPendingInAssessment = 0;
      let OverallAging = 0;
      StoreData && StoreData.map(data => {
        DaysPendingInTransit = DaysPendingInTransit + data.DaysPendingInTransit;
        DaysPendingInAssessment = DaysPendingInAssessment + data.DaysPendingInAssessment;
        OverallAging = OverallAging + data.OverallAging;
      })
      FilteredData.push({
        StoreName: d,
        JobInTransit: StoreData.length,
        DaysPendingInTransit: Math.round((DaysPendingInTransit / StoreData.length)),
        DaysPendingInAssessment: Math.round((DaysPendingInAssessment / StoreData.length)),
        OverallAging: Math.round((OverallAging / StoreData.length))
      })
    });
    return FilteredData;
  }

  let sum = function (key, array) {
    return array.reduce((a, b) => Number(a) + Number(b[key] || 0), 0);
  }

  let getMasterReportData = () => {
    const confirmFilteredData = formattedData(ConfirmedStatusData);
    const batchedFilteredData = formattedData(BatchedStatusData)
    let summaryData = [
      {
        OrderType: 'Unbatched Order',
        OrderDeatis: confirmFilteredData,
        JobInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? sum('JobInTransit', confirmFilteredData) : 0,
        DaysPendingInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('DaysPendingInTransit', confirmFilteredData)) / confirmFilteredData.length) : 0,
        DaysPendingInAssessment: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('DaysPendingInAssessment', confirmFilteredData)) / confirmFilteredData.length) : 0,
        OverallAging: (confirmFilteredData && confirmFilteredData.length > 0) ? Math.round((sum('OverallAging', confirmFilteredData)) / confirmFilteredData.length) : 0,
        isSelectedMasterView: false
      },
      {
        OrderType: 'Ready For Pick Up Order',
        OrderDeatis: batchedFilteredData,
        JobInTransit: (batchedFilteredData && batchedFilteredData.length > 0) ? sum('JobInTransit', batchedFilteredData) : 0,
        DaysPendingInTransit: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('DaysPendingInTransit', batchedFilteredData)) / batchedFilteredData.length) : 0,
        DaysPendingInAssessment: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('DaysPendingInAssessment', batchedFilteredData)) / batchedFilteredData.length) : 0,
        OverallAging: (batchedFilteredData && batchedFilteredData.length > 0) ? Math.round((sum('OverallAging', batchedFilteredData)) / batchedFilteredData.length) : 0,
        isSelectedMasterView: false
      },
      /* {
        OrderType: 'Completed Orders',
        OrderDeatis: confirmFilteredData,
        JobInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? (sum('JobInTransit', confirmFilteredData) +  sum('JobInTransit', batchedFilteredData)) : 0,
        DaysPendingInTransit: (confirmFilteredData && confirmFilteredData.length > 0) ? (Math.round((sum('DaysPendingInTransit', confirmFilteredData)) / confirmFilteredData.length) + Math.round((sum('DaysPendingInTransit', batchedFilteredData)) / batchedFilteredData.length)): 0,
        DaysPendingInAssessment: (confirmFilteredData && confirmFilteredData.length > 0) ? (Math.round((sum('DaysPendingInAssessment', confirmFilteredData)) / confirmFilteredData.length) + Math.round((sum('DaysPendingInAssessment', batchedFilteredData)) / batchedFilteredData.length)) : 0,
        OverallAging: (confirmFilteredData && confirmFilteredData.length > 0) ? (Math.round((sum('OverallAging', confirmFilteredData)) / confirmFilteredData.length) + Math.round((sum('OverallAging', batchedFilteredData)) / batchedFilteredData.length)) : 0,
        isSelectedMasterView: false
      }, */
    ];
    actions.setMasterViewData(summaryData);
  }

  let setSelectedMasterView = (OrderType) => {
    masterReportData && masterReportData.map((s, i) => {
      if (s.OrderType === OrderType) {
        s.isSelectedMasterView = !s.isSelectedMasterView;
      }
    })
    actions.setMasterViewData(masterReportData)
  }

  if (reportDetailsSuccess && ReportDetails && ReportDetails.length > 0 && masterReportData.length == 0) {
    getMasterReportData();
  }
  return (
    <div>
      {ReportDetails && ReportDetails.length > 0 &&
        masterReportData && masterReportData.length > 0 && (
          <div>
            <table class="mt-5 text-sm font-VodafoneRgbd w-full">
              <thead class=" bg-grey-4 text-white h-60 text-left w-full">
                <tr class="w-full h-60">
                  <th class="w-250"></th>
                  <th class="w-250 h-34  font-VodafoneRg text-14 leading-16 text-center">
                    Jobs In Transit
                    </th>
                  <th class="w-250 h-34   font-VodafoneRg text-14 text-center leading-16">
                    Days Pending in Transit
                    </th>
                  <th class=" h-34 font-VodafoneRg text-14 text-center leading-16">
                    Days Pending Assessment
                    </th>
                  <th class=" h-34  font-VodafoneRg text-14  text-center leading-16">
                    Overall Aging
                    </th>
                  <th class=" h-34  font-VodafoneRg text-14 text-center leading-16">
                  </th>
                </tr>
              </thead>
              {masterReportData &&
                masterReportData.map((s, index) => (
                  <tbody key={index} class="border border-gray-100">
                    <tr class=" text-sm h-50 bg-gray-1 border border-gray-1 ">
                      <td class="w-250 inline-flex font-VodafoneRg font-bold text-14 leading-16 m-15">
                        <span class="mr-10 ">{s.OrderType}</span>
                        <img class="inline-flex" onClick={() => setSelectedMasterView(s.OrderType)} src={s.isSelectedMasterView ? upIcon :  downIcon} />
                      </td>
                      <td class="w-250 h-10   text-sm font-bold text-center">{s.JobInTransit}</td>
                      <td class="w-250 h-10   text-sm font-bold text-center">{s.DaysPendingInTransit}</td>
                      <td class="w-250 h-10   text-sm font-bold text-center">{s.DaysPendingInAssessment}</td>
                      <td class="w-250 h-10   text-sm font-bold text-center">{s.OverallAging}</td>
                      <td class=" h-34 pl-67  font-VodafoneRg text-14 leading-16"></td>
                    </tr>
                    {s && s.isSelectedMasterView &&
                      s.OrderDeatis.map((d, idx) => (
                        <tr key={idx} class="text-sm  bg-white h-50 border border-gray-1">
                          <td class="w-280 font-VodafoneRg text-14 leading-16 text-right font-bold">{d.StoreName}</td>
                          <td class="w-250 h-10   text-sm  text-center">{d.JobInTransit}</td>
                          <td class="w-250 h-10   text-sm  text-center">{d.DaysPendingInTransit}</td>
                          <td class="w-250 h-10   text-sm  text-center">{d.DaysPendingInAssessment}</td>
                          <td class="w-250 h-10   text-sm  text-center">{d.OverallAging}</td>
                          <td class=" h-30 "></td>
                        </tr>
                      ))}
                  </tbody>
                ))}
            </table>
          </div>

        )
        //  : (
        //   <div className="mt-15">No Records Found</div>
        // )
      }
    </div>
  )
}

export default ReportSummary
