import types from './ActionTypes'

const useActions = (state, dispatch) => ({
  setEmailId: (data) => {
    dispatch({ type: types.SET_EMAIL_ID, payload: data })
  },
  openPopUp: () => {
    dispatch({ type: types.OPEN_POP_UP })
  },
  closePopUp: () => {
    dispatch({ type: types.CLOSE_POP_UP })
  },
  setPassword: (data) => {
    dispatch({ type: types.SET_PASSWORD, payload: data })
  },
  setCaptcha: (data) => {
    dispatch({ type: types.SET_CAPTCHA, payload: data })
  },
  setUserAuthentication: (data) => {
    dispatch({ type: types.SET_USER_AUTHENTICATION, payload: data })
  },
  setCustomerTradeId: (data) => {
    dispatch({ type: types.SET_CUSTOMER_TRADE_ID, payload: data })
  },
  setStoreName: (data) => {
    dispatch({ type: types.SET_STORE_NAME, payload: data })
  },
  findCustomerTradeInfo: (tradeid) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.FIND_TRADE_INFO_REQUEST, tradeid })
  },
  setMobileNumber: (data) => {
    dispatch({ type: types.SET_MOBILE_NUMBER, payload: data })
  },
  setBillingAccountNumber: (data) => {
    dispatch({ type: types.SET_BILLING_ACCOUNT_NUMBER, payload: data })
  },
  validateCustomerInfo: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.VALIDATE_CUSTOMER_REQUEST, payload: data })
  },
  validateCustomerInfoV4: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.VALIDATE_CUSTOMER_REQUEST_V4, payload: data })
  },
  setFirstName: (data) => {
    dispatch({ type: types.SET_FIRST_NAME, payload: data })
  },
  setLastName: (data) => {
    dispatch({ type: types.SET_LAST_NAME, payload: data })
  },
  setEmailAddress: (data) => {
    dispatch({ type: types.SET_EMAIL_ADDRESS, payload: data })
  },
  setAddressLine1: (data) => {
    dispatch({ type: types.SET_ADDRESS_LINE_1, payload: data })
  },
  setAddressLine2: (data) => {
    dispatch({ type: types.SET_ADDRESS_LINE_2, payload: data })
  },
  setAddressLine3: (data) => {
    dispatch({ type: types.SET_ADDRESS_LINE_3, payload: data })
  },
  setPostalCode: (data) => {
    dispatch({ type: types.SET_POSTAL_CODE, payload: data })
    if (data.length === 4) {
      dispatch({ type: types.SET_LOADING_INDICATOR })
      dispatch({ type: types.GET_CITY_STATE_REQUEST, data })
    }
  },
  setCityName: (data) => {
    dispatch({ type: types.SET_CITY_NAME, payload: data })
  },
  setSelectedState: (data) => {
    dispatch({ type: types.SET_SELECTED_STATE, payload: data })
  },
  setSelectedCountry: (data) => {
    dispatch({ type: types.SET_SELECTED_COUNTRY, payload: data })
  },
  getMasterdata: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_MASTERDATA_REQUEST, payload: data })
  },
  setPrimaryImeiCheck: (data) => {
    dispatch({ type: types.SET_PRIMARY_IMEI_CHECK, payload: data })
  },
  setSecondaryImeiCheck: (data) => {
    dispatch({ type: types.SET_SECONDARY_IMEI_CHECK, payload: data })
  },
  setDeviceModelCheck: (data) => {
    dispatch({ type: types.SET_DEVICE_MODEL_CHECK, payload: data })
  },
  setPhysicalResultCheck: (data) => {
    dispatch({ type: types.SET_PHYSICAL_RESULT_CHECK, payload: data })
  },
  setGoodWorkingOrderCheck: (data) => {
    dispatch({ type: types.SET_GOOD_WORKING_ORDER_CHECK, payload: data })
  },
  setCountryName: (data) => {
    dispatch({ type: types.SET_COUNTRY_NAME, payload: data })
  },
  setStateName: (data) => {
    dispatch({ type: types.SET_STATE_NAME, payload: data })
  },
  setOrderType: (data) => {
    dispatch({ type: types.SET_ORDER_TYPE, payload: data })
  },
  getResources: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_RESOURCE_REQUEST, payload: data })
  },
  setMobileNumberChecked: (data) => {
    dispatch({ type: types.SET_MDN_CHECKED, payload: data })
  },
  addFeatureOpenPopUp: (data) => {
    dispatch({ type: types.ADD_MSG_OPEN_POPUP, payload: data })
  },
  additionalMsgPopUpClose: (data) => {
    dispatch({ type: types.ADD_MSG_CLOSE_POPUP, payload: data })
  },
  addPopUpData: (data) => {
    dispatch({ type: types.ADD_POPUP_DATA, payload: data })
  },
  setAddMessageFlag: (data) => {
    dispatch({ type: types.SET_ADD_MESSAGE_FLAG, payload: data })
  },
  setBillingNumberChecked: (data) => {
    dispatch({ type: types.SET_BAN_CHECKED, payload: data })
  },
  setCustomerFormValidity: (data) => {
    dispatch({ type: types.SET_CUSTOMER_FORM_VALIDITY, payload: data })
  },
  setCaptureChecked: (data) => {
    dispatch({ type: types.SET_CAPTUREDETAILS_CHECKED, payload: data })
  },
  setTwelveMonthCredits: (data) => {
    dispatch({ type: types.SET_TWELVE_MONTHS_CREDIT, payload: data })
  },
  setTwentyFourMonthCredits: (data) => {
    dispatch({ type: types.SET_TWENTYFOUR_MONTHS_CREDIT, payload: data })
  },
  setThirtySixMonthCredits: (data) => {
    dispatch({ type: types.SET_THIRTYSIX_MONTHS_CREDIT, payload: data })
  },
  setServiceManagementNoChecked: (data) => {
    dispatch({ type: types.SET_SERVICE_MNGNMNT_NO_CHECK, payload: data })
  },
  setServiceManagementNumber: (data) => {
    dispatch({ type: types.SET_SERVICE_MNGMNT_NO, payload: data })
  },
  login: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.LOGIN_REQUEST, data })
  },
  changePassword: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CHANGE_PASSWORD_REQUEST, data })
  },
  setCurrentPassword: (data) => {
    dispatch({ type: types.SET_CURRENT_PASSWORD, payload: data })
  },
  setNewPassword: (data) => {
    dispatch({ type: types.SET_NEW_PASSWORD, payload: data })
  },
  setConfirmNewPassword: (data) => {
    dispatch({ type: types.SET_CONFIRM_NEW_PASSWORD, payload: data })
  },
  setInvalidPassword: (data) => {
    dispatch({ type: types.SET_INVALID_PASSWORD, payload: data })
  },
  submitTradeInfo: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SUBMIT_TRADE_INFO, data })
  },
  getLogistics: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_LOGISTICES_REQUEST, data })
  },
  setLogisticsData: (id) => {
    dispatch({
      type: types.SET_LOGISTICS_DATA,
    })
  },
  setLogisticsDataAll: (id) => {
    dispatch({
      type: types.SET_LOGISTICS_DATA_ALL,
    })
  },
  setOrderNumber: (data) => {
    dispatch({ type: types.SET_ORDER_NUMBER, payload: data })
  },
  setStartDate: (data) => {
    dispatch({ type: types.SET_START_DATE, payload: data })
  },
  setEndDate: (data) => {
    dispatch({ type: types.SET_END_DATE, payload: data })
  },
  setMake: (data) => {
    dispatch({ type: types.SET_MAKE, payload: data })
  },
  setModelVarriant: (data) => {
    dispatch({ type: types.SET_MODEL_VARRIANT, payload: data })
  },
  setCondition: (data) => {
    dispatch({ type: types.LOGISTICS_LBL_CONDITION, payload: data })
  },
  setStoreCode: (data) => {
    dispatch({ type: types.LOGISTICS_LBL_STORE_CODE, payload: data })
  },
  setLogisticsStore: (data) => {
    dispatch({ type: types.SET_LOGISTICS_STORE, payload: data })
  },
  login1: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_ITEM, data })
  },
  getImeiVerifications: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_IMEI_VERIFICATION_REQUEST, data })
  },
  setPrimaryIMEIStatus: (AMTA, FMIP) => {
    dispatch({ type: types.SET_PRIMARY_IMEI_STATUS, AMTA, FMIP })
  },
  setSecondaryIMEIStatus: (AMTA, FMIP) => {
    dispatch({ type: types.SET_SECONDARY_IMEI_STATUS, AMTA, FMIP })
  },
  navigateToSearch: () => {
    dispatch({ type: types.NAVIGATE_TO_SEARCH })
  },
  setLogisticsTab: (data) => {
    dispatch({ type: types.SET_LOGISTICS_TAB, payload: data })
  },
  setOrderDetailsTab: (data) => {
    dispatch({ type: types.SET_ORDER_DETAILS_TAB, payload: data })
  },
  setPartnerStoreCode: (data) => {
    dispatch({ type: types.SET_PARTNER_STORE_CODE, payload: data })
  },
  setPartnerStoreName: (data) => {
    dispatch({ type: types.SET_PARTNER_STORE_NAME, payload: data })
  },
  setSelectedPage: (data) => {
    dispatch({ type: types.SET_SELECTED_PAGE, payload: data })
  },
  createBatch: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_BATCH_REQUEST, data })
  },
  openOrCloseSpan: () => {
    dispatch({ type: types.SET_SPAN_OPEN_OR_CLOSE })
  },
  setIMEIStatus: (amta, fmip) => {
    dispatch({ type: types.SET_IMEI_STATUS, amta, fmip })
  },
  openCreateItemPopUp: () => {
    dispatch({ type: types.CREATE_SEARCH_OPEN_POPUP })
  },
  closeCreateItemPopUp: () => {
    dispatch({ type: types.CREATE_SEARCH_CLOSE_POPUP })
  },
  setSearchItemId: (data) => {
    dispatch({ type: types.SET_SEARCH_ITEM_ID, payload: data })
  },
  getSearchItems: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_SEARCH_ITEMS_REQUEST, data })
  },
  setSelectStoreName: (data) => {
    dispatch({ type: types.SET_SELECT_STORE_NAME, payload: data })
  },
  createParcelApi: (data, GetBatchDetailsRequest) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_PARCEL_REQUEST, data, GetBatchDetailsRequest })
  },
  setBatchNumber: (data) => {
    dispatch({ type: types.SET_BATCH_NUMBER, payload: data })
  },
  setBatchStartDate: (data) => {
    dispatch({ type: types.SET_BATCH_START_DATE, payload: data })
  },
  setBatchConsignmentNumber: (data) => {
    dispatch({ type: types.SET_BATCH_CONSIGNMENT_NUMBER, payload: data })
  },
  getLogisticsBatchData: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_LOGISTICES_BATCH_REQUEST, data })
  },
  setLogisticsBatchData: (data) => {
    dispatch({
      type: types.SET_LOGISTICS_BATCH_DATA,
      payload: data,
    })
  },
  UpdateStore: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.UPDATE_STORE_REQUEST, data })
  },
  setCreditTradeUp: (data) => {
    dispatch({ type: types.SET_CREDIT_TRADE_UP, payload: data })
  },
  setReportingTab: (data) => {
    dispatch({ type: types.SET_REPORTING_TAB, payload: data })
  },
  setCreditTradeIn: (data) => {
    dispatch({ type: types.SET_CREDIT_TRADE_IN, payload: data })
  },
  setBatchSelectedPage: (data) => {
    dispatch({ type: types.SET_BATCH_SELECTED_PAGE, payload: data })
    dispatch({ type: types.SET_CREDIT_TRADE_IN, payload: data })
  },
  setRecurring: (data) => {
    dispatch({ type: types.SET_RECURRING, payload: data })
  },
  downloadReport: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.DOWNLOAD_FILE_REQUEST, payload: data })
  },
  getReports: () => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_FILE_REQUEST })
  },
  uploadFileRequest: (file) => {
    dispatch({ type: types.UPLOAD_FILE, payload: file })
  },
  uploadFileError: (error) => {
    dispatch({ type: types.UPLOAD_FILE_ERROR, payload: error })
  },
  uploadFiles: (files) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.UPLOAD_FILE_REQUEST, payload: files })
  },
  backClick: () => {
    dispatch({ type: types.BACK_CLICK })
  },
  createContactPoint: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_CONTACT_POINT_REQUEST, payload: data })
  },
  setSendLabelCheck: (data) => {
    dispatch({ type: types.SET_SEND_LABEL_CHECK, payload: data })
  },
  pushNotification: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.PUSH_NOTIFICATION_REQUEST, payload: data })
  },
  sendEmailLabel: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.SEND_EMAIL_LABEL_REQUEST, payload: data })
  },
  setTradeIDNumber: (data) => {
    dispatch({ type: types.SET_TRADE_ID_NUMBER, payload: data })
  },
  forgotPasswordRequest: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.FORGORT_PASSWORD_REQUEST, data })
  },
  setVodafoneAgntID: (data) => {
    dispatch({ type: types.SET_VODAFONE_AGENT_ID, payload: data })
  },
  navigateToForgotPassword: () => {
    dispatch({ type: types.NAVIGATE_TO_FORGOT_PASSWORD })
  },
  setVerificationCode: (data) => {
    dispatch({ type: types.SET_VERIFICATION_CODE, payload: data })
  },
  confirmPassword: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CONFIRM_PASSWORD_REQUEST, data })
  },
  getIdDocs: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_ID_DOCES_REQUEST, payload: data })
  },
  setUpFront: (data) => {
    dispatch({ type: types.SET_UPFRONT, payload: data })
  },
  setNotificationFlag: () => {
    dispatch({ type: types.SET_NOTIFICATION_FLAG })
  },
  resetCustomerTradeIdField: (data) => {
    dispatch({ type: types.RESET_CUSTOMER_TRADE_ID_FIELD, payload: data })
  },
  setCreateItemsData: (data) => {
    dispatch({ type: types.SET_CREATE_ITEMS_DATA, payload: data })
  },
  setBatchItemsData: (data) => {
    dispatch({ type: types.SET_BATCH_ITEMS_DATA, payload: data })
    dispatch({ type: types.RESET_CUSTOMER_TRADE_ID_FIELD, payload: data })
  },
  createBatchOnsubmit: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_BATCH_ONSUBMIT, payload: data })
  },
  createParcelOnSubmit: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.CREATE_PARCEL_ONSUBMIT_REQUEST, payload: data })
  },
  setWorkingAs: (data) => {
    dispatch({ type: types.SET_WORKING_AS, payload: data })
  },
  setStoreAgentRole: (data) => {
    dispatch({ type: types.SET_STORE_AGENT_ROLE, payload: data })
  },
  setReportDetailsTab: (data) => {
    dispatch({ type: types.SET_REPORT_DETAILS_TAB, payload: data })
  },
  setReportCareCreditTab: (data) => {
    dispatch({ type: types.SET_REPORT_CARE_CREDIT_TAB, payload: data })
  },
  setReportPricingTab: (data) => {
    dispatch({ type: types.SET_REPORT_PRICING_TAB, payload: data })
  },
  setReportTradeIDNumber: (data) => {
    dispatch({ type: types.SET_REPORT_TRADE_ID_NUMBER, payload: data })
  },
  setReportIMEI: (data) => {
    dispatch({ type: types.SET_REPORT_IMEI, payload: data })
  },
  setReportStartDate: (data) => {
    dispatch({ type: types.SET_REPORT_START_DATE, payload: data })
  },
  setReportEndDate: (data) => {
    dispatch({ type: types.SET_REPORT_END_DATE, payload: data })
  },
  setReportState: (data) => {
    dispatch({ type: types.SET_REPORT_STATE, payload: data })
  },
  setReportStatus: (data) => {
    dispatch({ type: types.SET_REPORT_STATUS, payload: data })
  },
  setReportStoreName: (data) => {
    dispatch({ type: types.SET_REPORT_STORE_NAME, payload: data })
  },
  setReportStoreCode: (data) => {
    dispatch({ type: types.SET_REPORT_STORE_CODE, payload: data })
  },
  getReportingDetails: (data, Exportflag) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_REPORTING_DETAILS_REQUEST, data, Exportflag })
  },
  setOsotReportData: (data) => {
    dispatch({
      type: types.SET_OSOT_REPORT_DATA,
      payload: data,
    })
  },
  setShowReportingDetails: (data) => {
    dispatch({
      type: types.SET_SHOW_OSOT_REPORT_DATA,
      payload: data,
    })
  },
  setReportSelectedPage: (data) => {
    dispatch({ type: types.SET_REPORT_SELECTED_PAGE, payload: data })
  },
  setExceptionTab: (data) => {
    dispatch({ type: types.SET_EXCEPTION_TAB, payload: data })
  },
  setExceptionAction: (data) => {
    dispatch({ type: types.SET_EXCEPTION_ACTION, payload: data })
  },
  closeActionPopUp: (data) => {
    dispatch({ type: types.CLOSE_ACTION_POPUP, payload: data })
  },
  getExceptionDetails: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_EXCEPTION_DETAILS_REQUEST, data: data.GetExceptionRequest })
  },
  openActionPopUp: (data) => {
    dispatch({ type: types.OPEN_ACTION_POPUP, payload: data })
  },
  setExceptionTradeId: (data) => {
    dispatch({ type: types.SET_EXCEPTION_TRADEID, payload: data })
  },
  setExceptionStartDate: (data) => {
    dispatch({ type: types.SET_EXCEPTION_STARTDATE, payload: data })
  },
  setExceptionEndDate: (data) => {
    dispatch({ type: types.SET_EXCEPTION_ENDDATE, payload: data })
  },
  setEscalationCode: (data) => {
    dispatch({ type: types.SET_ESCALATION_CODE, payload: data })
  },
  getDAXImage: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.GET_DAX_IMAGE_REQUEST, data })
  },
  setOrderTab: (data) => {
    dispatch({ type: types.SET_ORDER_TAB, payload: data })
  },
  updateAction: (data) => {
    dispatch({ type: types.SET_LOADING_INDICATOR })
    dispatch({ type: types.UDPATE_EXCEPTION_ACTION_REQUEST, data: data.requestAction })
  },
  setReportSummaryTab: (data) => {
    dispatch({ type: types.SET_REPORT_SUMMARY_TAB, payload: data })
  },
  setCustomerResolutionFormValidity: (data) => {
    dispatch({ type: types.SET_CUSTOMER_RESOLUTION_FORM_VALIDITY, payload: data })
  },
  setMasterViewData: (data) => {
    dispatch({ type: types.SET_MASTER_VIEW_DATA, payload: data })
  },
})
export { useActions }
