import { HmacSHA512, SHA512, enc } from 'crypto-js'

import { Config } from '../config'

const createHash = (data) => {
  const sha512Data = SHA512(data)
  const encData = enc.Base64.stringify(sha512Data)
  return encData
}

const reverse = (data) => {
  return data.split('').reverse().join('')
}

export const getHmacTokenAndTimestamp = () => {
  const hmacTimestamp = new Date().getTime()
  const hmacData = createHash(Config.APP.client.toLowerCase()) + hmacTimestamp + createHash(reverse(Config.APP.applicationType.toLowerCase()))
  const hmacToken = HmacSHA512(hmacData, Config.SLS.hmacKey).toString()

  // console.log("hmacTimestamp", hmacTimestamp);
  // console.log(
  //   "createHash(Config.APP.client.toLowerCase())",
  //   createHash(Config.APP.client.toLowerCase())
  // );
  // console.log(
  //   "createHash(reverse(Config.APP.applicationType.toLowerCase())",
  //   createHash(reverse(Config.APP.applicationType.toLowerCase()))
  // );
  // console.log("Config.----", Config.APP.client.toLowerCase(),Config.APP.applicationType.toLowerCase());
  // console.log("reverse.----", createHash(reverse(Config.APP.applicationType.toLowerCase())));
  // console.log("client.----", createHash(Config.APP.client.toLowerCase()));
  // console.log("hmacToken.----", hmacToken);

  return { hmacTimestamp, hmacToken }
}