import React, { useContext, useEffect } from 'react'

const DeviceSummary = (props) => {
  const { DeviceList, actions, AssetCatalogName, TradeId, validCustomer, customerVerificationRequested, CustomerInfo, TradeInfo, orderType } = props
  const { ProceedTradeIn, ProceedTradeUp } = CustomerInfo
  const { TradeInStatus,TradeQuoteNbr } = TradeInfo
  const processTrade = orderType === 'Trade In' ? ProceedTradeIn : ProceedTradeUp
  const disabledForm = (!validCustomer && customerVerificationRequested) || processTrade === false || (TradeInStatus.toUpperCase() !== "OPEN" && TradeInStatus.toUpperCase() !== "CANCELLED") || customerVerificationRequested || orderType;

  return (
    <div className="Device-Summary justify-between items-center">
      <p className="font-VodafoneRgBd text-22 leading-26 mb-10 text-black">Device summary</p>
      <div className="flex flex-row">
        <div className="flex flex-col mr-38">
          <div className="imei-block">
            <span className="font-VodafoneRg text-15 leading-18 text-span">Trade ID</span>
            <p className="font-VodafoneRgBd text-15 leading-18 detail-span device-summary-margin">{TradeQuoteNbr}</p>
          </div>

          <div className="imei-block">
            <label className="Checkbox-Container mt-10">
              <input type="checkbox" id="devicemodel" name="deviceModelCheck" onChange={(e) => actions.setDeviceModelCheck(e.target.checked)} disabled={disabledForm} />
              <span className={`Checkbox-Mark ${disabledForm ? ' opacity-25 cursor-not-allowed' : ''}`} />
            </label>
            <span className="font-VodafoneRg text-15 leading-18 text-span mt-10">Device Model</span>
            <p className="font-VodafoneRgBd text-15 leading-18 detail-span">{AssetCatalogName}</p>
          </div>

        </div>
        <div className="flex flex-col">
          <div className="w-210">
            <label className="Checkbox-Container">
              <input type="checkbox" id="primaryIMEI" name="primaryImeiCheck" onChange={(e) => actions.setPrimaryImeiCheck(e.target.checked)} disabled={disabledForm} />
              <span className={`Checkbox-Mark ${disabledForm ? ' opacity-25 cursor-not-allowed' : ''}`} />
            </label>
            <span className="font-VodafoneRg text-15 leading-18 text-span w-150 h-5">Primary IMEI No.</span>
            <p className="font-VodafoneRgBd text-15 leading-18 detail-span">{DeviceList && DeviceList.PrimarySerialNbr}</p>
            {DeviceList && DeviceList.SecondarySerialNbr &&
              <div>
                <label className="Checkbox-Container">
                  <input type="checkbox" id="secondarymodel" name="secondaryImeiCheck" onChange={(e) => actions.setSecondaryImeiCheck(e.target.checked)} disabled={disabledForm} />
                  <span className={`Checkbox-Mark ${disabledForm ? ' opacity-25 cursor-not-allowed' : ''}`} />
                </label>
                <span className="font-VodafoneRg text-15 leading-18 text-span mt-10">Secondary IMEI No.</span>
                <p className="font-VodafoneRgBd text-15 leading-18 detail-span">{DeviceList && DeviceList.SecondarySerialNbr}</p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default DeviceSummary
