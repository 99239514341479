import React, { useContext, useEffect } from 'react';
import { store } from '../Store';
import img from '../assets/img-asurion.png';
import { useHistory } from 'react-router-dom'

const Footer = () => {
  const { state } = useContext(store);
  const { TradeInfoSearched, isError } = state
  let history = useHistory()
  let location= history.location
  let path = location && location.pathname
  let footerRextFlag = path === '/' || path === '/forgotpass' || path === '/changepass'
  return (
    <div className={`${!TradeInfoSearched ? 'footer' : ''} py-10 pl-50 bg-gray-1`}>
      {footerRextFlag &&
      <div className = 'mb-2' > IT IS AN OFFENSE TO CONTINUE ACCESS WITHOUT PROPER AUTHORISATION FROM VODAFONE HUTCHISON AUSTRALIA.
        VHA monitors all systems and workplaces
        for unauthorised access, improper use, user safety, and the security of physical and intellectual property. Authorised individuals may continuously monitor these systems by electronic or manual review of activity and logs. By using this system, you acknowledge that you understand and accept VHA's security and workplace policies.
      </div>
      }
      <img className="" src={img} alt="asurion-img" />
    </div>
  )
}
export default Footer
