import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { RenderSelectField } from '../../utils/custom-fields'
import tooltipIcon from '../../assets/ic-info_default.png'
import Dropdown from 'react-dropdown'
import { store } from '../../Store'

const WorkingOrder = (props) => {
  const { state, dispatch } = useContext(store)
  const {
    validCustomer,
    twelveMonthsCredit,
    twentyFourMonthsCredit,
    thirtySixMonthsCredit,
    customerVerificationRequested,
    CustomerInfo,
    TradeInfo,
    isCreditTradeUp,
    primaryAMTAStatus,
    primaryFMIPStatus,
    secondaryAMTAStatus,
    secondaryFMIPStatus,
    isUpFront,
    recerringCredit,
  } = state
  const { DeviceList, actions, orderType, DeviceDiagnosticsResult, enableOrderType, GWOPrice, NGWOPrice } = props
  const { TradeInStatus, CreditApplyPeriod, FinalCredit, TradeFulfillType, FinalPrice, AssetCatalogName, BonusCredit, Make } = TradeInfo
  const { ProceedTradeIn, ProceedTradeUp, TradeUpDevice, RemainingTerm, OneOffAmount } = CustomerInfo
  const amtaStatus = secondaryAMTAStatus ? (primaryAMTAStatus === 'PASSED' && secondaryAMTAStatus === 'PASSED' ? 'PASSED' : 'FAILED') : primaryAMTAStatus === 'PASSED' ? 'PASSED' : 'FAILED'
  const fmipStatus = secondaryFMIPStatus ? (primaryFMIPStatus === 'PASSED' && secondaryFMIPStatus === 'PASSED' ? 'PASSED' : 'FAILED') : primaryFMIPStatus === 'PASSED' ? 'PASSED' : 'FAILED'
  const tradeFullfillment = TradeFulfillType == 'TRADE-UP' ? 'Trade Up' : TradeFulfillType
  const OrderTypes = [
    {
      label: 'Trade In',
      value: 'Trade In',
    },
    {
      label: 'Trade Up',
      value: 'Trade Up',
    },
  ]
  const orderTypeValue = TradeFulfillType
  const TradeStatus = TradeInStatus && TradeInStatus.toUpperCase() !== 'OPEN' && TradeInStatus.toUpperCase() !== 'CANCELLED'
  const { register, errors, triggerValidation, handleSubmit } = useForm({ mode: 'onChange' })
  let totalCredit = FinalPrice
  let credit = TradeStatus ? FinalCredit : twelveMonthsCredit ? totalCredit / 12 : twentyFourMonthsCredit ? totalCredit / 24 : thirtySixMonthsCredit ? totalCredit / 36 : undefined
  let creditPeriod = TradeStatus ? CreditApplyPeriod : twelveMonthsCredit ? 12 : twentyFourMonthsCredit ? 24 : thirtySixMonthsCredit ? 36 : undefined
  let _BonusCredit = BonusCredit ? BonusCredit : 0
  let totalTradeUpCredit = FinalPrice + _BonusCredit
  const confirmedUpFront = TradeStatus ? (CreditApplyPeriod === 0 ? false : true) : false
  let creditTradeUp = TradeStatus ? totalTradeUpCredit / CreditApplyPeriod : RemainingTerm ? totalTradeUpCredit / RemainingTerm : 0
  // if (isCreditTradeUp === undefined && validCustomer) {
  //   actions.setCreditTradeUp(creditTradeUp);
  // }

  if (orderType === 'Trade Up' && recerringCredit === undefined && validCustomer) {
    actions.setRecurring(true)
  }
  let creditTradeUpPeriod = TradeStatus ? CreditApplyPeriod : RemainingTerm
  const creditApplyOverTradeUp = TradeStatus ? true : validCustomer ? true : false
  const processTrade = orderType === 'Trade In' ? ProceedTradeIn : ProceedTradeUp
  const disabledForm = (!validCustomer && customerVerificationRequested) || processTrade === false || TradeStatus

  const disabledLock =
    AssetCatalogName && (AssetCatalogName.includes('Apple') || AssetCatalogName.includes('APPLE') || AssetCatalogName.includes('apple')) ? (fmipStatus === 'FAILED' ? true : false) : false

  useEffect(() => {
    if (validCustomer && OneOffAmount) {
      actions.setUpFront(true)
    }
  }, [validCustomer, OneOffAmount])

  return (
    <div>
      <div className="flex flex-col justify-center Good-Working-ordr font-VodafoneRg w-320 xl:w-300 ">
        <div className="flex flex-col pr-30 xl:pr-29">
          <div className="mb-1">
            <label className="Checkbox-Container">
              <input
                type="checkbox"
                id="Good-Working-ordr"
                name="goodWorkingOrderCheck"
                disabled={!enableOrderType || customerVerificationRequested || amtaStatus === 'FAILED' || disabledLock}
                onChange={(e) => {
                  actions.setGoodWorkingOrderCheck(e.target.checked)
                }}
              />
              <span className={`Checkbox-Mark ${!enableOrderType || customerVerificationRequested || amtaStatus === 'FAILED' || disabledLock ? 'opacity-25 cursor-not-allowed' : ''}`} />
            </label>
            <span className="text-base leading-19 font-VodafoneRgBd text-span">
              {DeviceDiagnosticsResult && DeviceDiagnosticsResult.toUpperCase() === 'PASSED' ? 'Good Working order value' : 'Non Good Working order value'}
            </span>
          </div>
          <p className="leading-37 text-32 text-green-1 font-VodafoneRgBd ml-36">${totalCredit.toFixed(2)}</p>
        </div>
        <div className="flex flex-row pr-30">
          <div className="flex flex-col mt-4">
            {/* <div>
              <span className="text-base font-VodafoneRgBd leading-19 ml-36" htmlFor="ordertype">
                Order Type
              </span>
            </div> */}
            {/* <div className="working-order">
            <Dropdown
                      name="orderType"
                      className="ml-6 xl:ml-13"
                      disabled={!enableOrderType || customerVerificationRequested || amtaStatus === 'FAILED' || disabledLock}
                      register={register}
                      onChange={(e) => actions.setOrderType(e)}
                      options={OrderTypes}
                      errors={errors}
                      schema="OrderType"
                      arrowClosed={<span className="arrow-closed" />}
                      arrowOpen={<span className="arrow-open" />}
                      value={TradeStatus ? orderTypeValue : orderType}
            />
          </div> */}
            {/* <RenderSelectField
              name="orderType"
              label=""
              value={TradeStatus ? orderTypeValue : orderType}
              disabled={!enableOrderType || customerVerificationRequested || amtaStatus === 'FAILED' || disabledLock}
              className="ml-6 select_working_order"
              register={register}
              onChange={(e) => actions.setOrderType(e)}
              options={OrderTypes}
              optionsKey="TypeId"
              optionsValue="TypeName"
              errors={errors}
              schema="OrderType"
            /> */}
          </div>
        </div>
        {validCustomer || TradeStatus ? (
          <div>
            {orderType === 'Trade Up' && (
              <div>
                <div className="flex flex-row mt-10">
                  <div className="flex flex-col ml-36 mt-20">
                    <p className="text-14 leading-16 font-VodafoneRgBd inline-block">Make</p>
                    <p className="text-14 leading-16 font-VodafoneLt inline-block mt-1">{TradeInfo && TradeInfo?.Make}</p>
                  </div>
                </div>
                <div className="flex flex-row mt-10">
                  <div className="flex flex-col ml-36 mt-20">
                    <p className="text-14 leading-16 font-VodafoneRgBd inline-block">Model</p>
                    <p className="text-14 leading-16 font-VodafoneLt inline-block mt-1">{TradeUpDevice && TradeUpDevice?.ItemName}</p>
                  </div>
                </div>
                <div className="flex flex-row mt-10">
                  <div className="flex flex-col ml-36 mt-20">
                    <p className="text-14 leading-16 font-VodafoneRgBd inline-block">Primary IMEI</p>
                    <p className="text-14 leading-16 font-VodafoneLt inline-block mt-1">{TradeUpDevice && TradeUpDevice?.IMEI}</p>
                  </div>
                </div>
                {/* {TradeUpDevice && TradeUpDevice.SecondaryIMEI &&
                  <div className="flex flex-row mt-10">
                    <div className="flex flex-col ml-36 mt-20">
                      <p className="text-14 leading-16 font-VodafoneRgBd inline-block">Secondary IMEI</p>
                      <p className="text-14 leading-16 font-VodafoneLt inline-block mt-1">1927194791347</p>
                    </div>
                  </div>
                }*/}
                {TradeUpDevice && TradeUpDevice.Bonus !== undefined && TradeUpDevice.Bonus != 0 ? (
                  <div className="flex flex-row mt-10">
                    <div className="flex flex-col ml-36 mt-20">
                      <p className="text-14 leading-16 font-VodafoneRgBd inline-block">Bonus credit</p>
                      <p className="text-14 leading-16 font-VodafoneRgBd text-green-1 inline-block mt-1">+${TradeUpDevice && TradeUpDevice.Bonus}</p>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            <div>
              {TradeInfo && TradeInfo.BonusCredit !== undefined && TradeInfo.BonusCredit != 0 ? (
                <div className="flex flex-row mt-10">
                  <div className="flex flex-col ml-36 mt-20">
                    <p className="text-14 leading-16 font-VodafoneRgBd inline-block">Bonus credit</p>
                    <p className="text-14 leading-16 font-VodafoneRgBd text-green-1 inline-block mt-1">+${TradeInfo && TradeInfo.BonusCredit}</p>
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="flex flex-row mt-30 "> */}
            {/* <div className="flex flex-col ml-6">
                <p className="text-14 leading-16 font-VodafoneRgBd inline-block">Credit apply over</p>
                {orderType === 'Trade In' || TradeFulfillType === 'Trade In' ?
                  <div className="flex flex-row justify-between items-center text-center mt-10">
                    <div
                      className={`${disabledForm ? 'pointer-events-none opacity-25 ' : 'cursor-pointer '}${(twelveMonthsCredit === 'true' || CreditApplyPeriod === 12) ? 'rounded-5 border-purple-1 bg-purple-1' : 'bg-white border-purple-1 rounded-5'} w-60 border border-solid justify-between items-center text-center `}
                      onClick={() => actions.setTwelveMonthCredits('true')}
                      disabled={disabledForm}
                    >
                      <p className={`${(twelveMonthsCredit === 'true' || CreditApplyPeriod === 12) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mt-18 `}>12</p>
                      <p className={`${(twelveMonthsCredit === 'true' || CreditApplyPeriod === 12) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mb-18 `}>Months</p>
                    </div>
                    <div
                      className={`${disabledForm ? 'pointer-events-none opacity-25 ' : 'cursor-pointer '}${(twentyFourMonthsCredit === 'true' || CreditApplyPeriod === 24) ? 'rounded-5  border-purple-1 bg-purple-1' : 'bg-white border-purple-1 rounded-5'} w-60 border border-solid justify-between items-center teSxt-center mx-5`}
                      onClick={() => actions.setTwentyFourMonthCredits('true')}
                      disabled={disabledForm}
                    >
                      <p className={`${(twentyFourMonthsCredit === 'true' || CreditApplyPeriod === 24) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mt-18 `}>24</p>
                      <p className={`${(twentyFourMonthsCredit === 'true' || CreditApplyPeriod === 24) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mb-18 `}>Months</p>
                    </div>
                    <div
                      className={`${disabledForm ? 'pointer-events-none opacity-25 ' : ' cursor-pointer '}${(thirtySixMonthsCredit === 'true' || CreditApplyPeriod === 36) ? 'rounded-5  border-purple-1 bg-purple-1' : 'bg-white border-purple-1 rounded-5'} w-60 border border-solid justify-between items-center text-center `}
                      onClick={() => actions.setThirtySixMonthCredits('true')}
                      disabled={disabledForm}
                    >
                      <p className={`${(thirtySixMonthsCredit === 'true' || CreditApplyPeriod === 36) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mt-18 `}>36</p>
                      <p className={`${(thirtySixMonthsCredit === 'true' || CreditApplyPeriod === 36) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mb-18 `}>Months</p>
                    </div>
                  </div> :

                  <div className="flex flex-row justify-between items-center text-center mt-10">
                    <div
                      className={`${disabledForm ? 'pointer-events-none opacity-25 ' : ' '}${creditApplyOverTradeUp === true && recerringCredit === true ? 'rounded-5 border-purple-1 bg-purple-1 mt-10' : 'bg-white border-purple-1 rounded-5 mt-10'} h-70 w-70 mr-20 border border-solid justify-between items-center text-center `}
                      onClick={() => actions.setRecurring(true)}
                      disabled={disabledForm}
                    >
                      <p className={`${(creditApplyOverTradeUp === true && recerringCredit === true) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mt-25 `}></p>
                      <p className={`${(creditApplyOverTradeUp === true && recerringCredit === true) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mb-25 `}>Recurring</p>
                    </div>

                    {OneOffAmount || CreditApplyPeriod === 0 ?
                      <div
                        className={`${disabledForm ? 'pointer-events-none opacity-25 ' : ' '}${isUpFront === true || CreditApplyPeriod === 0 ? 'rounded-5 border-purple-1 bg-purple-1 mt-10' : 'bg-white border-purple-1 rounded-5 mt-10'} h-70 w-70 border border-solid justify-between items-center text-center `}
                        onClick={() => actions.setUpFront(true)}
                        disabled={disabledForm}
                      >
                        <p className={`${(isUpFront === true || CreditApplyPeriod === 0) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mt-25 `}></p>
                        <p className={`${(isUpFront === true || CreditApplyPeriod === 0) ? 'text-white' : 'text-purple-1'} leading-16 text-14 font-VodafoneRgBd mb-25 `}>Upfront</p>
                      </div> : null
                    }
                  </div>
                }
              </div> */}
            {/* </div> */}
          </div>
        ) : null}
        {(orderType === 'Trade In' || orderTypeValue === 'Trade In') && (twelveMonthsCredit || twentyFourMonthsCredit || thirtySixMonthsCredit || TradeStatus) ? (
          <div className="trade-credit mt-20 ">
            <p className="font-VodafoneRgBd text-17 leading-22 w-190">Total Trade in Credit</p>
            <div className="mt-1 flex flex-row">
              <div>
                <span className="mr-20 leading-26 text-22 text-green-1 font-VodafoneRgBd">+${totalCredit && totalCredit.toFixed(2)}</span>
              </div>
              <div className="flex flex-col pl-22 text-left">
                <span className="leading-26 text-22  text-green-1 font-VodafoneRgBd text-left">+${credit && credit.toFixed(2)}</span>
                <p className="leading-14 text-12 font-VodafoneRg text-left">over {creditPeriod} months remaining</p>
              </div>
            </div>
          </div>
        ) : null}
        {(orderType === 'Trade Up' || orderTypeValue.toUpperCase() === 'TRADE UP') && creditApplyOverTradeUp ? (
          <div className="trade-credit mt-20 ">
            <p className="font-VodafoneRgBd text-17 leading-22 w-190">Total Trade Up Credit</p>
            <div className="mt-1 flex flex-row">
              <div>
                <span className="mr-20 leading-26 text-22 text-green-1 font-VodafoneRgBd">+${totalTradeUpCredit && totalTradeUpCredit.toFixed(2)}</span>
              </div>
              {isUpFront === false || confirmedUpFront ? (
                <div className="flex flex-col pl-22 text-left">
                  <span className="leading-26 text-22  text-green-1 font-VodafoneRgBd text-left">+${creditTradeUp && creditTradeUp.toFixed(2)}</span>
                  <p className="leading-14 text-12 font-VodafoneRg text-left">over {creditTradeUpPeriod} months remaining</p>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default WorkingOrder
