import types from './ActionTypes'
const moment = require('moment-timezone')

const initialState = {
  openOrCloseSpan: false,
  isLoading: false,
  isError: false,
  initializeFlag: true,
  isUserAuthenticated: false,
  invalidCityState: false,
  LogisticsOrderDetailsTab: true,
  reportingSummaryTab: true,
  LogisticsInputField: {},
  Login: {},
  LogisticsBatchDetailsInputField: {},
  selectedPage: 0,
  logisticsBatchData: {},
  batchSelectedPage: 0,
  osotReportData: {},
  ReportingDetailsInputFields: {},
  reportSelectedPage: 0,
  masterReportData: [],
  reportDetailsSuccess: false,
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOADING_INDICATOR:
      return { ...state, ...{ isLoading: true } }
    case types.UNSET_LOADING_INDICATOR:
      return { ...state, ...{ isLoading: false } }
    case types.GET_MASTERDATA_SUCCESS:
      let PartnerStoreCode = state && state.Login && state.Login.PartnerStoreCode
      let filteredStore = action.data.Masterdata && action.data.Masterdata.Stores && action.data.Masterdata.Stores.filter((s) => s.PartnerStoreCode === PartnerStoreCode)
      let storeNameData = filteredStore && filteredStore[0] && filteredStore[0].PartnerStoreName
      return {
        ...state,
        ...{
          masterdata: action.data.Masterdata,
          partnerStoreName: storeNameData,
          PartnerStoreCodeNbr: PartnerStoreCode,
        },
      }
    case types.API_ERROR:
      return { ...state, ...{ isError: true, ErrorDetails: action.data } }
    case types.SET_EMAIL_ID:
      return { ...state, isForgotPswdClicked: false, Login: { ...state.Login, emailId: action.payload, vodafoneAgentId: action.payload } }
    case types.SET_VODAFONE_AGENT_ID:
      return { ...state, Login: { ...state.Login, vodafoneAgentId: action.payload } }
    case types.SET_PASSWORD:
      return { ...state, Login: { ...state.Login, password: action.payload } }
      case types.SET_CAPTCHA:
        return { ...state, Login: { ...state.Login, setCaptcha: action.payload } }
      case types.SET_CUSTOMER_TRADE_ID:
      return { ...state, ...{ customerTradeId: action.payload, TradeNotFound: false } }
    case types.SET_USER_AUTHENTICATION:
      return { ...state, ...{ isUserAuthenticated: true, initializeFlag: false } }
    case types.SET_STORE_NAME:
      return { ...state, ...{ storeId: action.payload } }
    case types.FIND_TRADE_INFO_REQUEST:
      return {
        ...state,
        ...{
          FileUploadSuccess: false,
          fileError: null,
          selectedFiles: [],
          TradeInfoSearched: false,
          disableStoreSelection: true,
          orderType: undefined,
          validCustomer: undefined,
          deviceModelCheck: undefined,
          goodWorkingOrderCheck: undefined,
          physicalResultCheck: undefined,
          primaryImeiCheck: undefined,
          secondaryImeiCheck: undefined,
          captureCheck: false,
          customerVerificationRequested: false,
          oneMonthsCredit: undefined,
          twelveMonthsCredit: undefined,
          twentyFourMonthsCredit: undefined,
          thirtySixMonthsCredit: undefined,
          isMDNChecked: false,
          isBANChecked: false,
          sericeManagementNoCheck: false,
          recerringCredit: undefined,
          customerValidationError: undefined,
          verificationSuccessMsg: undefined,
          verificationErrorMsg: undefined,
          additionalMsgPopUpContent: undefined,
          addMessageFlag: undefined,
          additionalMsg: undefined,
          invalidCityState: true,
          resetCustomerForm: true,
          EmailLabelSuccess: undefined,
          NotificationSuccess: undefined,
          isUpFront: undefined,
          idDocsSuccess: undefined,
          serviceManamgementNumber: undefined,
          mobileNumber: undefined,
          billingAccountNumber: undefined,
          BatchId: undefined,
        },
        CustomerInfo: {
          ...state.CustomerInfo,
          ProceedTradeIn: undefined,
          ProceedTradeUp: undefined,
          CustomerUpdatedInfo: {
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            postalCode: '',
            cityName: '',
            stateName: '',
            emailAddress: '',
          },
        },
      }
    case types.FIND_TRADE_INFO_SUCCESS:
      return {
        ...state,
        ...{
          TradeInfo: action.data,
          TradeInfoSearched: true,
          additionalMsgPopUpOpen: false,
          addMessageFlag: false,
          TradeNotFound: false,
          secondaryAMTAStatus: action.data.DeviceList.SecondaryAmtaStatus,
          secondaryFMIPStatus: action.data.DeviceList.SecondaryFmipStatus,
          primaryAMTAStatus: action.data.DeviceList.PrimaryAmtaStatus,
          primaryFMIPStatus: action.data.DeviceList.PrimaryFmipStatus,
        },
      }
    case types.FIND_TRADE_INFO_FAILURE:
      return { ...state, ...{ TradeInfoError: action.data, TradeInfoSearched: false, TradeNotFound: true } }
    case types.SET_MOBILE_NUMBER:
      return { ...state, ...{ mobileNumber: action.payload, verificationSuccessMsg: false, verificationErrorMsg: false } }
    case types.SET_BILLING_ACCOUNT_NUMBER:
      return { ...state, ...{ billingAccountNumber: action.payload, verificationSuccessMsg: false, verificationErrorMsg: false } }
    case types.VALIDATE_CUSTOMER_REQUEST:
      return { ...state, ...{ customerVerificationRequested: true } }
    case types.VALIDATE_CUSTOMER_REQUEST_V4:
      return { ...state, ...{ customerVerificationRequested: true } }
    case types.VALIDATE_CUSTOMER_SUCCESS:
      return { ...state, ...{ CustomerInfo: action.data, validCustomer: true, verificationSuccessMsg: true } }
    case types.VALIDATE_CUSTOMER_FAILURE:
      return { ...state, ...{ customerValidationError: action.data, validCustomer: false, verificationErrorMsg: true } }
    case types.SET_FIRST_NAME:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, firstName: action.payload } },
      }
    case types.SET_LAST_NAME:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, lastName: action.payload } },
      }
    case types.SET_ADDRESS_LINE_1:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, addressLine1: action.payload } },
      }
    case types.SET_ADDRESS_LINE_2:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, addressLine2: action.payload } },
      }
    case types.SET_ADDRESS_LINE_3:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, addressLine3: action.payload } },
      }
    case types.SET_POSTAL_CODE:
      return { ...state, CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, postalCode: action.payload } }, invalidCityState: false }
    case types.SET_CITY_NAME:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, cityName: action.payload } },
      }
    case types.SET_EMAIL_ADDRESS:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, emailAddress: action.payload } },
      }
    case types.SET_PRIMARY_IMEI_CHECK:
      return { ...state, ...{ primaryImeiCheck: action.payload } }
    case types.SET_SECONDARY_IMEI_CHECK:
      return { ...state, ...{ secondaryImeiCheck: action.payload } }
    case types.SET_DEVICE_MODEL_CHECK:
      return { ...state, ...{ deviceModelCheck: action.payload } }
    case types.SET_PHYSICAL_RESULT_CHECK:
      return { ...state, ...{ physicalResultCheck: action.payload } }
    case types.SET_GOOD_WORKING_ORDER_CHECK:
      return { ...state, ...{ goodWorkingOrderCheck: action.payload } }
    case types.GET_MASTERDATA_REQUEST:
      return { ...state, selectedFiles: [] }
    case types.OPEN_POP_UP:
      return { ...state, ...{ openConfirmationPopUp: true } }
    case types.SET_COUNTRY_NAME:
      return { ...state, ...{ verificationSuccessMsg: false, verificationErrorMsg: false }, CustomerInfo: { ...state.CustomerInfo, countryName: action.payload } }
    case types.SET_STATE_NAME:
      return { ...state, ...{ verificationSuccessMsg: false, verificationErrorMsg: false }, CustomerInfo: { ...state.CustomerInfo, stateName: action.payload } }
    case types.SET_SELECTED_STATE:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, stateName: action.payload } },
      }
    case types.SET_SELECTED_COUNTRY:
      return {
        ...state,
        ...{ verificationSuccessMsg: false, verificationErrorMsg: false },
        CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, countryName: action.payload } },
      }
    case types.CLOSE_POP_UP:
      return { ...state, ...{ openConfirmationPopUp: false } }
    case types.SET_ORDER_TYPE:
      return { ...state, ...{ orderType: action.payload.value } }
    case types.GET_RESOURCE_REQUEST:
      return { ...state }
    case types.GET_RESOURCE_SUCCESS:
      return { ...state, ...{ resources: action.data } }
    case types.SET_MDN_CHECKED:
      return { ...state, ...{ isMDNChecked: action.payload } }
    case types.ADD_MSG_OPEN_POPUP:
      return { ...state, ...{ additionalMsgPopUpOpen: true } }
    case types.ADD_MSG_CLOSE_POPUP:
      return { ...state, ...{ additionalMsgPopUpOpen: false } }
    case types.ADD_POPUP_DATA:
      return { ...state, ...{ additionalMsg: action.payload } }
    case types.SET_ADD_MESSAGE_FLAG:
      return {
        ...state,
        ...{
          addMessageFlag: action.payload.addMessageFlag,
          additionalMsgPopUpContent: action.payload.additionalMsg,
          additionalMsg: action.payload.additionalMsg,
        },
      }
    case types.SET_BAN_CHECKED:
      return { ...state, ...{ isBANChecked: action.payload } }
    case types.SET_CUSTOMER_FORM_VALIDITY:
      return { ...state, ...{ customerFormVailidity: action.payload } }
    case types.SET_CAPTUREDETAILS_CHECKED:
      return { ...state, ...{ captureCheck: action.payload, NotificationSuccess: undefined, verificationSuccessMsg: false } }
    case types.SET_TWELVE_MONTHS_CREDIT:
      return { ...state, ...{ twelveMonthsCredit: action.payload, twentyFourMonthsCredit: false, thirtySixMonthsCredit: false, oneMonthsCredit:false } }
    case types.SET_TWENTYFOUR_MONTHS_CREDIT:
      return { ...state, ...{ twentyFourMonthsCredit: action.payload, thirtySixMonthsCredit: false, twelveMonthsCredit: false, oneMonthsCredit:false } }
    case types.SET_THIRTYSIX_MONTHS_CREDIT:
      return { ...state, ...{ thirtySixMonthsCredit: action.payload, twelveMonthsCredit: false, twentyFourMonthsCredit: false, oneMonthsCredit:false } }
    case types.SET_SERVICE_MNGNMNT_NO_CHECK:
      return { ...state, ...{ sericeManagementNoCheck: action.payload } }
    case types.SET_SERVICE_MNGMNT_NO:
      return { ...state, ...{ serviceManamgementNumber: action.payload, verificationSuccessMsg: false, verificationErrorMsg: false } }
    case types.GET_CITY_STATE_REQUEST:
      return { ...state }
    case types.GET_CITY_STATE_SUCCESS:
      return {
        ...state,
        ...{
          cityStateResponse: action.data,
          invalidCityState: false,
          resetCustomerForm: false,
          CustomerInfo: {
            ...state.CustomerInfo,
            CustomerUpdatedInfo: {
              ...state.CustomerInfo.CustomerUpdatedInfo,
              cityName: '',
              // stateName: ""
            },
          },
        },
      }
    case types.GET_CITY_STATE_FAILURE:
      return {
        ...state,
        ...{
          cityStateError: action.data,
          invalidCityState: true,
          resetCustomerForm: false,
          CustomerInfo: { ...state.CustomerInfo, CustomerUpdatedInfo: { ...state.CustomerInfo.CustomerUpdatedInfo, cityName: '' } },
        },
      }
    case types.LOGIN_REQUEST:
      return { ...state }
    case types.LOGIN_SUCCESS:
      const loginSuccess = action.data && action.data.Status === 'success' ? true : false
      return {
        ...state,
        Login: {
          ...state.Login,
          Status: action.data && action.data.Status,
          AccessToken: action.data && action.data.AccessToken,
          IdToken: action.data && action.data.IdToken,
          RefreshToken: action.data && action.data.RefreshToken,
          StoreAgentName: action.data && action.data.StoreAgentName,
          PartnerStoreCode: action.data && action.data.PartnerStoreCode,
          PartnerStoreId: action.data && action.data.PartnerStoreId,
          PartnerParentStoreCode: action.data && action.data.PartnerParentStoreCode,
          StoreAgentRole: action.data && action.data.StoreAgentRole,
          PartnerStoreChannel: action.data && action.data.PartnerStoreChannel,
          AgentId: action.data && action.data.StoreId,
        },
        loginsuccess: loginSuccess,
      }
    case types.LOGIN_FAILURE:
      let response = action.data && action.data.response && action.data.response.data && action.data.response.data.LoginAPiResponse ? action.data.response.data.LoginAPiResponse : undefined
      return {
        ...state,
        ...{
          Login: {
            ...state.Login,
            Status: response ? response && response.Status : state.Login.Status,
            AccessToken: response ? response && response.AccessToken : state.Login.AccessToken,
            IdToken: response ? response && response.IdToken : state.Login.AccessTIdTokenoken,
            RefreshToken: response ? response && response.RefreshToken : state.Login.RefreshToken,
            CognitoError: response ? response && response.Error : state.Login.Error,
            setCaptcha: undefined,
          },
        },
      }
    case types.CHANGE_PASSWORD_REQUEST:
      return { ...state, ...{ isSubmitted: true } }
    case types.CHANGE_PASSWORD_SUCCESS:
      const passwordSuccess = action.data && action.data.Status === 'success' ? true : false
      return {
        ...state,
        Login: {
          ...state.Login,
          Status: action.data && action.data.Status,
          AccessToken: action.data && action.data.AccessToken,
          IdToken: action.data && action.data.IdToken,
          RefreshToken: action.data && action.data.RefreshToken,
          PartnerStoreCode: action.data && action.data.PartnerStoreCode,
          StoreAgentName: action.data && action.data.StoreAgentName,
          PartnerParentStoreCode: action.data && action.data.PartnerParentStoreCode,
          StoreAgentRole: action.data && action.data.StoreAgentRole,
          PartnerStoreId: action.data && action.data.PartnerStoreId,
          PartnerStoreChannel: action.data && action.data.PartnerStoreChannel,
          AgentId: action.data && action.data.StoreId,
        },
        loginsuccess: passwordSuccess,
      }
    case types.CHANGE_PASSWORD_FAILURE:
      let changePwdError =
        action.data && action.data.response && action.data.response.data && action.data.response.data.ChangeTempPasswordResponse ? action.data.response.data.ChangeTempPasswordResponse : undefined
      return {
        ...state,
        ...{
          Login: {
            ...state.Login,
            Status: changePwdError ? changePwdError && changePwdError.Status : state.Login.Status,
            AccessToken: changePwdError ? changePwdError && changePwdError.AccessToken : state.Login.AccessToken,
            IdToken: changePwdError ? changePwdError && changePwdError.IdToken : state.Login.AccessTIdTokenoken,
            RefreshToken: changePwdError ? changePwdError && changePwdError.RefreshToken : state.Login.RefreshToken,
            CognitoError: changePwdError ? changePwdError && changePwdError.Error : state.Login.Error,
          },
        },
      }
    case types.SET_CURRENT_PASSWORD:
      return {
        ...state,
        Login: { ...state.Login, currentPassword: action.payload, invalidPassword: false },
        isSubmitted: false,
      }
    case types.SET_NEW_PASSWORD:
      return {
        ...state,
        Login: { ...state.Login, newPassword: action.payload, invalidPassword: false },
        isSubmitted: false,
      }
    case types.SET_CONFIRM_NEW_PASSWORD:
      return {
        ...state,
        Login: { ...state.Login, confirmNewPassword: action.payload, invalidPassword: false },
        isSubmitted: false,
      }
    case types.SET_INVALID_PASSWORD:
      return {
        ...state,
        Login: { ...state.Login, invalidPassword: action.payload },
        isSubmitted: true,
      }
    case types.SUBMIT_TRADE_INFO:
      return { ...state, ...{ submitLoader: true } }
    case types.SUBMIT_TRADE_INFO_SUCCESS:
      return {
        ...state,
        ...{
          submitTradeInfoResponse: action.data,
          tradeSuccess: true,
          submitLoader: false,
          submitionDate: moment().format('D MMMM YYYY'),
          submitionTime: moment().format('h:mm:ss a'),
        },
      }
    case types.SUBMIT_TRADE_INFO_FAILURE:
      return { ...state, ...{ submitTradeInfoResponseErr: action.error, tradeSuccess: false, submitLoader: false } }

    case types.GET_LOGISTICES_REQUEST:
      return { ...state, ...{ BatchingItems: null, ErrorDetails: undefined } }
    case types.GET_LOGISTICES_SUCCESS:
      let deviceList = action.data.DeviceList.map((item) => {
        item.isSelected = false
        return item
      })
      let data = { DeviceList: deviceList, ToBeBatched: action.data.ToBeBatched, TotalRecords: action.data.TotalRecords }

      return { ...state, ...{ logisticsData: data } }

    case types.SET_LOGISTICS_DATA:
      return { ...state }
    case types.SET_LOGISTICS_DATA_ALL:
      return { ...state }

    case types.SET_ORDER_NUMBER:
      return { ...state, LogisticsInputField: { ...state.LogisticsInputField, OrderNumber: action.payload } }

    case types.SET_START_DATE:
      return { ...state, LogisticsInputField: { ...state.LogisticsInputField, StartDate: action.payload } }
    case types.SET_END_DATE:
      return { ...state, LogisticsInputField: { ...state.LogisticsInputField, EndDate: action.payload } }
    case types.SET_MAKE:
      return { ...state, LogisticsInputField: { ...state.LogisticsInputField, Make: action.payload } }
    case types.SET_MODEL_VARRIANT:
      return { ...state, LogisticsInputField: { ...state.LogisticsInputField, Model_Varriant: action.payload } }
    case types.LOGISTICS_LBL_CONDITION:
      return { ...state, LogisticsInputField: { ...state.LogisticsInputField, Condition: action.payload } }
    case types.GET_IMEI_VERIFICATION_REQUEST:
      return { ...state }
    case types.GET_IMEI_VERIFICATION_SUCCESS:
      return { ...state, ...{ IMEIVerificationResponse: action.data, primaryIMEIStatus: true, secondaryIMEIStatus: true } }
    case types.GET_IMEI_VERIFICATION_FAILURE:
      return { ...state }
    case types.SET_PRIMARY_IMEI_STATUS:
      return { ...state, ...{ primaryAMTAStatus: action.AMTA, primaryFMIPStatus: action.FMIP, primaryIMEIStatus: false } }
    case types.SET_SECONDARY_IMEI_STATUS:
      return { ...state, ...{ secondaryAMTAStatus: action.AMTA, secondaryFMIPStatus: action.FMIP, secondaryIMEIStatus: false } }
    case types.NAVIGATE_TO_SEARCH:
      return {
        ...state,
        ...{
          submitTradeInfoResponse: {},
          CustomerInfo: {},
          TradeInfo: {},
          cityStateResponse: {},
          customerTradeId: '',
          TradeInfoSearched: undefined,
          billingAccountNumber: undefined,
          captureCheck: undefined,
          cityStateResponse: [],
          customerFormVailidity: undefined,
          customerVerificationRequested: undefined,
          deviceModelCheck: undefined,
          goodWorkingOrderCheck: undefined,
          initializeFlag: undefined,
          mobileNumber: undefined,
          orderType: undefined,
          physicalResultCheck: undefined,
          primaryImeiCheck: undefined,
          secondaryImeiCheck: undefined,
          thirtySixMonthsCredit: undefined,
          tradeSuccess: undefined,
          oneMonthsCredit:undefined,
          twelveMonthsCredit: undefined,
          twentyFourMonthsCredit: undefined,
          validCustomer: undefined,
          recerringCredit: undefined,
          EmailLabelSuccess: undefined,
          NotificationSuccess: undefined,
        },
      }
    case types.SET_LOGISTICS_TAB:
      return { ...state, LogisticsTab: action.payload }
    case types.SET_REPORTING_TAB:
      return { ...state, ReportsTab: action.payload }
    case types.SET_ORDER_TAB:
      return { ...state, OrderTab: action.payload }
    case types.SET_ORDER_DETAILS_TAB:
      return {
        ...state,
        LogisticsOrderDetailsTab: action.payload,
        UpdateStoreSuccess: null,
        creatItemsArray: null,
        logisticsStoreId: null,
        createBatchResponse: null,
        BatchingItems: null,
        AusPost: null,
      }
    case types.SET_SELECTED_PAGE:
      return { ...state, selectedPage: action.payload }
    case types.CREATE_BATCH_REQUEST: {
      return { ...state, batchRequest: true, AusPost: null, BatchingItems: null, isError: false }
    }
    case types.CREATE_BATCH_SUCCESS:
      let batchingItems = state.logisticsData.DeviceList.filter((item) => {
        return item.isSelected === true
      })
      let newBatchingItems = batchingItems.map((item) => {
        item.BatchId = action.data.BatchId
        return item
      })
      return { ...state, ...{ createBatchResponse: action.data }, BatchingItems: newBatchingItems, redirectToLogistics: false }
    case types.SET_PARTNER_STORE_CODE:
      return { ...state, PartnerStoreCodeNbr: action.payload }
    case types.SET_PARTNER_STORE_NAME:
      return { ...state, partnerStoreName: action.payload }
    case types.SET_SPAN_OPEN_OR_CLOSE:
      return { ...state, openOrCloseSpan: !state.openOrCloseSpan }
    case types.SET_IMEI_STATUS:
      return { ...state, ...{ AMTAStatus: action.amta, FMIPStatus: action.fmip } }
    case types.SET_BATCH_START_DATE:
      return { ...state, LogisticsBatchDetailsInputField: { ...state.LogisticsBatchDetailsInputField, startDate: action.payload } }
    case types.SET_BATCH_NUMBER:
      return { ...state, LogisticsBatchDetailsInputField: { ...state.LogisticsBatchDetailsInputField, batchNumber: action.payload } }
    case types.SET_BATCH_CONSIGNMENT_NUMBER:
      return { ...state, LogisticsBatchDetailsInputField: { ...state.LogisticsBatchDetailsInputField, consignmentNumber: action.payload } }
    case types.GET_LOGISTICES_BATCH_REQUEST:
      return { ...state }
    case types.GET_LOGISTICES_BATCH_SUCCESS:
      return { ...state, ...{ logisticsBatchData: action.data } }
    case types.SET_LOGISTICS_BATCH_DATA:
      return { ...state, logisticsBatchData: { ...state.logisticsBatchData, BatchItems: action.payload } }
    case types.CREATE_SEARCH_OPEN_POPUP:
      return { ...state, ...{ openCreateItemPopUp: true, ClearNoItemFoundMsg: false } }
    case types.CREATE_SEARCH_CLOSE_POPUP:
      return { ...state, ...{ openCreateItemPopUp: false, ClearNoItemFoundMsg: false } }
    case types.SET_SEARCH_ITEM_ID:
      return { ...state, ...{ searchItemId: action.payload } }
    case types.GET_SEARCH_ITEMS_REQUEST:
      return { ...state }
    case types.GET_SEARCH_ITEMS_SUCCESS:
      let creatItemsArray = action.data
      let closePopUp = action.data.DeviceList.length >= 1 ? false : true
      return {
        ...state,
        ...{ creatItemsArray: creatItemsArray },
        openCreateItemPopUp: closePopUp,
        searchItemId: null,
        UpdateStoreSuccess: null,
        logisticsStoreId: null,
        ClearNoItemFoundMsg: closePopUp,
      }
    case types.CREATE_PARCEL_REQUEST:
      return { ...state, isError: false }
    case types.SET_OSOT_REPORT_DATA:
      return { ...state, osotReportData: { ...state.osotReportData, GetOSOTReportDetails: action.payload } }
    case types.CREATE_PARCEL_SUCCESS:
      let createShipmentResponse = action.data.ReprintDetails ? undefined : action.data.responses && action.data.responses.createShipmentResponse
      let CreateLabelResponse =
        action.data.ReprintDetails && action.data.ReprintDetails.OrderLabelData ? action.data.ReprintDetails.OrderLabelData : action.data.responses && action.data.responses.CreateLabelResponse
      let ConsignmentNo = action.data.ReprintDetails ? undefined : createShipmentResponse && createShipmentResponse.shipments[0].items[0].tracking_details.consignment_id
      let base64Summary =
        action.data.ReprintDetails && action.data.ReprintDetails.OrderSummaryPdfData
          ? action.data.ReprintDetails.OrderSummaryPdfData
          : action.data.responses && action.data.responses.OrderSummaryExpResponse
      let base64Label = action.data.ReprintDetails && action.data.ReprintDetails.OrderLabelData ? action.data.ReprintDetails.OrderLabelData : action.data.responses && action.data.responses.File
      return {
        ...state,
        AusPost: {
          base64Summary: base64Summary,
          base64Label: base64Label,
          ConsignmentNo: ConsignmentNo,
        },
      }
    case types.SHOW_CANVAS:
      return { ...state, showCanvas: true }
    case types.HIDE_CANVAS:
      return { ...state, showCanvas: false }
    case types.SHOW_CANVAS_REPRINT:
      return { ...state, showCanvasReprint: true }
    case types.HIDE_CANVAS_REPRINT:
      return { ...state, showCanvasReprint: false }
    case types.REDIRECT_TO_LOGISTICS:
      return { ...state, redirectToLogistics: true, BatchingItems: null, creatItemsArray: null }
    case types.SET_LOGISTICS_STORE:
      return { ...state, logisticsStoreId: action.payload }
    case types.UPDATE_STORE_REQUEST:
      return { ...state }
    case types.UPDATE_STORE_SUCCESS:
      return { ...state, UpdateStoreSuccess: action.data }
    case types.SET_CREDIT_TRADE_IN:
      return { ...state, creditTradeIn: action.payload, isCreditTradeIn: true }
    case types.SET_CREDIT_TRADE_UP:
      return { ...state, creditTradeUp: action.payload, isCreditTradeUp: true }
    case types.DOWNLOAD_FILE_REQUEST:
      return { ...state }
    case types.DOWNLOAD_FILE_SUCCESS:
      return { ...state, DownloadedReports: action.data }
    case types.GET_FILE_REQUEST:
      return { ...state }
    case types.GET_FILE_SUCCESS:
      return { ...state, Reports: action.data }
    case types.GET_PRICING_FILE_SUCCESS:
      return { ...state, pricingReports: action.data }
    case types.SET_RECURRING:
      return { ...state, ...{ recerringCredit: action.payload, isUpFront: false } }
    case types.SET_BATCH_SELECTED_PAGE:
      return { ...state, batchSelectedPage: action.payload }
    case types.UPLOAD_FILE:
      let updateFilesArray = true
      /*  state.selectedFiles.forEach(f => {
         if (f.name === action.payload.name && f.fileType === action.payload.fileType) {
           updateFilesArray = false;
         }
       }) */
      if (updateFilesArray) {
        state.selectedFiles = [...state.selectedFiles, action.payload]
        return { ...state, FileUploadSuccess: true, fileError: null }
      }
    case types.UPLOAD_FILE_ERROR:
      return { ...state, fileError: action.payload, FileUploadSuccess: false }
    case types.UPLOAD_FILE_REQUEST:
      return { ...state }
    case types.UPLOAD_FILE_SUCCESS:
      return { ...state, FileuploadRes: action.payload }
    case types.BACK_CLICK:
      return { ...state, creatItemsArray: null }
    case types.CREATE_CONTACT_POINT_REQUEST:
      return { ...state }
    case types.CREATE_CONTACT_POINT_SUCCESS:
      return { ...state, ...{ contactPointCreated: true, AddressId: action.data.AddressId } }
    case types.SET_SEND_LABEL_CHECK:
      return { ...state, ...{ sendLabelCheck: action.payload, EmailLabelSuccess: undefined } }
    case types.PUSH_NOTIFICATION_REQUEST:
      return { ...state, ...{ NotificationSuccess: false } }
    case types.PUSH_NOTIFICATION_SUCCESS:
      return { ...state, ...{ NotificationSuccess: true } }
    case types.PUSH_NOTIFICATION_FAILURE:
      return { ...state, ...{ NotificationSuccess: false } }
    case types.SEND_EMAIL_LABEL_REQUEST:
      return { ...state, ...{ EmailLabelSuccess: false } }
    case types.SEND_EMAIL_LABEL_SUCCESS:
      return { ...state, ...{ EmailLabelSuccess: true } }
    case types.SEND_EMAIL_LABEL_FAILURE:
      return { ...state, ...{ EmailLabelSuccess: false } }
    case types.SET_TRADE_ID_NUMBER:
      return { ...state, LogisticsBatchDetailsInputField: { ...state.LogisticsBatchDetailsInputField, tradeId: action.payload } }
    case types.NAVIGATE_TO_FORGOT_PASSWORD:
      return { ...state, isForgotPswdClicked: true }
    case types.FORGORT_PASSWORD_REQUEST:
      return { ...state }
    case types.FORGORT_PASSWORD_SUCCESS:
      return { ...state, forgotPasswordSuccessResponse: action.data }
    case types.FORGORT_PASSWORD_FAILURE:
      return { ...state, forgotPasswordError: action.data, isForgotPassFailure: true }
    case types.SET_VERIFICATION_CODE:
      return { ...state, Login: { ...state.Login, verificationCode: action.payload } }
    case types.CONFIRM_PASSWORD_REQUEST:
      return { ...state, ...{ isSubmitted: true } }
    case types.CONFIRM_PASSWORD_SUCCESS:
      const confirmPassSuccess = action.data && action.data.Status === 'success' ? true : false
      return {
        ...state,
        Login: {
          ...state.Login,
          Status: action.data && action.data.Status,
          AccessToken: action.data && action.data.AccessToken,
          IdToken: action.data && action.data.IdToken,
          RefreshToken: action.data && action.data.RefreshToken,
          StoreAgentName: action.data && action.data.StoreAgentName,
          PartnerStoreCode: action.data && action.data.PartnerStoreCode,
          PartnerStoreId: action.data && action.data.PartnerStoreId,
          PartnerParentStoreCode: action.data && action.data.PartnerParentStoreCode,
          StoreAgentRole: action.data && action.data.StoreAgentRole,
          PartnerStoreChannel: action.data && action.data.PartnerStoreChannel,
        },
        loginsuccess: confirmPassSuccess,
      }
    case types.CONFIRM_PASSWORD_FAILURE:
      return { ...state, confirmPasswordError: action.data, isConfirmPassFailure: true }
    case types.GET_ID_DOCES_REQUEST:
      return { ...state, ...{ idDocsSuccess: false } }
    case types.GET_ID_DOCES_SUCCESS:
      return { ...state, ...{ fileDetails: action.data, idDocsSuccess: true } }
    case types.GET_ID_DOCES_FAILURE:
      return { ...state, ...{ idDocsSuccess: false } }
    case types.GET_ROLE_ACCESS_REQUEST:
      return { ...state }
    case types.GET_ROLE_ACCESS_SUCCESS:
      return { ...state, ...{ roles: action.data } }
    case types.SET_UPFRONT:
      return { ...state, ...{ isUpFront: action.payload, recerringCredit: false } }
    case types.SET_NOTIFICATION_FLAG:
      return { ...state, ...{ NotificationSuccess: false } }
    case types.RESET_CUSTOMER_TRADE_ID_FIELD:
      return { ...state, ...{ resetCustomerTradeIdField: action.payload } }
    case types.SET_CREATE_ITEMS_DATA:
      return { ...state, ...{ creatItemsArray: action.payload } }
    case types.SET_BATCH_ITEMS_DATA:
      return { ...state, ...{ BatchingItems: action.payload } }
    case types.CREATE_BATCH_ONSUBMIT:
      return { ...state }
    case types.CREATE_BATCH_ONSUBMIT_SUCCESS:
      return { ...state, ...{ BatchId: action.data.BatchId } }
    case types.CREATE_PARCEL_ONSUBMIT_REQUEST:
      return { ...state }
    case types.CREATE_PARCEL_ONSUBMIT_SUCCESS:
      return { ...state }
    case types.SET_WORKING_AS:
      return { ...state, ...{ workingAs: action.payload } }
    case types.SET_STORE_AGENT_ROLE:
      return { ...state, Login: { ...state.Login, StoreAgentRole: action.payload } }
    case types.SET_REPORT_DETAILS_TAB:
      return { ...state, ...{ reportingDetailsTab: action.payload, reportDetailsSuccess: false } }
    case types.SET_REPORT_SUMMARY_TAB:
      return { ...state, ...{ reportingSummaryTab: action.payload, masterReportData: [], reportDetails: [], shoeReportingDetails: false } }
    case types.SET_REPORT_CARE_CREDIT_TAB:
      return { ...state, ...{ reportingCareCreditTab: action.payload, reportDetailsSuccess: false, shoeReportingDetails: false } }
    case types.SET_REPORT_PRICING_TAB:
      return { ...state, ...{ reportingPricingTab: action.payload, reportDetailsSuccess: false, shoeReportingDetails: false } }
    case types.SET_REPORT_TRADE_ID_NUMBER:
      return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, Tradeid: action.payload } }
    case types.SET_REPORT_IMEI:
      return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, Imei: action.payload } }
    case types.SET_REPORT_START_DATE:
      return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, StartDate: action.payload } }
    case types.SET_REPORT_END_DATE:
      return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, EndDate: action.payload } }
    case types.SET_REPORT_STATE:
      return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, State: action.payload } }
    case types.SET_REPORT_STATUS:
      return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, Status: action.payload } }
    case types.SET_REPORT_STORE_NAME:
      return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, StoreName: action.payload } }
    case types.SET_REPORT_STORE_CODE:
      return { ...state, ReportingDetailsInputFields: { ...state.ReportingDetailsInputFields, PartnerStoreCode: action.payload } }
    case types.GET_REPORTING_DETAILS_REQUEST:
      return { ...state, ...{ isError: false, reportDetailsSuccess: false, exportSuccessFlag: false } }
    case types.GET_REPORTING_DETAILS_SUCCESS:
      let reportDetails = action.data.ReportsData.map((item) => {
        item.isSelected = false
        return item
      })
      let details = { ReportDetails: reportDetails, TotalRecords: action.data.TotalRecords }
      return { ...state, ...{ reportingData: details, shoeReportingDetails: false, reportDetailsSuccess: true, masterReportSummaryData: action.data.masterReportData, exportSuccessFlag: true } }
    case types.SET_REPORT_SELECTED_PAGE:
      return { ...state, ...{ reportSelectedPage: action.payload } }
    case types.SET_SHOW_OSOT_REPORT_DATA:
      return { ...state, ...{ shoeReportingDetails: action.payload } }
    //return { ...state, Login: { ...state.Login, StoreAgentRole: action.payload } }
    case types.SET_EXCEPTION_TAB:
      return { ...state, ...{ ExceptionsTab: action.payload } }
    case types.SET_EXCEPTION_ACTION:
      return { ...state }
    case types.OPEN_ACTION_POPUP:
      return { ...state, ...{ selectedTradeId: action.payload, openActionPopUp: true } }
    case types.CLOSE_ACTION_POPUP:
      return { ...state, ...{ selectedTradeId: '', openActionPopUp: false } }
    case types.GET_EXCEPTION_DETAILS_REQUEST:
      return { ...state, ...{ daxImagesResponse: '', requestedRedemptionOrder: '' } }
    case types.GET_EXCEPTION_DETAILS_SUCCESS:
      return { ...state, ...{ exceptionSuccess: true, exceptionResponse: action.data.EscalationsResponse, exceptionList: action.data.EscalationsResponse.EscalationItems } }
    case types.SET_ESCALATION_CODE:
      return { ...state, SearchException: { ...state.SearchException, escalationCode: action.payload } }
    case types.SET_EXCEPTION_STARTDATE:
      return { ...state, SearchException: { ...state.SearchException, startDate: action.payload } }
    case types.SET_EXCEPTION_TRADEID:
      return { ...state, SearchException: { ...state.SearchException, tradeId: action.payload } }
    case types.SET_EXCEPTION_ENDDATE:
      return { ...state, SearchException: { ...state.SearchException, endDate: action.payload } }
    case types.GET_DAX_IMAGE_REQUEST:
      return { ...state, ...{ requestedRedemptionOrder: action.data, daxImagesResponse: '' } }
    case types.GET_DAX_IMAGE_SUCCESS:
      return { ...state, ...{ daxImagesResponse: action.data.GetDAXImageResponse } }
    case types.UDPATE_EXCEPTION_ACTION_REQUEST:
      return { ...state }
    case types.UDPATE_EXCEPTION_ACTION_SUCCESS:
      return { ...state }
    case types.SET_CUSTOMER_RESOLUTION_FORM_VALIDITY:
      return { ...state, ...{ customerResolutionFormValidity: action.payload } }
    case types.SET_MASTER_VIEW_DATA:
      return { ...state, ...{ masterReportData: action.payload } }
    default:
      return { state }
  }
}
export { initialState, reducer }
