import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'
import LogisticsOrderDetails from './logistics-orderdetails'
import LogisticsBatchDetails from './logistics-batchdetails'
import { MemoryRouter as Router, Switch, Route, Link } from 'react-router-dom'


const Logistics = () => {
	const { state, dispatch } = useContext(store)
	const actions = useActions(state, dispatch)
	const { Login, roles } = state;
	const { StoreAgentRole } = Login;
	const LogisticsRoles = roles && roles.filter(r => r.menu === 'Logistics' && r.section === "Tabs") &&
		roles && roles.filter(r => r.menu === 'Logistics' && r.section === "Tabs")[0];

	const batchDetailsTab = () => {
		actions.setOrderDetailsTab(false)
	}

	const orderDetailsTab = () => {
		actions.setOrderDetailsTab(true)
		let GetDeviceListRequest = {
			PartnerStoreId: state && state.Login && state.Login.PartnerStoreId,
			PartnerParentCode: state && state.Login && state.Login.PartnerParentStoreCode,
			PageSize: 10,
			PageNumber: 1,
		}
		actions.setSelectedPage(0)
		actions.getLogistics({ GetDeviceListRequest })
	}

	let LogisticsOrderDetailsTab = state && state.LogisticsOrderDetailsTab
	const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase();

	return (
		<Router>
			<div className="ml-50 mr-50 min-h-718 mb-100 font-VodafoneRg">
				<div className="flex items-center pt-30">
					<h2 className="text-32 leading-37 p-1 font-VodafoneRgBd">Logistics</h2>
					{LogisticsRoles['OrderDeatils'][agentRole] === 'SHOW' &&
						<div className={`${LogisticsOrderDetailsTab ? 'text-red-1' : 'text-grey-3'} text-17 text-center ml-8 py-2 m-2 font-VodafoneRgBd`}>
							<Link
								to="/orderdetails"
								onClick={() => {
									orderDetailsTab()
								}}
								className="cursor-pointer"
							>Order Details</Link>
						</div>
					}
					{LogisticsRoles['BatchDeatils'][agentRole] === 'SHOW' &&
						<div className={`${LogisticsOrderDetailsTab ? 'text-grey-3' : 'text-red-1'} text-17 leading-22 text-center ml-5 py-2 m-2 font-VodafoneRgBd`}>
							<Link
								to="/batchdetails"
								onClick={() => {
									batchDetailsTab()
								}}
								className="cursor-pointer"
							>Batch Details</Link>
						</div>
				}

				</div>
				<Switch>
					<Route exact path="/">
						<LogisticsOrderDetails />
					</Route>
					<Route path="/orderdetails">
						<LogisticsOrderDetails />
					</Route>
					<Route path="/batchdetails">
						<LogisticsBatchDetails />
					</Route>
				</Switch>
			</div>
		</Router>
	)
}

export default Logistics
