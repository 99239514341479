import React, { useContext, useEffect } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'

const INVOKE_ONCE = true

const PricingReport = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const { pricingReports } = state

  useEffect(() => {
    actions.getReports()
  }, [INVOKE_ONCE])

  const downloadFile = (fileName) => {
    actions.downloadReport(fileName)
  }

  return (
    <div className="ml-50 mr-50">
      <div className="flex items-center pt-50" />
      {pricingReports && pricingReports.length > 0 ? (
        <table className="thead mt-15 border border-gray-2 w-full h-50  text-xl font-normal  font-VodafoneRg ">
          <tbody>
            <tr className="bg-grey-3 text-white h-50 text-left text-xl">
              <th className="w-130 h-10 p-4 font-VodafoneLt">Trade In - Pricing Report</th>
            </tr>
            {pricingReports.map((file, i) => (
              <tr className="text-left  h-10  border text-xl">
                <td key={i} className="w-130 h-10 p-4 underline text-blue-800">
                  <span>
                    <a href="#" onClick={() => downloadFile(file.FileName)}>
                      {' '}
                      {`${i + 1}. ${file.FileName}`}
                    </a>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-red-600 text-center text-2xl">No Data Found</div>
      )}
    </div>
  )
}

export default PricingReport
