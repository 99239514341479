import types from './ActionTypes'
import API from './API'
import resources from './dummyjson/resources.json'
import customerInfo from './dummyjson/customerInfoResponse.json'
import roleAccess from './components/role-access.json'
import reportDetails from './dummyjson/reportdetails.json'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import { PDFDocument } from 'pdf-lib'
import html2canvas from 'html2canvas'
import exceptionList from './dummyjson/searchExecption.json'
import ExportColumns from './components/reports/exportreportformat'
import masterreportheaderformat from './components/reports/masterreportheaderformat.json'
const middlewareErrorHanlder = (dispatch) => (error) => {
  console.log('error', error)
  dispatch({ type: types.API_ERROR, data: error })
  dispatch({ type: types.UNSET_LOADING_INDICATOR })
}
const middleware = {}

middleware[types.GET_ROLE_ACCESS_REQUEST] = (dispatch) => (action) =>
  // API[action.type](action.payload)
  Promise.resolve(roleAccess)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_ROLE_ACCESS_SUCCESS, data })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.GET_RESOURCE_REQUEST] = (dispatch) => (action) =>
  // API[action.type](action.payload)
  Promise.resolve(resources)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_RESOURCE_SUCCESS, data })
      dispatch({ type: types.GET_ROLE_ACCESS_REQUEST })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.GET_MASTERDATA_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.tokenHeaders)
    //Promise.resolve(masterdata)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_MASTERDATA_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.FIND_TRADE_INFO_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.tradeid)
    //Promise.resolve(tradeInfo)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.FIND_TRADE_INFO_SUCCESS, data: data.data.FindTradeInfoResponse })
      if (data && data.data && data.data.FindTradeInfoResponse) {
        dispatch({ type: types.SET_LOADING_INDICATOR })
        dispatch({ type: types.GET_IMEI_VERIFICATION_REQUEST, data: action.tradeid })
      }
    })
    .catch((error) => {
      dispatch({ type: types.FIND_TRADE_INFO_FAILURE, data: error && error.response }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.VALIDATE_CUSTOMER_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.payload)
    //Promise.resolve(customerInfo)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.VALIDATE_CUSTOMER_SUCCESS, data: data.data })
    })
    .catch((error) => {
      dispatch({ type: types.VALIDATE_CUSTOMER_FAILURE, data: error && error.response && error.response.data }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.VALIDATE_CUSTOMER_REQUEST_V4] = (dispatch) => (action) =>
    API[action.type](action.payload)
      //Promise.resolve(customerInfo)
      .then((data) => {
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
        dispatch({ type: types.VALIDATE_CUSTOMER_SUCCESS, data: data.data })
      })
      .catch((error) => {
        dispatch({ type: types.VALIDATE_CUSTOMER_FAILURE, data: error && error.response && error.response.data }),
          dispatch({ type: types.UNSET_LOADING_INDICATOR })
      })

middleware[types.GET_CITY_STATE_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    // Promise.resolve(tradeInfo)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_CITY_STATE_SUCCESS, data: data.data.GetCityStatebyZipCodeResponse })
    })
    .catch((error) => {
      dispatch({ type: types.GET_CITY_STATE_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.LOGIN_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    // Promise.resolve(tradeInfo)
    .then((data) => {
      // dispatch({ type: types.UNSET_LOADING_INDICATOR })
      let dataa = data.data && data.data.LoginAPiResponse
      let tokenHeaders = { AccessToken: dataa.AccessToken, IdToken: dataa.IdToken, RefreshToken: dataa.RefreshToken, StoreAgentName: dataa.StoreAgentName }
      if (dataa.Status == 'success') {
        setInterval(() => {
          window.location.reload(true)
        }, 2700000)
      }
      dispatch({ type: types.LOGIN_SUCCESS, data: data.data.LoginAPiResponse })
      dispatch({ type: types.GET_MASTERDATA_REQUEST, tokenHeaders })
    })
    .catch((error) => {
      dispatch({ type: types.LOGIN_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.CHANGE_PASSWORD_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      //dispatch({ type: types.UNSET_LOADING_INDICATOR })
      let res = data.data && data.data.ChangeTempPasswordResponse
      let tokenHeaders = { AccessToken: res.AccessToken, IdToken: res.IdToken, RefreshToken: res.RefreshToken, StoreAgentName: res.StoreAgentName }
      if (res.Status == 'success') {
        setInterval(() => {
          window.location.reload(true)
        }, 2700000)
      }
      dispatch({ type: types.CHANGE_PASSWORD_SUCCESS, data: data.data.ChangeTempPasswordResponse })
      dispatch({ type: types.GET_MASTERDATA_REQUEST, tokenHeaders })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.SUBMIT_TRADE_INFO] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.SUBMIT_TRADE_INFO_SUCCESS, data: data.data.SubmitTradeInfoResult })
    })
    .catch((error) => {
      dispatch({ type: types.SUBMIT_TRADE_INFO_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })
}

middleware[types.GET_LOGISTICES_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_LOGISTICES_SUCCESS, data: data.data.GetDeviceListResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.GET_IMEI_VERIFICATION_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_IMEI_VERIFICATION_SUCCESS, data: data.data.IMEIVerificationResponse })
    })
    .catch((error) => {
      dispatch({ type: types.GET_IMEI_VERIFICATION_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })
}

middleware[types.CREATE_BATCH_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_BATCH_SUCCESS, data: data.data.CreateBatchResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.GET_SEARCH_ITEMS_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_SEARCH_ITEMS_SUCCESS, data: data.data.GetDeviceListResponse })
      if (data.data.GetDeviceListResponse.DeviceList > 0) {
        dispatch({ type: types.CREATE_SEARCH_CLOSE_POPUP })
      }
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_LOGISTICES_BATCH_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_LOGISTICES_BATCH_SUCCESS, data: data.data.GetBatchDetailsResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.CREATE_PARCEL_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data, action.GetBatchDetailsRequest)
    .then((data) => {
      let res = (data && data.data && data.data.response && data.data.response.AusPostApiCallDetails) || (data && data.data)
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_PARCEL_SUCCESS, data: res })

      let ReprintDetails = res && res.ReprintDetails
      let createShipmentResponse =
        data &&
        data.data &&
        data.data.response &&
        data.data.response.AusPostApiCallDetails &&
        data.data.response.AusPostApiCallDetails.responses &&
        data.data.response.AusPostApiCallDetails.responses.createShipmentResponse
      let ConsignmentNo = createShipmentResponse && createShipmentResponse.shipments[0].items[0].tracking_details.consignment_id
      let base64Summary = ReprintDetails
        ? ReprintDetails.OrderSummaryPdfData
        : data &&
          data.data &&
          data.data.response &&
          data.data.response.AusPostApiCallDetails &&
          data.data.response.AusPostApiCallDetails.responses &&
          data.data.response.AusPostApiCallDetails.responses.OrderSummaryExpResponse
      let base64Label = ReprintDetails
        ? ReprintDetails.OrderLabelData
        : data &&
          data.data &&
          data.data.response &&
          data.data.response.AusPostApiCallDetails &&
          data.data.response.AusPostApiCallDetails.responses &&
          data.data.response.AusPostApiCallDetails.responses.File

      let AusPost = {
        base64Summary: base64Summary,
        base64Label: base64Label,
        ConsignmentNo: ConsignmentNo,
      }
      return new Promise((resolve) => resolve()).then(async (data) => {
        const base64Summary = AusPost && AusPost.base64Summary
        const base64label = AusPost && AusPost.base64Label

        const dataUriSummary = 'data:application/pdf;base64,' + base64Summary
        const dataUriLabel = 'data:application/pdf;base64,' + base64label
        const firstDonorPdfDoc = await PDFDocument.load(dataUriSummary)
        const secondDonorPdfDoc = await PDFDocument.load(dataUriLabel)

        const pdfDoc = await PDFDocument.create()
        const [firstPage, secondPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [0, 1])
        const [thirdPage] = await pdfDoc.copyPages(secondDonorPdfDoc, [0])

        pdfDoc.addPage(firstPage)
        pdfDoc.addPage(secondPage)
        pdfDoc.addPage(thirdPage)

        const page = pdfDoc.addPage([842, 595])
        dispatch({ type: types.SHOW_CANVAS })
        let canvas
        if (!ReprintDetails) {
          if (action.GetBatchDetailsRequest) {
            dispatch({ type: types.SHOW_CANVAS_REPRINT })
            document.getElementById('reprintme').style.visibility = 'visible'
            const input = document.getElementById('reprintme')
            canvas = await html2canvas(input, { scale: 4 })
            document.getElementById('reprintme').style.visibility = 'hidden'
            dispatch({ type: types.HIDE_CANVAS_REPRINT })
          } else {
            dispatch({ type: types.SHOW_CANVAS })
            document.getElementById('printme').style.visibility = 'visible'
            const input = document.getElementById('printme')
            canvas = await html2canvas(input, { scale: 4 })
            document.getElementById('printme').style.visibility = 'hidden'
            dispatch({ type: types.HIDE_CANVAS })
          }
        } else {
          dispatch({ type: types.SHOW_CANVAS_REPRINT })
          document.getElementById('reprintme').style.visibility = 'visible'
          const input = document.getElementById('reprintme')
          canvas = await html2canvas(input, { scale: 4 })
          document.getElementById('reprintme').style.visibility = 'hidden'
          dispatch({ type: types.HIDE_CANVAS_REPRINT })
        }

        const imgData = canvas.toDataURL('image/png')
        const pngImage = await pdfDoc.embedPng(imgData)

        page.drawImage(pngImage, {
          x: 15,
          y: 20,
          width: 812,
          height: 555,
        })
        const pdfBytes = await pdfDoc.saveAsBase64()

        let linkSource = `data:application/pdf;base64,${pdfBytes}`
        const downloadLink = document.createElement('a')
        const fileName = 'Logistics.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        if (action.GetBatchDetailsRequest) {
          let GetBatchDetailsRequest = {
            GetBatchDetailsRequest: action.GetBatchDetailsRequest,
          }
          dispatch({ type: types.GET_LOGISTICES_BATCH_REQUEST, data: GetBatchDetailsRequest })
        }
      })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.UPDATE_STORE_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.UPDATE_STORE_SUCCESS, data: data.data.UpdateStoreResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.GET_FILE_REQUEST] = (dispatch) => (action) => {
  API[action.type]()
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_FILE_SUCCESS, data: data.data.GetTradeInCareCreditReportResponse.filter((f) => f.FileName.includes('CreditReport')) })
      dispatch({ type: types.GET_PRICING_FILE_SUCCESS, data: data.data.GetTradeInCareCreditReportResponse.filter((f) => f.FileName.includes('PricingReport')) })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.DOWNLOAD_FILE_REQUEST] = (dispatch) => (action) => {
  /*  let body = { Files: action.data };
   let data = {FileDownloadRequest: body} */
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      var byteCharacters = atob(data.data.FileDownloadResponse)
      var byteNumbers = new Array(byteCharacters.length)
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      var byteArray = new Uint8Array(byteNumbers)
      var blob = new Blob([byteArray], {
        type: 'application/octet-stream',
      })
      FileSaver.saveAs(blob, action.payload)
      dispatch({ type: types.DOWNLOAD_FILE_SUCCESS, data: data.data })
      return data
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.CREATE_CONTACT_POINT_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.payload.contactPointRequest)
    .then((data) => {
      let SubmitTradeInfoRequest = action.payload.SubmitTradeInfoRequest
      SubmitTradeInfoRequest.SubmitTradeInfoRequest.AddressId = data.data.UpdateAccountResponse.AddressId
      dispatch({ type: types.CREATE_CONTACT_POINT_SUCCESS, data: data.data.UpdateAccountResponse })
      dispatch({ type: types.SUBMIT_TRADE_INFO, data: SubmitTradeInfoRequest })
      //April 2023 - Skipping push notifications call as its not requored and giving error on prod.
      //    dispatch({ type: types.PUSH_NOTIFICATION_REQUEST, payload: action.payload.pushNotificationRequest })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.UPLOAD_FILE_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.UPLOAD_FILE_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.PUSH_NOTIFICATION_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.PUSH_NOTIFICATION_SUCCESS, data: data.data })
    })
    .catch((error) => {
      dispatch({ type: types.PUSH_NOTIFICATION_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })

middleware[types.SEND_EMAIL_LABEL_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.SEND_EMAIL_LABEL_SUCCESS, data: data.data })
    })
    .catch((error) => {
      dispatch({ type: types.SEND_EMAIL_LABEL_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })
middleware[types.FORGORT_PASSWORD_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.FORGORT_PASSWORD_SUCCESS, data: data.data.ForgotPasswordResponse })
    })
    .catch((error) => {
      dispatch({ type: types.FORGORT_PASSWORD_FAILURE, data: error && error.response && error.response.data && error.response.data.ForgotPasswordResponse }),
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })
middleware[types.CONFIRM_PASSWORD_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data)
    .then((data) => {
      let confirmData = data.data && data.data.ConfirmPasswordResponse
      let tokenHeaders = { AccessToken: confirmData.AccessToken, IdToken: confirmData.IdToken, RefreshToken: confirmData.RefreshToken, StoreAgentName: confirmData.StoreAgentName }
      if (confirmData.Status == 'success') {
        setInterval(() => {
          window.location.reload(true)
        }, 2700000)
      }
      dispatch({ type: types.CONFIRM_PASSWORD_SUCCESS, data: data.data.ConfirmPasswordResponse })
      dispatch({ type: types.GET_MASTERDATA_REQUEST, tokenHeaders })
    })
    .catch((error) => {
      dispatch({ type: types.CONFIRM_PASSWORD_FAILURE, data: error && error.response && error.response.data && error.response.data.ConfirmPasswordResponse }),
        dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })
middleware[types.GET_ID_DOCES_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_ID_DOCES_SUCCESS, data: data.data.GetFileDetailsResponse })
    })
    .catch((error) => {
      dispatch({ type: types.GET_ID_DOCES_FAILURE, data: error }), dispatch({ type: types.UNSET_LOADING_INDICATOR })
    })
middleware[types.CREATE_BATCH_ONSUBMIT] = (dispatch) => (action) => {
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_BATCH_ONSUBMIT_SUCCESS, data: data.data.CreateBatchResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))
}
middleware[types.CREATE_PARCEL_ONSUBMIT_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.payload)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.CREATE_PARCEL_ONSUBMIT_SUCCESS, data: data.data.response.AusPostApiCallDetails })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_REPORTING_DETAILS_REQUEST] = (dispatch) => (action) =>
  API[action.type](action.data, action.Exportflag)
    //Promise.resolve(reportDetails)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      let ReportDetails = data && data.data && data.data.GetReportSummaryResponse && data.data.GetReportSummaryResponse.ReportsData
      if (action.Exportflag) {
        /*const exportColumns = [];
          const exportData = [];
          const header = [];
          //exportServiceCall()
          ExportColumns.forEach(col => {
            const colHeading = col.header;
            header.push(colHeading);
            exportColumns.push(col);
          })

          exportData.push(header);

          ReportDetails.forEach(item => {
            const row = [];
            exportColumns.forEach(col => {
              row.push(item[col.key] || '');
            })

            exportData.push(row);
          })

          const csvData = exportData.reduce((csv, row) => {
            csv += row.join(',') + '\r\n';
            return csv;
          }, ''); */

        let colWidth = []
        const ws = XLSX.utils.json_to_sheet(ReportDetails)
        var range = XLSX.utils.decode_range(ws['!ref'])
        for (var C = range.s.r; C <= range.e.c; ++C) {
          var address = XLSX.utils.encode_col(C) + '1'
          ws[address].v = masterreportheaderformat[ws[address].v] ? masterreportheaderformat[ws[address].v] : ws[address].v
          if (ws[address].v === 'Current Location' || ws[address].v === 'IMEI In' || ws[address].v === 'IMEI Out' || ws[address].v === 'Store Name' || ws[address].v === 'Trading Name') {
            colWidth.push({ width: ws[address].v.length + 19 })
          } else if (ws[address].v === 'Submitted At' || ws[address].v === 'IMEI IN Model / Variant' || ws[address].v === 'IMEI Out Model / Variant') {
            colWidth.push({ width: ws[address].v.length + 17 })
          } else if (ws[address].v === 'Model') {
            colWidth.push({ width: ws[address].v.length + 28 })
          } else {
            colWidth.push({ width: ws[address].v.length + 2 })
          }
        }
        ws['!cols'] = colWidth
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
        FileSaver.saveAs(blob, `MasterViewReport.xlsx`)
        return true
      }

      dispatch({ type: types.GET_REPORTING_DETAILS_SUCCESS, data: data.data.GetReportSummaryResponse })
    })
    .catch(middlewareErrorHanlder(dispatch))

middleware[types.GET_EXCEPTION_DETAILS_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    // Promise.resolve(exceptionList)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_EXCEPTION_DETAILS_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.GET_DAX_IMAGE_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    // Promise.resolve(exceptionList)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_DAX_IMAGE_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

middleware[types.UDPATE_EXCEPTION_ACTION_REQUEST] = (dispatch) => (action) => {
  API[action.type](action.data)
    // Promise.resolve(exceptionList)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.UDPATE_EXCEPTION_ACTION_SUCCESS, data: data.data })
    })
    .catch(middlewareErrorHanlder(dispatch))
}

const applyMiddleware = (dispatch) => (action) => middleware[action.type] && middleware[action.type](dispatch)(action)

export { applyMiddleware }
