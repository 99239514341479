import React from 'react';

const Loader = () => (
  <div className="z-1060 loader fixed w-full h-full flex top-0 bottom-0">
    <div className="relative inset-45">
      <div
        className="rounded-full animate-spin"
        style={{
          width: '50px', height: '50px', border: 'solid white 4px', margin: 'auto', borderTopColor: 'red',
        }}
      />
    </div>
  </div>
);

export default Loader;
