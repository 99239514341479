import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import cross from '../../assets/ic-cross.png'
import LabeledInputField from '../../utils/custom-fields'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useContext, useEffect, useState } from 'react'

const CreateItemPopUp = (props) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)

  const contentStyleForDesktopView = {
    padding: '0px',
    alignSelf: 'center',
    width: '400px',
    height: '280px',
    "border-radius": '16px'
  }
  const { searchItemId, Login, creatItemsArray,ClearNoItemFoundMsg } = state
  const { openCreateItemPopUp } = props
  const { register, errors, triggerValidation, handleSubmit, formState } = useForm({ mode: 'onChange' })

  const closeCreateItemPopUp = () => {
    props.closeCreateItemPopUp()
  }
  const searchItem = () => {
    let GetDeviceListRequest = {
      PartnerParentCode: Login && Login.PartnerParentStoreCode,
      PageSize: 10,
      PageNumber: 1,
      TradeId: state && state.searchItemId,
    }
    actions.getSearchItems({ GetDeviceListRequest })
  }
  return (
    <Popup
      contentStyle={contentStyleForDesktopView}
      open={openCreateItemPopUp}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      repositionOnResize={false}
      onClose={() => closeCreateItemPopUp()}
      modal
    >
      <div>
        <div className="cross pos-rel cursor-pointer mt-15 mr-15" id="cross-img">
          <img src={cross} onClick={() => closeCreateItemPopUp()} />
        </div>
        <div>
          <h1 className="popUp-heading pt-50">Create Item</h1>
          <div className="info">
            <div className="flex-container-popup pl-62">
              <div>
                <label className="text-16 font-VodafoneRgBd">Search Trade ID <span className="text-red-1 ml-1">*</span></label>
              </div>
            </div>
            <div className="flex-container-popup pr-50 pl-50 mt-2">
              <LabeledInputField
                type="text"
                name="searchItemId"
                placeholder="VODAFONE_TYPE_TRADE_ID_PLACEHOLDER"
                className="h-40 w-300"
                register={register}
                errors={errors}
                schema=""
                onChange={actions.setSearchItemId}
              />
            </div>
            <div className="actions-createitem">
              <button className="btn-cancel black-button" onClick={() => closeCreateItemPopUp()}>
                Cancel
              </button>
              <button
                className='purple-button btn-search'
                disabled={!searchItemId}
                onClick={() => searchItem()}>
                Search
              </button>
            </div>
            {creatItemsArray && creatItemsArray.DeviceList.length == 0 && ClearNoItemFoundMsg ? < div className="text-red-400 text-center mt-2" >
              No Item Found
            </div> : null}
          </div>
        </div>
      </div>
    </Popup >
  )
}

export default CreateItemPopUp
