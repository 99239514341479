import React, { useContext } from 'react'
import FileUploader from './file-uploader'
import { store } from '../../Store'
import cross from '../../assets/ic-cross.png'
import info from '../../assets/ic-refresh_default.png'

const CaptureTC = (props) => {
  const { state, dispatch } = useContext(store)
  let { selectedFiles, fileError, FileUploadSuccess, TradeInfo, captureCheck, NotificationSuccess,
    verificationSuccessMsg, verificationErrorMsg, orderType, CustomerInfo, validCustomer, idDocsSuccess, fileDetails } = state
  const { TradeQuoteNbr, AssetCatalogName, TradeInfoId } = TradeInfo
  const { ProceedTradeIn, ProceedTradeUp } = CustomerInfo

  const processTrade = orderType === 'Trade In' ? ProceedTradeIn : ProceedTradeUp
  const disabledTc = validCustomer ? (processTrade ? false : true) : true
  const { actions, StoreAgentRole, OrderTradeRoles } = props
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase();

  let frontImage, backImage;
  fileDetails && fileDetails.map(f => {
    if (f.FileName.includes('back')) {
      backImage = f;
    }
    else {
      frontImage = f
    }
  })
  const pushNotification = () => {
    const data = {
      "tradeid": TradeInfoId,
      "platform": AssetCatalogName && (AssetCatalogName.includes('Apple') || AssetCatalogName.includes('APPLE') || AssetCatalogName.includes('apple')) ? "ios" : "android",
      "action": "id-terms"
    }
    actions.pushNotification(data)
  }
  if (NotificationSuccess) {
    setInterval(() => {
      actions.setNotificationFlag()
    }, 10000)
  }
  return (
    <div className="documents flex flex-col relative">
      {NotificationSuccess && <p className="notification-success text-17 leading-28 p-4 bg-green-1 text-white">Link sent to app was successful</p>}
      <div className="">
        <label className="Checkbox-Container xl:ml-17">
          <input type="checkbox" id="capture" name="capture" disabled={disabledTc} onChange={(e) => actions.setCaptureChecked(e.target.checked)} />
          <span className={`Checkbox-Mark mt-1 ${disabledTc ? ' opacity-25 cursor-not-allowed' : ''}`} />
        </label>
        <span className="text-22 font-VodafoneRgBd leading-26 ml-31 xl:ml-51">Capture the T&C And ID
        <img
            src={info}
            disabled={!captureCheck}
            className={`inline-block float-right  ${!captureCheck ? ' opacity-25 cursor-not-allowed pointer-events-none' : 'cursor-pointer'}`}
            onClick={() => actions.getIdDocs(TradeQuoteNbr)} />
        </span>
        <div className="flex flex-row my-20 ml-31  xl:ml-51">
          <div className="flex flex-cols mr-20">
            <button
              className={`${!captureCheck || (OrderTradeRoles['CaptureTCSendAppLink'][agentRole] !== 'SHOW') ? 'opacity-25 cursor-not-allowed pointer-events-none' : ''} app-link text-15 leading-18 w-140 cursor-pointer`}
              id=""
              disabled={!captureCheck || OrderTradeRoles['CaptureTCSendAppLink'][agentRole] !== 'SHOW'}
              onClick={() => pushNotification()}
              name="" >Send app link</button>
          </div>
          < div className={`flex flex-col upload mr-6 ${!captureCheck || (OrderTradeRoles['CaptureTCUploadId'][agentRole] !== 'SHOW') ? 'opacity-25 cursor-not-allowed pointer-events-none' : ''} text-15 leading-18 w-140 cursor-pointer`}>
            {/*  <input className="upload mr-6 text-13" type="text" id="" name="" placeholder="Upload" /> */}
            < FileUploader
              actions={actions}
              className={`${!captureCheck || (OrderTradeRoles['CaptureTCUploadId'][agentRole] !== 'SHOW') ? 'opacity-25 cursor-not-allowed' : ''} text-15 leading-18 w-140 cursor-pointer`}
              selectedFiles={selectedFiles}
              fileError={fileError}
            />
          </div>
        </div>
        < div className={`${FileUploadSuccess ? 'text-green-600 ' : ' text-red-600 '}text-center text-sm textalign`} >
          <div className="text-danger">
            {fileError && fileError.error && <span> {fileError.error} </span>}
            {FileUploadSuccess && <span>File Uploaded Successfully</span>}
          </div>
        </div>
        {idDocsSuccess && fileDetails && fileDetails.length > 0 &&
          <div className="ml-31">
            <p className="font-VodafoneRgBd text-15 leading-18 mb-1">Uploaded ID</p>
            <div className="flex flex-row">
              {/* {fileDetails &&
                fileDetails.map((value, key) => (
                  <div
                    key={key}
                    className={`${key != fileDetails.length - 1 ? 'mr-18' : ''}  cursor-pointer `}
                  // onClick={() => props.setSelectedDeviceName(value)}
                  >
                    <img alt="id-img" className="inline-block w-101 h-64 id-docs" src={`data:image/jpg;base64,${value.FileDesc}`} />
                    <p className='font-VodafoneRgBd text-black text-17 leading-28 text-center'>{key == 0 ? 'Front' : 'Back'}</p>
                  </div>
                ))} */}
              {fileDetails &&
                <div className="mr-18 cursor-pointer">
                  <img alt="id-img" className="inline-block w-101 h-64 id-docs" src={`data:image/jpg;base64,${frontImage.FileDesc}`} />
                  <p className='font-VodafoneRgBd text-black text-17 leading-28 text-center'>Front</p>
                </div>
              }
              {fileDetails &&
                <div className="cursor-pointer">
                  <img alt="id-img" className="inline-block w-101 h-64 id-docs" src={`data:image/jpg;base64,${backImage.FileDesc}`} />
                  <p className='font-VodafoneRgBd text-black text-17 leading-28 text-center'>Back</p>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default CaptureTC
