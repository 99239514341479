import React, { useContext, useEffect } from 'react'
import { useActions } from '../../Action'
import { store } from '../../Store'

import CustomerDetails from './customer-details'
import ConfirmationPopUp from '../confirmOrder-PopUp'
import DeviceSummary from './device-summary'
import AssismentSummary from './assisment-summary'
import WorkingOrder from './working-order'

const OrderDetails = () => {
  const { state, dispatch } = useContext(store)
  const {
    TradeInfo,
    TradeInfoSearched,
    openConfirmationPopUp,
    storeId,
    orderType,
    primaryImeiCheck,
    secondaryImeiCheck,
    deviceModelCheck,
    physicalResultCheck,
    goodWorkingOrderCheck,
    CustomerInfo,
    customerFormVailidity,
    captureCheck,
    customerTradeId,
    submitTradeInfoResponse,
    tradeSuccess,
    oneMonthsCredit,
    twelveMonthsCredit,
    twentyFourMonthsCredit,
    thirtySixMonthsCredit,
    Login,
    billingAccountNumber,
    mobileNumber,
    serviceManamgementNumber,
    validCustomer,
    customerVerificationRequested,
    AMTAStatus,
    FMIPStatus,
    recerringCredit,
    sendLabelCheck,
    EmailLabelSuccess,
    roles,
    customerResolutionFormValidity,
    selectedFiles,
    idDocsSuccess,
    fileDetails,
  } = state
  const { StoreAgentRole } = Login
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase()

  const { DiagnosticTest, DeviceList, AssetCatalogName, DeviceDiagnosticsResult, GWOPrice, NGWOPrice, TradeInStatus, TradeQuoteNbr } = TradeInfo
  const FunctionalResult = DiagnosticTest && DiagnosticTest.filter((d) => d.DiagnosticTestType === 'FUNCTIONALITY')
  const PhysicalResult = DiagnosticTest && DiagnosticTest.filter((d) => d.DiagnosticTestType === 'PHYSICAL')
  const secondaryIMEI = DeviceList && DeviceList.SecondarySerialNbr ? true : false
  const confirmedTrade = TradeInStatus && TradeInStatus.toUpperCase() !== 'OPEN' && TradeInStatus.toUpperCase() !== 'CANCELLED' ? true : false
  const actions = useActions(state, dispatch)
  const confirmTrade = () => {
    actions.openPopUp()
  }
  const credits = orderType === 'Trade In' ? twelveMonthsCredit || twentyFourMonthsCredit || thirtySixMonthsCredit : true
  const disabledLock =
    AssetCatalogName && (AssetCatalogName.includes('Apple') || AssetCatalogName.includes('APPLE') || AssetCatalogName.includes('apple')) ? (FMIPStatus === 'FAILED' ? true : false) : false

  const enableConfirmBtn =
    primaryImeiCheck &&
    deviceModelCheck &&
    physicalResultCheck &&
    goodWorkingOrderCheck &&
    customerResolutionFormValidity &&
    customerFormVailidity &&
    (secondaryIMEI ? secondaryImeiCheck : true) &&
    credits &&
    AMTAStatus === 'PASSED' &&
    !disabledLock
  const enableOrderType = primaryImeiCheck && deviceModelCheck && physicalResultCheck && (secondaryIMEI ? secondaryImeiCheck : true)
  const enableRetriggerEmail = sendLabelCheck ? true : false

  const section = confirmedTrade ? 'ConfirmOrder' : 'OpenOrder'
  const OrderTradeRoles = roles && roles.filter((r) => r.menu === 'Order' && r.section === section) && roles && roles.filter((r) => r.menu === 'Order' && r.section === section)[0]

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mt-30 mx-50">
        <ConfirmationPopUp
          openConfirmationPopUp={openConfirmationPopUp}
          closePopUp={() => actions.closePopUp()}
          TradeId={customerTradeId}
          actions={actions}
          navigateToSearch={() => actions.navigateToSearch()}
          state={state}
        />
        <div className="First-block flex flex-col">
          {OrderTradeRoles['DeviceSummary'][agentRole] === 'SHOW' && (
            <DeviceSummary
              DeviceList={DeviceList}
              AssetCatalogName={AssetCatalogName}
              TradeId={customerTradeId}
              actions={actions}
              validCustomer={validCustomer}
              customerVerificationRequested={customerVerificationRequested}
              CustomerInfo={CustomerInfo}
              orderType={orderType}
              TradeInfo={TradeInfo}
            />
          )}
          {OrderTradeRoles['AssismentSummary'][agentRole] === 'SHOW' && (
            <AssismentSummary
              DeviceList={DeviceList}
              FunctionalResult={FunctionalResult}
              PhysicalResult={PhysicalResult}
              actions={actions}
              TradeId={customerTradeId}
              StoreAgentRole={StoreAgentRole}
              OrderTradeRoles={OrderTradeRoles}
            />
          )}
        </div>

        <div className="Second-Block flex flex-col ml-37 xl:ml-20">
          {OrderTradeRoles['WorkingOrder'][agentRole] === 'SHOW' && (
            <WorkingOrder actions={actions} DeviceDiagnosticsResult={DeviceDiagnosticsResult} orderType={orderType} enableOrderType={enableOrderType} GWOPrice={GWOPrice} NGWOPrice={NGWOPrice} />
          )}
        </div>
        {confirmedTrade && OrderTradeRoles['RetriggerEmail'][agentRole] === 'SHOW' && (
          <div className="flex flex-col retrigger-email ml-37 relative">
            {EmailLabelSuccess && <p className="email-success text-17 leading-28 p-4 bg-green-1 text-white">Confirmation email sent successfully</p>}
            <div className="flex flex-col ">
              <div className="">
                <label className="Checkbox-Container">
                  <input type="checkbox" id="retrigger-email" name="sendLabelCheck" onChange={(e) => actions.setSendLabelCheck(e.target.checked)} />
                  <span className={`Checkbox-Mark`} />
                </label>
                <span className="text-22 leading-26 font-VodafoneRgBd text-span">Retrigger Confirmation Email</span>
              </div>
            </div>
            <div className="mt-20">
              <button
                disabled={!enableRetriggerEmail}
                className={`${!enableRetriggerEmail ? 'cursonr-not-allowed opacity-25' : ''} btn-retrigger-email`}
                onClick={() => actions.sendEmailLabel(TradeQuoteNbr)}
              >
                Retrigger Confirmation Email
              </button>
            </div>
          </div>
        )}
        <div className="Third-Block info flex flex-col ml-30 xl:ml-20 font-VodafoneRg">{goodWorkingOrderCheck && <CustomerDetails StoreAgentRole={StoreAgentRole} OrderTradeRoles={OrderTradeRoles} />}</div>
      </div>
      <div className={`${validCustomer === undefined || validCustomer === false ? 'xl:mr-44' : 'xl:mr-27'} confirm mb-30  self-end mr-50  xl:mt-20`}>
        <button className={`${!enableConfirmBtn ? 'disable-button' : 'purple-button'} btn`} type="button" disabled={!enableConfirmBtn} onClick={() => confirmTrade()}>
          Confirm
        </button>
      </div>
    </div>
  )
}
export default OrderDetails
