import React, { useContext, useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { store } from '../Store'
import { useActions } from '../Action'
import LabeledInputField from '../utils/custom-fields'
import vodaLogo from '../assets/img-vodafone.png'
import vodaBanner from '../assets/Banner.png'

import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Login = (props) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const { register, errors, trigger, handleSubmit, formState } = useForm({ mode: 'onChange' })
  const { isValid } = formState
  const displayNationalIdError = false
  const resources = state && state.resources
  const userAuthenticated = state && state.isUserAuthenticated
  const { isForgotPswdClicked } = state
  const login = state && state.Login
  const { emailId, password, Status, vodafoneAgentId, setCaptcha } = login
  const [isButtonDisable, setButtonDisable] = useState(true)

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    return token
  }, [executeRecaptcha]);


  if (Status && Status.toLowerCase() === 'change_pass') {
    history.replace('/changepass')
  }
  if (Status && Status.toLowerCase() === 'success') {
    history.replace('/orderSearch')
  }
  if (isForgotPswdClicked) {
    history.replace('/forgotpass')
  }

  useEffect(() => {
    if (isValid &&  Object.keys(errors).length === 0) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }, [vodafoneAgentId, password, isValid, errors])

  const confirmOrder = async () => {
    trigger()
    const token = await handleReCaptchaVerify()
    if (password && vodafoneAgentId) {
      actions.login({
        username: vodafoneAgentId,
        password: password,
        captchatoken: token,
      })
    }
  }

  return (
    <div>
      <div className="">
        <img src={vodaLogo} className="mt-50 mb-30 ml-50" />
      </div>
      <div className="">
        <img src={vodaBanner} className="" />
      </div>
      <div className="mx-auto max-w-screen-md">
      <div className="mt-70 flex flex-row">
          <div className="inline-blcok mr-20 lg:mr-30">
            <LabeledInputField
              type="text"
              name="vodafoneAgentId"
              label="VODAFONE_AGENT_ID_LABEL"
              placeholder="VODAFONE_ID_PLACEHOLDER"
              className={`mt-10 w-300 h-50 input_box_bold ${displayNationalIdError ? 'input_box_error' : ''}`}
              onChange={actions.setVodafoneAgntID}
              register={register}
              errors={errors}
              schema="verification"
            />
          </div>
          <div className="inline-blcok mr-20 lg:mr-30">
            <LabeledInputField
              type="password"
              name="password"
              labelClass="inline-block"
              label="VODAFONE_LABEL_PASSWORD"
              placeholder="VODAFONE_PSWD_PLACEHOLDER"
              className={`mt-10 w-300 h-50 input_box_bold ${displayNationalIdError ? 'input_box_error' : ''}`}
              onChange={actions.setPassword}
              register={register}
              errors={errors}
              schema="verification"
            />
            <div>
            <a
                className="cursor-pointer text-17 leading-28 mt-10 block font-VodafoneRg active:text-purple-1 active:underline focus:underline focus:text-purple-1"
                onClick={() => {
                  actions.navigateToForgotPassword()
                }}
              >
                {resources.VODAFONE_LBL_FORGOT_PASSWORD}
              </a>
            </div>
          </div>
          <div className="mt-34">
            <button
              className={`${isButtonDisable ? 'disable-button' : 'purple-button'} btn`}
              disabled={isButtonDisable}
              onClick={() => confirmOrder()}
            >
              {resources.VODAFONE_LOGIN_BUTTON}
            </button>
          </div>
        </div>
        {Status && (Status.toLowerCase() === 'failure' || Status.toLowerCase() === 'account_lock') && (
          <div className="login-container">
            <div className="mt-10 text-center">
              <p className="error-text">{Status.toLowerCase() === 'failure' ? 'user not found.' : 'Too many failed attempts. Your account has been locked, please try again in 10 minutes.'}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Login
