import React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import LabeledInputField, { RenderSelectField } from '../../utils/custom-fields'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import Paginate from '../pagination'
import { MemoryRouter as Router, Switch, Route, Link } from 'react-router-dom'
const moment = require('moment')
import Dropdown from 'react-dropdown'
import ActionPopUp from './action-popUp'

const INVOKE_ONCE = true;

const exceptionDetails = () => {
    const { state, dispatch } = useContext(store)
    const actions = useActions(state, dispatch)
    const history = useHistory()
    const { register, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' })
    let { openActionPopUp, resources, selectedPage, exceptionResponse, roles, exceptionList, exceptionSuccess, daxImagesResponse, requestedRedemptionOrder, masterdata, Login} = state
    // const ExceptionRoles = roles && roles.filter(r => r.menu === 'Exceptions' && r.section === "Tabs") &&
	// 	roles && roles.filter(r => r.menu === 'Exceptions' && r.section === "Tabs")[0];
    const partnerStoreAgentId = Login && Login.AgentId
    const careRole = Login && Login.StoreAgentRole && Login.StoreAgentRole.toUpperCase() === 'CARE'
    const TotalRecords = exceptionResponse && exceptionResponse.TotalRecords
    const pageLimit = 10
    let pageCount = Math.ceil(TotalRecords / pageLimit)
    if(exceptionList && exceptionList.length > 0 && daxImagesResponse && daxImagesResponse.length > 0){
        exceptionList.map((data) => {
            if(data.RedemptionOrderId === requestedRedemptionOrder){
                data.daxImages = daxImagesResponse.map((image) => {
                    return image
                })
                // actions.setExceptionAction(exceptionList)
            }
        })
    }
    
    const getDaxImages = (RedemptionOrderId) => {
        actions.getDAXImage(RedemptionOrderId)
    }
    useEffect(() => {
        let GetExceptionRequest = {
            pageNumber : "1",
            pageSize : "10",
            tradeId : "",
            startDate : "",
            endDate : "",
            escalationCode : "",
            agentId : partnerStoreAgentId
        }
        actions.getExceptionDetails({ GetExceptionRequest })
    }, [INVOKE_ONCE])
    
    const setExceptionAction = (e, tradeId) => {
                    exceptionList && exceptionList.map( (data) => {
           if(data.TradeId === tradeId){
               data.actionException = e
            }
            return data
        })
        actions.setExceptionAction(exceptionList)
        actions.openActionPopUp(tradeId)
    }
    const setSelectedPage = (index) => {
        actions.setSelectedPage(index)
    }
    const options = []
    const actionsExcptn = masterdata && masterdata.TradeEscalationResolution
    actionsExcptn &&
        actionsExcptn.map((data) => {
                options.push({
                    label: data.EscalationResolutionName,
                    value: data.EscalationResolution
                })        
        })
    const baseURLReturn = 'https://msto.startrack.com.au/track-trace/?id='
    const starTrackURL = 'https://msto.startrack.com.au/track-trace/?id='
    const auspostBaseURL =  'https://auspost.com.au/mypost/track/#/details/'

    return (
        <div>
            <table className="mt-15 text-sm border-white w-full h-50 border border-gray-2">
                  <thead className=" text-transform: uppercase border-white bg-grey-4 text-white h-50 text-left font-VodafoneRgBd border border-gray-2 ">
                    <tr>
                        <th className="h-34 pl-8 xl:pl-11 text-sm leading-17"> <span className="columnTitle">TRADE ID</span></th>
                        <th className="leading-17 pl-13 xl:pl-11 h-34 ">DATE/TIME</th>
                        <th className="pl-13 xl:pl-11 leading-17">DEVICE IMEI </th>
                        <th className="pl-13 xl:pl-11">EXCEPTION STATUS</th>
                        <th className="pl-13 xl:pl-11 ">APP CONDITION</th>
                        <th className="pl-13 xl:pl-11"> GRADED CONDITION</th>
                        <th className="pl-13 xl:pl-11"><span className="columnTitle"> RAISED LOCATION</span></th>
                        <th className="pl-13 xl:pl-11"><span className="columnTitle">PHOTO</span></th>
                        <th className="pl-13 xl:pl-11"><span className="columnTitle">SHIPPING TO ASURION</span></th>
                        <th className="pl-13 xl:pl-11"><span className="columnTitle">SHIPPING FOR RETURN</span></th>
                    </tr>
                  </thead>
                  {exceptionList && exceptionList.length > 0 ? (
                    exceptionList.map((table_Detail, index) => (
                      <tbody>
                        <tr className="text-sm text-#000000 border border-gray-2">
                            <td className="pl-8 xl:pl-11">{table_Detail.TradeId}</td>
                            <td className="pl-13 xl:pl-11">
                                {table_Detail.ExceptionCreatedDate ? <div>
                            {moment(table_Detail.ExceptionCreatedDate).format('DD/MM/YYYY h:mm:ss').split(' ')[0]}
                            {' '}
                            {moment(table_Detail.ExceptionCreatedDate).format('DD/MM/YYYY h:mm:ss').split(' ')[1]} </div> : '-'}
                            </td>
                            <td className="pl-13 xl:pl-11"><span className=" font-bold">{'P'}&nbsp;</span>{table_Detail.PrimaryIMEI}<br />{table_Detail.SecondaryIMEI ? <div>
                                <span className=" font-bold">{'S'}&nbsp;</span>{table_Detail.SecondaryIMEI} </div> : ''}</td>
                            <td className="h-34 pl-13 xl:pl-11 text-sm leading-17 text-left">{table_Detail.CurrentStatus}</td>
                            <td className="pl-13 xl:pl-11">{table_Detail.AppCondition === 'PASSED' ? <div>GWO</div> : <div>NGWO</div> }</td>
                            <td className="pl-13 xl:pl-11">{table_Detail.GradedCondition}</td>
                            <td className="pl-13 xl:pl-11">{table_Detail.RaisedCondition}</td>
                            {/* { table_Detail.Action ? <div>{table_Detail.Action === 'RTN' ? <div>Return to Customer</div> : <div>Apply Credit</div> }</div> :
                                table_Detail.AppCondition === 'PASSED' ? table_Detail.GradedCondition === 'INELIGB' ||  table_Detail.GradedCondition === 'NGWO' ?
                                    <RenderSelectField
                                        name="exceptionList"
                                        label=""
                                        className={`select_box_exceptions_action ${ table_Detail.actionException !== "" && table_Detail.actionException !== undefined ? 'cursor-not-allowed bg-gray-2' : ''  }`}
                                        register={register}
                                        onChange={(e) => setExceptionAction(e, table_Detail.TradeId)}
                                        options={options}
                                        optionsKey="value"
                                        optionsValue="label"
                                        errors={errors}
                                        schema="logistics"
                                        value={table_Detail.actionException}
                                        disabled = { table_Detail.actionException !== "" && table_Detail.actionException !== undefined ? true : false }
                                    /> 
                                    :
                                    table_Detail.GradedCondition === 'INELGIBLE' ?
                                    <RenderSelectField
                                        name="exceptionList"
                                        label=""
                                        className={`select_box_exceptions_action ${ table_Detail.actionException !== "" && table_Detail.actionException !== undefined ? 'cursor-not-allowed bg-gray-2' : ''  }`}
                                        register={register}
                                        onChange={(e) => setExceptionAction(e, table_Detail.TradeId)}
                                        options={options}
                                        optionsKey="value"
                                        optionsValue="label"
                                        errors={errors}
                                        schema="logistics"
                                        value={table_Detail.actionException}
                                        disabled = { table_Detail.actionException !== "" && table_Detail.actionException !== undefined ? true : false }
                                    /> 
                                    : null : null
                            } */}
                            
                            <td className="pl-13 xl:pl-11 text-decoration: underline text-green-1 text-17 text-left font-VodafoneRg">
                                    {table_Detail.daxImages ?
                                        table_Detail.daxImages.map((i) => {
                                            return <span><a href={i.Url}  target='_blank' rel="noopener noreferrer">{i.FileName}</a><br /></span>
                                        }) : <span className="cursor-pointer" onClick={ () => getDaxImages(table_Detail.RedemptionOrderId)}>Click to View</span>}</td>
                            <td className="pl-13 xl:pl-11 text-green-1 text-17 text-left font-VodafoneRgBd text-decoration: underline">
                                {table_Detail.TrackingNumber ? <a href={table_Detail.RaisedCondition && table_Detail.RaisedCondition.toUpperCase() === 'ONLINE' ? auspostBaseURL + table_Detail.TrackingNumber : starTrackURL + table_Detail.TrackingNumber} target='_blank' rel="noopener noreferrer">{table_Detail.TrackingNumber}</a> : null }</td>
                            <td className="pl-13 xl:pl-11 text-green-1 text-17 text-left  font-VodafoneRgBd text-decoration: underline">
                                {table_Detail.DeviceReturnTrackingNumber ? <a href={baseURLReturn + table_Detail.DeviceReturnTrackingNumber} target='_blank' rel="noopener noreferrer">{table_Detail.DeviceReturnTrackingNumber}</a> : null}</td>
                        </tr>
                      </tbody>
                       )) ) : <tbody><tr className="text-sm text-#000000 border border-gray-2"> 
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td className=" font-bold text-center">No Records Found</td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       </tr></tbody>}
                </table> 
                <ActionPopUp openActionPopUp={openActionPopUp}/>
                 <div className="flex justify-between mt-17">
                {pageCount === 0 ? <div></div> : <div>Page {selectedPage + 1} of {pageCount}</div>}
                {pageCount === 0 ? <div></div> : 
                  <Paginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={<a>...</a>}
                    breakClassName={'break-me'}
                    pageCount= {pageCount}
                    selectedPage={selectedPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={(page) => setSelectedPage(page.selected)}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                }
                </div> 
            </div>
    )
}

export default exceptionDetails
