import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import cross from '../assets/ic-cross.png'
import JsBarcode from 'jsbarcode'
import * as htmlToImage from 'html-to-image'
import { toJpeg } from 'html-to-image'
const ConfirmationPopUp = (props) => {
  const contentStyleForDesktopView = {
    padding: '0px',
    alignSelf: 'center',
    width: 'auto',
    borderRadius: '25px',
  }

  const { openConfirmationPopUp, TradeId, actions, state } = props
  const {
    submitTradeInfoResponse,
    tradeSuccess,
    TradeInfo,
    CustomerInfo,
    Login,
    billingAccountNumber,
    mobileNumber,
    serviceManamgementNumber,
    twelveMonthsCredit,
    twentyFourMonthsCredit,
    thirtySixMonthsCredit,
    oneMonthsCredit,
    additionalMsgPopUpContent,
    orderType,
    partnerStoreName,
    masterdata,
    selectedFiles,
    AddressId,
    customerTradeId,
    isUpFront,
    submitionDate,
    submitionTime,
    BatchId,
    PartnerStoreCodeNbr,
  } = state
  const { TradeUpDevice, RemainingTerm, OneOffAmount, ServiceOrderId } = CustomerInfo
  const { DeviceList, DeviceDiagnosticsResult, NGWOPrice, GWOPrice, TradeQuoteNbr, FinalPrice, AssetCatalogName, CreditApplyPeriod, TradeInStatus, BonusCredit } = TradeInfo

  const TradeStatus = TradeInStatus && TradeInStatus.toUpperCase() !== 'OPEN' && TradeInStatus.toUpperCase() !== 'CANCELLED'
  let Bonus = BonusCredit ? BonusCredit : 0
  let totalTradeUpCredit = FinalPrice + Bonus
  let creditTradeUp = TradeStatus ? totalTradeUpCredit / CreditApplyPeriod : RemainingTerm ? totalTradeUpCredit / RemainingTerm : 0
  let totalCredit = FinalPrice
  let _creditTradeIn = twelveMonthsCredit ? totalCredit / 12 : twentyFourMonthsCredit ? totalCredit / 24 : thirtySixMonthsCredit ? totalCredit / 36 : undefined
  let totalCreditTradeUp = FinalPrice + Bonus
  let credit = orderType === 'Trade Up' ? (OneOffAmount && isUpFront ? totalCreditTradeUp : creditTradeUp) : _creditTradeIn
  let creditPeriod = orderType === 'Trade Up' ? (OneOffAmount && isUpFront ? 0 : RemainingTerm) : twelveMonthsCredit ? 12 : twentyFourMonthsCredit ? 24 : thirtySixMonthsCredit ? 36 : undefined
  let filteredStore = masterdata && masterdata.Stores && masterdata.Stores.filter((s) => s.PartnerStoreName === partnerStoreName)
  let PartnerStoreId = filteredStore && filteredStore[0] && filteredStore[0].PartnerStoreId
  const agentChannel = Login && Login.PartnerStoreChannel && Login.PartnerStoreChannel.toUpperCase() === 'AGENT CHANNEL'
  const careRole = Login && Login.StoreAgentRole && Login.StoreAgentRole.toUpperCase() === 'CARE'
  const eParcelRequest = agentChannel && careRole

  const [tcChecked, setTcCheck] = useState(false)
  const [uploadChecked, setUploadCheck] = useState(false)
  const [devcieQualityChecked, setDeviceQuality] = useState(false)

  // const enableSubmitBtn = tcChecked && uploadChecked && devcieQualityChecked

  const submitTradeInfo = () => {
    const SubmitTradeInfoRequest = {
      SubmitTradeInfoRequest: {
        CustomerId: TradeInfo && TradeInfo.CustomerId,
        FirstName: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.firstName !== undefined ? CustomerInfo.CustomerUpdatedInfo.firstName : '',
        LastName: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.lastName !== undefined ? CustomerInfo.CustomerUpdatedInfo.lastName : '',
        AddressId: AddressId,
        AddressLine1: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.addressLine1 !== undefined ? CustomerInfo.CustomerUpdatedInfo.addressLine1 : '',
        AddressLine2: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.addressLine2 !== undefined ? CustomerInfo.CustomerUpdatedInfo.addressLine2 : '',
        AddressLine3: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.addressLine3 !== undefined ? CustomerInfo.CustomerUpdatedInfo.addressLine3 : '',
        CityName: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.cityName !== undefined ? CustomerInfo.CustomerUpdatedInfo.cityName : '',
        StateProvinceCode: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.stateName !== undefined ? CustomerInfo.CustomerUpdatedInfo.stateName : '',
        PostalCode: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.postalCode !== undefined ? CustomerInfo.CustomerUpdatedInfo.postalCode : '',
        PhoneContactPointId: undefined,
        PhoneNbr: undefined,
        TradeInfoId: TradeInfo && TradeInfo.TradeInfoId,
        TradeInStatus: 'Confirmed',
        PrimaryAssetId: TradeInfo && TradeInfo.DeviceList && TradeInfo.DeviceList.PrimaryAssetId,
        AssetCatalogId: TradeInfo && TradeInfo.DeviceList && TradeInfo.DeviceList.PrimaryAssetId,
        TradeQuoteNbr: TradeInfo && TradeInfo.TradeQuoteNbr,
        StoreId: PartnerStoreId,
        LoginEmailAddress: Login && Login.vodafoneAgentId,
        PrimaryIMEI: TradeInfo && TradeInfo.DeviceList && TradeInfo.DeviceList.PrimarySerialNbr,
        BAN: billingAccountNumber,
        MSISDN: mobileNumber,
        ExternalOrderNumber: ServiceOrderId,
        BonusCredit: Bonus,
        CreditApplyPeriod: creditPeriod,
        FinalCredit: credit,
        Notes: additionalMsgPopUpContent,
        TradeFulfillType: orderType,
        UserEmail: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.emailAddress !== undefined ? CustomerInfo.CustomerUpdatedInfo.emailAddress : '',
        AssetCatalogId: TradeUpDevice && TradeUpDevice.TradeUpDeviceCatalogId,
        ExternalReferenceId: TradeUpDevice && TradeUpDevice.ItemCode,
        ClientAssetSkuNumber: TradeUpDevice && TradeUpDevice.ItemName,
        TradeUpIMEI: TradeUpDevice && TradeUpDevice.IMEI,
        TradeInMethod: eParcelRequest || PartnerStoreCodeNbr === 'VODACARE' ? 'ONLINE' : 'IN-STORE',
      },
    }
    let FileUploadRequest = {
      FileUploadRequest: {
        TradeId: customerTradeId,
        UploadDetails: selectedFiles,
      },
    }
    if (selectedFiles && selectedFiles.length > 0) {
      actions.uploadFiles(FileUploadRequest)
    }
    const contactPointRequest = {
      tradeId: TradeInfo && TradeInfo.TradeInfoId,
      firstName: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.firstName !== undefined ? CustomerInfo.CustomerUpdatedInfo.firstName : '',
      lastName: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.lastName !== undefined ? CustomerInfo.CustomerUpdatedInfo.lastName : '',
      address1: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.addressLine1 !== undefined ? CustomerInfo.CustomerUpdatedInfo.addressLine1 : '',
      address2: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.addressLine2 !== undefined ? CustomerInfo.CustomerUpdatedInfo.addressLine2 : '',
      address3: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.addressLine3 !== undefined ? CustomerInfo.CustomerUpdatedInfo.addressLine3 : '',
      city: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.cityName !== undefined ? CustomerInfo.CustomerUpdatedInfo.cityName : '',
      state: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.stateName !== undefined ? CustomerInfo.CustomerUpdatedInfo.stateName : '',
      country: 'AUS',
      zipCode: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.postalCode !== undefined ? CustomerInfo.CustomerUpdatedInfo.postalCode : '',
      mobile: mobileNumber,
      EmailId: CustomerInfo && CustomerInfo.CustomerUpdatedInfo && CustomerInfo.CustomerUpdatedInfo.emailAddress !== undefined ? CustomerInfo.CustomerUpdatedInfo.emailAddress : '',
    }
    const pushNotificationRequest = {
      tradeid: TradeInfo && TradeInfo.TradeInfoId,
      platform: AssetCatalogName.includes('Apple') || AssetCatalogName.includes('APPLE') || AssetCatalogName.includes('apple') ? 'ios' : 'android',
      action: 'trade-confirmation',
    }

    actions.createContactPoint({
      contactPointRequest: contactPointRequest,
      SubmitTradeInfoRequest: SubmitTradeInfoRequest,
      pushNotificationRequest: pushNotificationRequest,
    })
  }
  useEffect(() => {
    if (submitTradeInfoResponse && !BatchId && eParcelRequest) {
      const CreateBatchRequest = {
        PartnerStoreId: AddressId,
        BatchItems: [
          {
            TradeInfoId: submitTradeInfoResponse.TradeInfoId,
            ShippingOrderId: submitTradeInfoResponse.InboundShippingOrderId,
          },
        ],
      }
      actions.createBatchOnsubmit({ CreateBatchRequest })
    }
    if (submitTradeInfoResponse && BatchId) {
      const CreateParcelRequest = {
        BatchId: BatchId.toString(),
      }
      actions.createParcelOnSubmit({ CreateParcelRequest })
    }
  }, [submitTradeInfoResponse, BatchId])

  const border = '1px solid balck'
  const saveImage = () => {
    // htmlToImage.toJpeg(document.getElementById('printableArea'), { backgroundColor: 'white' }, { Color: 'red' }, { borderWidth: '1px' }, { borderStyle: 'solid' }, { border }).then((dataUrl) => {
    //   const link = document.createElement('a')
    //   link.download = 'barcode.png'
    //   link.href = dataUrl
    //   link.click()
    // })

    htmlToImage
      ?.toJpeg(document.getElementById('printableArea'), { backgroundColor: 'white' }, { Color: 'red' }, { borderWidth: '1px' }, { borderStyle: 'solid' }, { border }, { width: '500px' })
      .then(function (dataUrl) {
        var link = document.createElement('a')
        link.download = 'barcode.jpeg'
        link.href = dataUrl
        link.click()
      })
  }

  const barcodeCanvas = (text) => {
    let canvas = document.createElement('canvas')
    JsBarcode(canvas, text, {
      format: 'CODE39',
      width: 2,
      height: 40,
      displayValue: false,
      //textPosition: 'top',
      margin: 0,
    })
    return canvas.toDataURL('image/jpeg')
  }
  const closePopUp = () => {
    if (tradeSuccess) {
      props.navigateToSearch()
      document.getElementById('order-search').reset()
      actions.resetCustomerTradeIdField(true)
    }
    // setTcCheck(false)
    // setUploadCheck(false)
    // setDeviceQuality(false)
    props.closePopUp()
  }
  return (
    <Popup contentStyle={contentStyleForDesktopView} open={openConfirmationPopUp} closeOnDocumentClick={false} closeOnEscape={false} repositionOnResize={false} onClose={() => closePopUp()} modal>
      <div className={`${tradeSuccess ? (eParcelRequest ? 'modal-pop-up-eparcel' : 'modal-pop-up-print') : 'modal-pop-up'}`}>
        <div className="cross" id="cross-img">
          <img src={cross} onClick={() => closePopUp()} />
        </div>
        <div className={`${tradeSuccess ? 'm-30 xl:m-20' : 'innerContent'}`}>
          {!tradeSuccess && <h1 className="popUp-heading">Confirm Order</h1>}
          {!tradeSuccess && (
            <div className="info">
              <div className="flex-container-popup">
                <div>
                  <lable>Device IMEI NO</lable>
                </div>
                <div>
                  <p>{DeviceList && DeviceList.PrimarySerialNbr}</p>
                </div>
              </div>
              <div className="flex-container-popup">
                <div>
                  <lable>Trade ID</lable>
                </div>
                <div>
                  <p> {TradeQuoteNbr} </p>
                </div>
              </div>
              {orderType === 'Trade In' && (
                <div className="flex-container-popup">
                  <div>
                    <lable>Total Trade-in Credit</lable>
                  </div>
                  <div>
                    <p>
                      ${totalCredit.toFixed(2)}/ ${_creditTradeIn && _creditTradeIn.toFixed(2)} monthly
                    </p>
                  </div>
                </div>
              )}
              {orderType === 'Trade Up' && (
                <div className="flex-container-popup">
                  <div>
                    <lable>Total Trade-up Credit</lable>
                  </div>
                  <div>
                    {OneOffAmount && isUpFront ? (
                      <p>${totalCreditTradeUp && totalCreditTradeUp.toFixed(2)}</p>
                    ) : creditTradeUp === 0 ? (
                      <p>${totalCreditTradeUp && totalCreditTradeUp.toFixed(2)}</p>
                    ) : (
                      <p>
                        ${totalCreditTradeUp && totalCreditTradeUp.toFixed(2)} / ${creditTradeUp && creditTradeUp.toFixed(2)} monthly
                      </p>
                    )}
                  </div>
                </div>
              )}
              {/* <div className="flex flex-row flex-container-popup terms-check-confirmation">
                <div className="terms-check-confirmation-label">
                  <span className="flex flex-col font-VodafoneRg">The customer has agreed to the T&C</span>
                </div>
                <div className="terms-check-confirmation-checkbox">
                  <label className="flex flex-col Checkbox-Container">
                    <input type="checkbox" id="terms" name="termsCheck" onChange={(e) => setTcCheck(e.target.checked)} />
                    <span className={`Checkbox-Mark`} />
                  </label>
                </div>
              </div>
              <div className="flex flex-row flex-container-popup terms-check-confirmation">
                <div className="terms-check-confirmation-label">
                  <span className="flex flex-col font-VodafoneRg">The customer has uploaded appropriate ID</span>
                </div>
                <div className="terms-check-confirmation-checkbox">
                  <label className="flex flex-col Checkbox-Container">
                    <input type="checkbox" id="upload" name="uploadCheck" onChange={(e) => setUploadCheck(e.target.checked)} />
                    <span className={`Checkbox-Mark`} />
                  </label>
                </div>
              </div>
              <div className="flex flex-row flex-container-popup terms-check-confirmation">
                <div className="terms-check-confirmation-label">
                  <span className="flex flex-col font-VodafoneRg">The customers device quality matches the condition in the app</span>
                </div>
                <div className="terms-check-confirmation-checkbox">
                  <label className="flex flex-col Checkbox-Container">
                    <input type="checkbox" id="upload" name="uploadCheck" onChange={(e) => setDeviceQuality(e.target.checked)} />
                    <span className={`Checkbox-Mark`} />
                  </label>
                </div>
              </div> */}
              <div className="actions mt-4">
                <button className="grey-button feature-buttons" onClick={() => closePopUp()}>
                  Cancel
                </button>
                {/* <button className={`${!enableSubmitBtn ? 'disable-button' : 'purple-button'} feature-buttons`} type="button" onClick={() => submitTradeInfo()}> */}
                <button className="purple-button feature-buttons" type="button" onClick={() => submitTradeInfo()}>
                  Submit
                </button>
              </div>
            </div>
          )}
          {tradeSuccess && (
            <div>
              <div className="content flex" id="printableArea">
                <div className="m-4 flex flex-col popup-width">
                  <div className="">
                    <div className="flex flex-row">
                      <div className="flex flex-col  w-1/2 mt-5 xl:mt-10">Date</div>
                      <div className="flex flex-col w-1/2 mt-5 xl:mt-10 font-bold">{submitionDate}</div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex flex-col  w-1/2 mt-5 xl:mt-10">Time</div>
                      <div className="flex flex-col w-1/2 mt-5 xl:mt-10 font-bold">{submitionTime}</div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex flex-col  w-1/2 mt-5 xl:mt-10">Vendor</div>
                      <div className="flex flex-col w-1/2 mt-5 xl:mt-10 font-bold">Asurion</div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex flex-col  w-1/2 mt-5 xl:mt-10">Consumer Type</div>
                      <div className="flex flex-col w-1/2 mt-5 xl:mt-10 font-bold">{orderType}</div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex flex-col  w-1/2 mt-5 xl:mt-10">Trade ID</div>
                      <div className="flex flex-col w-1/2 mt-5 xl:mt-10 font-bold">{TradeInfo.TradeQuoteNbr}</div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex flex-col  w-1/2 mt-5 xl:mt-10">IMEI</div>
                      <div className="flex flex-col w-1/2 mt-5 xl:mt-10 font-bold">{(TradeInfo.DeviceList && TradeInfo.DeviceList.PrimarySerialNbr) || '-'}</div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex flex-col  w-1/2 mt-5 xl:mt-10">Make/Model/Capacity</div>
                      <div className="flex flex-col w-1/2 mt-5 xl:mt-10 font-bold">{TradeInfo.AssetCatalogName || '-'}</div>
                    </div>
                  </div>
                  <div>
                    {!eParcelRequest ? <img id="barcode" className="flex flex-row mt-5 xl:mt-10 mx-auto mb-2" src={barcodeCanvas(submitTradeInfoResponse.RedemptionOrderNbr)} alt="" /> : null}{' '}
                  </div>
                  {!eParcelRequest ? (
                    <div>
                      <div className="flex flex-row">
                        <p className="flex flex-col mt-5 xl:mt-10 font-bold">Instructions :</p>
                      </div>
                      <div className="flex flex-row">
                        <p className="flex flex-col mt-5 xl:mt-10 popup-width">
                          Print the label. Please ensure that the device is packed and sealed with the correct label before the pickup. The order number is indicated in the label as reference.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {!eParcelRequest ? (
                <div className="mt-8 xl:mt-10 flex flex-row justify-center">
                  <button id="savebtn" onClick={saveImage} className="purple-button btn mr-20">
                    Save
                  </button>
                  <button id="printbtn" className="purple-button btn" onClick={() => window.print()}>
                    Print
                  </button>
                </div>
              ) : (
                <div className="mt-8 flex flex-row justify-center">
                  <button id="printbtn" className="purple-button btn" onClick={() => closePopUp()}>
                    Close
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Popup>
  )
}

export default ConfirmationPopUp
