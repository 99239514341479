import React, { Component } from 'react'
import { RenderSelectField, SortSelection } from '../../utils/custom-fields'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { useForm } from 'react-hook-form'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useContext, useState } from 'react'
import Dropdown from 'react-dropdown'

const moment = require('moment')

const SearchItems = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  let { resources, creatItemsArray, Login, logisticsStoreId, UpdateStoreSuccess } = state
  const { register, errors, handleSubmit, formState, schema } = useForm({ mode: 'onChange' })
  const { masterdata } = state

  let tradeInfoId = creatItemsArray && creatItemsArray.DeviceList && creatItemsArray.DeviceList[0].TradeInfoId
  const updateItem = () => {
    let UpdateStoreRequest = {
      TradeInfoId: tradeInfoId,
      PartnerStoreId: logisticsStoreId || '',
    }
    actions.UpdateStore({ UpdateStoreRequest })
  }
  let selectedStoreName = creatItemsArray && creatItemsArray.DeviceList && creatItemsArray.DeviceList[0].StoreName

  const storeNames = masterdata && masterdata.Stores && masterdata.Stores.filter((s) => s.PartnerParentStore === Login.PartnerParentStoreCode)

  let selectedStoreId = storeNames && storeNames.filter(item => item.PartnerStoreName == selectedStoreName)[0].PartnerStoreId
  selectedStoreId = logisticsStoreId ? logisticsStoreId : selectedStoreId

  let options = [];

  storeNames && storeNames.forEach((item) => {
    options.push({ label: item.PartnerStoreName, value: item.PartnerStoreId });
  })


  const backButtonClick = () => {
    actions.backClick()
    let GetDeviceListRequest = {
      PartnerStoreId: Login && Login.PartnerStoreId,
      PartnerParentCode: Login && Login.PartnerParentStoreCode,
      PageSize: 10,
      PageNumber: 1,
    }
    actions.setSelectedPage(0)
    actions.getLogistics({ GetDeviceListRequest })
  }

  let tobatch = creatItemsArray && creatItemsArray.ToBeBatched

  let capitalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  let columnsList = [
    { columnName: "Make", order: ""},
    { columnName: "Model", order: ""},
    { columnName: "ProductType", order: ""},
    { columnName: "Condition", order: ""},
    { columnName: "StoreCode", order: ""},
    { columnName: "StoreName", order: ""},
    { columnName: "MemberId", order: ""},
    { columnName: "TeamMember", order: ""},
    { columnName: "ReferenceNo", order: ""},
    { columnName: "LessThan5Days", order: ""}

  ];

  const [sortColumnsList, setSortOrder] = useState(columnsList)


  const sortData = (fieldName, order) => {
    sortColumnsList.map((item) => {
             if (item.columnName === fieldName) {
               item.order = order
             } else {
               item.order = ""
             }
    });
      setSortOrder(sortColumnsList);
      if(creatItemsArray) {
        creatItemsArray.DeviceList = creatItemsArray.DeviceList.sort(dynamicsort(fieldName,order))
        actions.setCreateItemsData(creatItemsArray);
      }
  }

  const dynamicsort = (property,order) => {
    var sort_order = 1;
    if(order === "DESC"){
        sort_order = -1;
    }
    return function (obj1, obj2){
      // obj1 should come before obj2 in the sorted order
      if (obj1[property] < obj2[property]){
              return -1 * sort_order;
      // obj1 should come after obj2 in the sorted order
      } else if(obj1[property] > obj2[property]){
              return 1 * sort_order;
      // obj1 and obj2 are the same
      } else{
              return 0 * sort_order;
      }
    }
  }

  return (
    <div>
      <div className="flex w-full items-center mt-48">
      </div>
      <div className="flex align-items-center">
        <button className="black-button w-145 h-10 ml-auto text-lg leading-21" onClick={() => actions.openCreateItemPopUp()}>
          {resources.VODAFONE_CREATE_ITEM_BUTTON}
        </button>
        <button
          className='purple-button w-145 h-10 ml-15 text-lg leading-21'
          onClick={() => { backButtonClick() }}
        >
          {resources.VODAFONE_BACK_BUTTON}
        </button>
      </div>
      <div>
        <table className="mt-5 text-sm border-white w-full h-60 border border-gray-2">
          <thead className="text-transform: uppercase border-white bg-grey-4 text-white h-128 text-left font-VodafoneRgBd">
            <tr>
              <th className="arrow" className="w-15 pl-37.5" />
              <th className="h-34 w-10 pl-8 text-sm leading-17 sortEnabledColumn">
                <span className="columnTitle">
                    {'>=1'} <br />
                    days
                </span>

                <SortSelection
                    sortOrder = {sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
                    fieldName = "LessThan5Days"
                    className = "pt-9"
                    onClick = {(order, fieldName) => sortData(fieldName, order) }
                />
              </th>

              <th className="leading-17 pl-5 w-20 h-34 ">

                Reference <br />
                      No.
                    </th>
              <th className="w-20 w-20 pl-5 leading-17">

                Date/
                      <br />
                      Time

                    </th>
              <th className="w-130 pl-5 align-baseline pt-13">Device IMEI</th>
              <th className="w-20  pl-5 align-baseline pt-13 sortEnabledColumn"><span className="columnTitle">Make</span>
                <SortSelection
                  sortOrder = {sortColumnsList.filter((item) => item.columnName === "Make")[0].order}
                  fieldName = "Make"
                  onClick = {(order, fieldName) => sortData(fieldName, order) }
                />
              </th>
              <th className=" w-120 pl-5 sortEnabledColumn">
                <span className="columnTitle">
                  Model/ <br />
                Variant </span>
                <SortSelection
                  sortOrder = {sortColumnsList.filter((item) => item.columnName === "Model")[0].order}
                  fieldName = "Model"
                  onClick = {(order, fieldName) => sortData(fieldName, order) }
                  className = "pt-9"
                />
              </th>
              <th className="w-70 pl-5 sortEnabledColumn">
                <span className="columnTitle">
                  Product
                <br />
                Type
                </span>
                <SortSelection
                  sortOrder = {sortColumnsList.filter((item) => item.columnName === "ProductType")[0].order}
                  fieldName = "ProductType"
                  onClick = {(order, fieldName) => sortData(fieldName, order) }
                  className = "pt-9"
                />
              </th>
              <th className="w-20 pl-5 align-baseline pt-13 sortEnabledColumn"><span className="columnTitle">Condition</span>
                <SortSelection
                  sortOrder = {sortColumnsList.filter((item) => item.columnName === "Condition")[0].order}
                  fieldName = "Condition"
                  onClick = {(order, fieldName) => sortData(fieldName, order) }
                />
              </th>
              <th className="w-50  pl-5 sortEnabledColumn">
                <span className="columnTitle">
                  Store <br />
                Code</span>
                <SortSelection
                    sortOrder = {sortColumnsList.filter((item) => item.columnName === "StoreCode")[0].order}
                    fieldName = "StoreCode"
                    onClick = {(order, fieldName) => sortData(fieldName, order) }
                    className = "pt-9"
                  />
              </th>
              <th className=" w-140 pl-5  align-baseline pt-13 sortEnabledColumn"><span className="columnTitle">Store Name OR Location</span>
                <SortSelection
                  sortOrder = {sortColumnsList.filter((item) => item.columnName === "StoreName")[0].order}
                  fieldName = "StoreName"
                  onClick = {(order, fieldName) => sortData(fieldName, order) }
                />
              </th>
              <th className=" w-75 pl-5  align-baseline pt-13 sortEnabledColumn"><span className="columnTitle">Member ID</span>
                <SortSelection
                  sortOrder = {sortColumnsList.filter((item) => item.columnName === "MemberId")[0].order}
                  fieldName = "MemberId"
                  onClick = {(order, fieldName) => sortData(fieldName, order) }
                />
              </th>
              <th className=" w-120 pl-5  align-baseline pt-13 sortEnabledColumn">
                <span className="columnTitle">Team member</span>
                <SortSelection
                  sortOrder = {sortColumnsList.filter((item) => item.columnName === "TeamMember")[0].order}
                  fieldName = "TeamMember"
                  onClick = {(order, fieldName) => sortData(fieldName, order) }
                />
              </th>
              <th className="w-20 pl-5">To be Batched </th>

              <th className=" w-60 pl-5 align-baseline pt-13">Batch</th>
              <th className="w-100 pl-5">
                Consignment <br />
                      No.
                    </th>
              <th className="h-60 w-20 ">{}</th>
            </tr>
          </thead>
          {creatItemsArray &&
            creatItemsArray.DeviceList.map((table_Detail, index) => (
              <tbody className="" key={index}>
                <tr className="text-sm text-#000000 border border-gray-2 h-50">
                  <td className="w-15 h-50 pl-37.5" />
                  <td className="w-10 h-34 text-sm leading-17 pl-8 ">{capitalizeFirst(table_Detail.LessThan5Days)}</td>
                  <td className="w-20 h-50 pl-5">{table_Detail.ReferenceNo}</td>
                  <td className="w-20 h-50 pl-5">{table_Detail.DateTime ? <div>
                    {moment(table_Detail.DateTime).format('DD/MM/YYYY h:mm:ss').split(' ')[0]}<br />
                    {moment(table_Detail.DateTime).format('DD/MM/YYYY h:mm:ss').split(' ')[1]} </div> : '-'}</td>
                  <td>
                    <td className="w-20 h-50 pl-5"><span className=" font-bold">{'P'}&nbsp;</span>{table_Detail.DeviceIMEI}<br />{table_Detail.SecondDeviceIMEI ? <div>
                      <span className=" font-bold">{'S'}&nbsp;</span>{table_Detail.SecondDeviceIMEI} </div> : ''}</td>
                  </td>
                  <td className="w-20 h-50 pl-5">{table_Detail.Make}</td>
                  <td className="h-50 w-120 pl-5">{table_Detail.Model}</td>
                  <td className="h-50 w-70 pl-5">{table_Detail.ProductType}</td>
                  <td className="w-20 h-50 pl-5">{table_Detail.Condition}</td>
                  <td className="w-50 h-20 pl-5">{table_Detail.StoreCode}</td>

                  <td className="h-34 w-180 pl-4 mt-20 font-VodafoneRg logistics">
                    {/* <RenderSelectField
                      name="storeNameList"
                      label=""
                      className="select_box_logistics"
                      register={register}
                      onChange={(e) => actions.setLogisticsStore(e)}
                      options={storeNames}
                      optionsKey="PartnerStoreId"
                      optionsValue="PartnerStoreName"
                      errors={errors}
                      schema="logistics"
                      value={selectedStoreId}
                    /> */}
                    <Dropdown
                      name="storeNameList"
                      register={register}
                      onChange={(option) => actions.setLogisticsStore(option.value)}
                      options={options}
                      errors={errors}
                      schema="logistics"
                      arrowClosed={<span className="arrow-closed" />}
                      arrowOpen={<span className="arrow-open" />}
                      value={selectedStoreId}
                    />
                  </td>
                  <td className="h-50 w-75 pl-5">{table_Detail.MemberId}</td>
                  <td className="h-50 w-120 pl-5">{table_Detail.TeamMember}</td>
                  <td className="h-50 w-20 pl-5">{capitalizeFirst(tobatch)}</td>
                  <td className="text-red-1 h-50 w-60 pl-5 font-VodafoneRgBd">{table_Detail.BatchId}</td>
                  <td className="h-50 w-100 pl-5">{}</td>
                  <td>
                    <button
                      className={`${!logisticsStoreId ? 'disable-button' : 'black-button'} feature-buttons-update`}
                      disabled={!logisticsStoreId}
                      onClick={() => updateItem()}
                    >
                      {resources.LOGISTICS_UPDATE_BUTTON}
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
        {
          UpdateStoreSuccess && UpdateStoreSuccess.message == "updated successfully" ? < div className="text-green-400 text-center mt-2" >
            Data Updated Successfully.
        </div> : null
        }
      </div>
    </div>
  )
}

export default SearchItems
