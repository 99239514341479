import React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import LabeledInputField, { RenderSelectField, SortSelection, DateInputField } from '../../utils/custom-fields'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useForm } from 'react-hook-form'
// import { useForm } from 'react-hook-form/dist/index.ie11.development'
import Paginator from 'react-hooks-paginator'
import Paginate from '../pagination'
import BatchedOrder from './batched-order'
import CreateItemPopUp from './createItemPopUp'
import SearchItems from './searchItems'
const moment = require('moment')
import datePicker from '../../assets/ic-calendar_default.png'

const LogisticsOrderDetails = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  let { LogisticsInputField, resources, selectedPage, BatchingItems, logisticsData, Login, openCreateItemPopUp, creatItemsArray, UpdateStoreSuccess, redirectToLogistics, ErrorDetails } = state
  const DeviceList = logisticsData && logisticsData.DeviceList
  const TotalRecords = logisticsData && logisticsData.TotalRecords
  const ToBeBatched = logisticsData && logisticsData.ToBeBatched
  const _batchError = ErrorDetails && ErrorDetails.response && ErrorDetails.response.data && ErrorDetails.response.data.response && ErrorDetails.response.data.response.message
  useEffect(() => {
    let GetDeviceListRequest = {
      PartnerStoreId: Login && Login.PartnerStoreId,
      PartnerParentCode: Login && Login.PartnerParentStoreCode,
      PageSize: 10,
      PageNumber: 1,
    }
    actions.getLogistics({ GetDeviceListRequest })
  }, [true])
  const toggleSelection = (e, id) => {
    DeviceList &&
      DeviceList.map((el) => {
        if (el.ReferenceNo === id) {
          el.isSelected = e.target.checked
        }

        return el
      })
    actions.setLogisticsData(DeviceList)
  }

  const toggleSelectionAll = (e) => {
    DeviceList &&
      DeviceList.map((el) => {
        el.isSelected = e.target.checked
        return el
      })
    actions.setLogisticsDataAll(DeviceList)
  }
  const { register, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' })

  const pageLimit = 10
  const totalPages = Math.ceil(TotalRecords / pageLimit)
  const range = (from, to, step = 1) => {
    let i = from
    const range = []
    while (i <= to) {
      range.push(i)
      i += step
    }
    return range
  }
  let pages = range(1, totalPages)
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  let [data, setData] = DeviceList ? useState(DeviceList) : useState([])
  const [currentData, setCurrentData] = useState([])

  useEffect(() => {
    setCurrentData(data.slice(offset, offset + pageLimit))
  }, [offset, data])

  useEffect(() => {
    return () => {
      actions.setOrderNumber(undefined);
      actions.setStartDate(undefined);
      actions.setEndDate(undefined);
      actions.setMake(undefined);
      actions.setModelVarriant(undefined);
      actions.setCondition(undefined);
      actions.setSelectedPage(0);
      actions.setLogisticsData({})
    }
  }, []);

  const searchLogistics = (isSearch = false) => {
    if (isSearch) {
      actions.setSelectedPage(0);
    }
    let GetDeviceListRequest = {
      PartnerStoreId: Login && Login.PartnerStoreId,
      PageSize: 10,
      PageNumber: isSearch ? 1 : selectedPage + 1,
      Make: LogisticsInputField.Make,
      Model: LogisticsInputField.Model_Varriant,
      Condition: LogisticsInputField.Condition,
      StartDate: LogisticsInputField.StartDate,
      EndDate: LogisticsInputField.EndDate,
      TradeId: LogisticsInputField.OrderNumber,
      PartnerParentCode: Login && Login.PartnerParentStoreCode,
    }
    actions.getLogistics({ GetDeviceListRequest })
  }

  const setSelectedPage = (index) => {
    actions.setSelectedPage(index)
  }


  useEffect(() => {
    searchLogistics()
  }, [selectedPage])

  let selectedList =
    DeviceList &&
    DeviceList.filter((item) => {
      return item.isSelected === true
    }).length

  let enableCreateBatch = selectedList > 0 && selectedList <= 10

  const createBatch = () => {
    let batchItems =
      DeviceList &&
      DeviceList.filter((item) => {
        return item.isSelected === true
      }).map((item) => {
        return { ShippingOrderId: item.ShippingOrderId, TradeInfoId: item.TradeInfoId }
      })

    let CreateBatchRequest = {
      PartnerStoreId: Login && Login.PartnerStoreId,
      BatchItems: batchItems,
    }
    actions.createBatch({ CreateBatchRequest })
  }

  const resetFields = () => {
    actions.setOrderNumber(undefined);
    actions.setStartDate(undefined);
    actions.setEndDate(undefined);
    actions.setMake(undefined);
    actions.setModelVarriant(undefined);
    actions.setCondition(undefined);
    if (selectedPage == 0) {
      let GetDeviceListRequest = {
        PartnerStoreId: Login && Login.PartnerStoreId,
        PageSize: 10,
        PageNumber: 1,
        PartnerParentCode: Login && Login.PartnerParentStoreCode,
      }
      actions.getLogistics({ GetDeviceListRequest })
    }
    actions.setSelectedPage(0);
    setValue("TradeID", "")
    setValue("StartDate", "")
    setValue("EndDate", "")
    setValue("Make", "")
    setValue("Model_Varriant", "")
    setValue("Condition", "")
  }

  let pageCount = Math.ceil(TotalRecords / pageLimit)

  let capitalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  let columnsList = [
    { columnName: "Make", order: ""},
    { columnName: "Model", order: ""},
    { columnName: "ProductType", order: ""},
    { columnName: "Condition", order: ""},
    { columnName: "StoreCode", order: ""},
    { columnName: "StoreName", order: ""},
    { columnName: "MemberId", order: ""},
    { columnName: "TeamMember", order: ""},
    { columnName: "ReferenceNo", order: ""},
    { columnName: "LessThan5Days", order: ""}

  ];

  const [sortColumnsList, setSortOrder] = useState(columnsList)


  const sortData = (fieldName, order) => {
    sortColumnsList.map((item) => {
             if (item.columnName === fieldName) {
               item.order = order
             } else {
               item.order = ""
             }
    });
      setSortOrder(sortColumnsList);
      DeviceList &&
      actions.setLogisticsDataAll(DeviceList.sort(dynamicsort(fieldName,order)))
  }

  const dynamicsort = (property,order) => {
    var sort_order = 1;
    if(order === "DESC"){
        sort_order = -1;
    }
    return function (obj1, obj2){
        // obj1 should come before obj2 in the sorted order
        if (obj1[property] < obj2[property]){
                return -1 * sort_order;
        // obj1 should come after obj2 in the sorted order
        } else if(obj1[property] > obj2[property]){
                return 1 * sort_order;
        // obj1 and obj2 are the same
        } else{
                return 0 * sort_order;
        }
    }
}

  return (
    <div>
      {openCreateItemPopUp &&
        <CreateItemPopUp
          openCreateItemPopUp={openCreateItemPopUp}
          closeCreateItemPopUp={() => actions.closeCreateItemPopUp()} />}
      {BatchingItems && BatchingItems.length > 0 ? (
        <BatchedOrder batchingItems={BatchingItems} />
      ) : creatItemsArray && creatItemsArray.DeviceList.length > 0 ? (
        <SearchItems />
      ) : (
            <div>
              <div className="flex w-full items-center">
                <h2 className="text-17 font-VodafoneRgBd mt-31 ml-4 w-138">Search</h2>
              </div>
              <div className="flex align-items-center">
                <LabeledInputField
                  className="order-no-input search-font"
                  type="text"
                  name="TradeID"
                  schema="logistics"
                  placeholder="LOGISTICS_LBL_TRADE_ID"
                  onChange={actions.setOrderNumber}
                  register={register}
                  errors={errors}
                  hideLabel
                />
                  {/* <LabeledInputField
                    className="date-input search-font"
                    type="date"
                    register={register}
                    errors={errors}
                    placeholder="LOGISTICS_LBL_START_DATE"
                    onChange={actions.setStartDate}
                    name="StartDate"
                    schema="logistics"
                    hideLabel
                    value={LogisticsInputField ? LogisticsInputField.StartDate:''}
                  /> */}
                  <DateInputField 
                    onChange={actions.setStartDate}
                    placeholder = "LOGISTICS_LBL_START_DATE"
                    value={LogisticsInputField && LogisticsInputField.StartDate ? LogisticsInputField.StartDate:''}
                    format="dd-MM-yyyy"
                  />
                  {/* <LabeledInputField
                    className="date-input search-font"
                    type="date"
                    register={register}
                    errors={errors}
                    placeholder="LOGISTICS_LBL_END_DATE"
                    onChange={actions.setEndDate}
                    name="EndDate"
                    schema="logistics"
                    hideLabel
                    value={LogisticsInputField ? LogisticsInputField.EndDate:''}
                  /> */}

                  <DateInputField 
                    onChange={actions.setEndDate}
                    placeholder = "LOGISTICS_LBL_END_DATE"
                    value={LogisticsInputField && LogisticsInputField.EndDate ? LogisticsInputField.EndDate:''}
                    format="dd-MM-yyyy"
                  />
                <LabeledInputField
                  className="order-no-input search-font"
                  type="text"
                  placeholder="Make"
                  name="Make"
                  schema="logistics"
                  placeholder="LOGISTICS_LBL_MAKE"
                  onChange={actions.setMake}
                  register={register}
                  errors={errors}
                  hideLabel
                />
                <LabeledInputField
                  className="date-input search-font"
                  type="text"
                  name="Model_Varriant"
                  schema="logistics"
                  placeholder="LOGISTICS_LBL_MODEL_VARRIANT"
                  onChange={actions.setModelVarriant}
                  register={register}
                  errors={errors}
                  hideLabel
                />
                <LabeledInputField
                  className="order-no-input w-100 search-font"
                  type="text"
                  name="Condition"
                  schema="logistics"
                  placeholder="LOGISTICS_LBL_CONDITION"
                  onChange={actions.setCondition}
                  register={register}
                  errors={errors}
                  hideLabel
                />
                <button className="purple-button w-80 h-8 search-font" onClick={() => searchLogistics(true)}>
                  {resources.VODAFONE_SEARCH_BUTTON}
                </button>
                <button className="black-button w-80 h-8 search-font ml-10" onClick={() => resetFields()}>
                  {resources.VODAFONE_RESET_BUTTON}
                </button>
                <button className="black-button w-145 h-10 ml-auto xl:ml-25 text-lg leading-21" onClick={() => actions.openCreateItemPopUp()}>
                  {resources.VODAFONE_CREATE_ITEM_BUTTON}
                </button>
                <button
                  className={`${!enableCreateBatch ? 'disable-button' : 'purple-button'} w-145 h-10 ml-15 text-lg leading-21`}
                  onClick={() => createBatch()}
                  disabled={!enableCreateBatch}
                >
                  {resources.VODAFONE_CREATE_BATCH_BUTTON}
                </button>
              </div>

              {DeviceList && DeviceList.length > 0 ? (
                <table className="mt-5 text-sm border-white w-full h-50 border border-gray-2">
                  <thead className=" text-transform: uppercase border-white bg-grey-4 text-white h-50 text-left font-VodafoneRgBd border border-gray-2 ">
                    <tr>
                      <th className="arrow" className="w-15 pl-36 pt-5 pb-5 h-60 leading-17">
                        {ToBeBatched && ToBeBatched.toUpperCase() === 'YES' &&
                          <label className="control control--checkbox">
                            <input type="checkbox" onChange={(e) => toggleSelectionAll(e)} />
                            <div className="control__indicator" />
                          </label>
                        }
                      </th>
                      <th className="h-34 w-10 pl-8 text-sm leading-17 sortEnabledColumn">
                        <span className="columnTitle">
                          {'>=1'} <br />
                          days
                        </span>

                        <SortSelection
                            sortOrder = {sortColumnsList.filter((item) => item.columnName === "LessThan5Days")[0].order}
                            fieldName = "LessThan5Days"
                            className = "pt-9"
                            onClick = {(order, fieldName) => sortData(fieldName, order) }
                        />
                    </th>

                      <th className="leading-17 pl-13 w-20 h-34 ">

                        Reference <br />
                      No.
                    </th>
                      <th className="w-20 w-20 pl-13 leading-17">

                        Date/
                      <br />
                      Time

                    </th>
                      <th className="w-130 pl-13 align-baseline pt-13">Device IMEI</th>
                      <th className="w-20  pl-13 align-baseline pt-13 sortEnabledColumn"><span className="columnTitle">Make</span>
                        <SortSelection
                          sortOrder = {sortColumnsList.filter((item) => item.columnName === "Make")[0].order}
                          fieldName = "Make"
                          onClick = {(order, fieldName) => sortData(fieldName, order) }
                        />
                      </th>
                      <th className=" w-120 pl-13 sortEnabledColumn">
                        <span className="columnTitle">
                          Model/ <br />
                        Variant </span>
                        <SortSelection
                          sortOrder = {sortColumnsList.filter((item) => item.columnName === "Model")[0].order}
                          fieldName = "Model"
                          onClick = {(order, fieldName) => sortData(fieldName, order) }
                          className = "pt-9"
                        />
                    </th>
                      <th className="w-70 pl-13 sortEnabledColumn">
                        <span className="columnTitle">
                          Product
                        <br />
                        Type
                        </span>
                        <SortSelection
                          sortOrder = {sortColumnsList.filter((item) => item.columnName === "ProductType")[0].order}
                          fieldName = "ProductType"
                          onClick = {(order, fieldName) => sortData(fieldName, order) }
                          className = "pt-9"
                        />
                    </th>
                      <th className="w-20 pl-13 align-baseline pt-13 sortEnabledColumn"><span className="columnTitle">Condition</span>
                        <SortSelection
                          sortOrder = {sortColumnsList.filter((item) => item.columnName === "Condition")[0].order}
                          fieldName = "Condition"
                          onClick = {(order, fieldName) => sortData(fieldName, order) }
                        /></th>
                      <th className="w-50  pl-13 sortEnabledColumn">
                        <span className="columnTitle">
                          Store <br />
                        Code</span>
                        <SortSelection
                            sortOrder = {sortColumnsList.filter((item) => item.columnName === "StoreCode")[0].order}
                            fieldName = "StoreCode"
                            onClick = {(order, fieldName) => sortData(fieldName, order) }
                            className = "pt-9"
                          />
                      </th>
                      <th className=" w-160 pl-13  align-baseline pt-13 sortEnabledColumn"><span className="columnTitle">Store Name OR Location</span>
                        <SortSelection
                          sortOrder = {sortColumnsList.filter((item) => item.columnName === "StoreName")[0].order}
                          fieldName = "StoreName"
                          onClick = {(order, fieldName) => sortData(fieldName, order) }
                        />
                      </th>
                      <th className=" w-75 pl-13  align-baseline pt-13 sortEnabledColumn"><span className="columnTitle">Member ID</span>
                        <SortSelection
                          sortOrder = {sortColumnsList.filter((item) => item.columnName === "MemberId")[0].order}
                          fieldName = "MemberId"
                          onClick = {(order, fieldName) => sortData(fieldName, order) }
                        />
                      </th>
                      <th className=" w-120 pl-13  align-baseline pt-13 sortEnabledColumn">
                      <span className="columnTitle">Team member</span>
                        <SortSelection
                          sortOrder = {sortColumnsList.filter((item) => item.columnName === "TeamMember")[0].order}
                          fieldName = "TeamMember"
                          onClick = {(order, fieldName) => sortData(fieldName, order) }
                        />
                      </th>
                      <th className="w-20 pl-13">To be Batched </th>

                      <th className=" w-60 pl-13 align-baseline pt-13">Batch</th>
                      <th className="w-100 pl-13">
                        Consignment <br />
                      No.
                    </th>
                    </tr>
                  </thead>
                  {DeviceList &&
                    DeviceList.map((table_Detail, index) => (
                      <tbody key={index}>
                        <tr className="text-sm text-#000000 border border-gray-2">
                          <td className="w-15 pl-36">
                            {ToBeBatched && ToBeBatched.toUpperCase() === 'YES' &&
                              < label className="control control--checkbox">
                                <input type="checkbox" checked={table_Detail.isSelected} onChange={(e) => toggleSelection(e, table_Detail.ReferenceNo)} />
                                <div className="control__indicator" />
                              </label>
                            }
                          </td>


                          <td className="w-10 h-34 text-sm leading-17 pl-8">{capitalizeFirst(table_Detail.LessThan5Days)}</td>
                          <td className="w-20 h-50 pl-13">{table_Detail.ReferenceNo}</td>
                          <td className="w-20 h-50 pl-13">{table_Detail.DateTime ? <div>
                            {moment((table_Detail.DateTime).split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' + (table_Detail.DateTime).split('T')[1].split('.')[0]}
                             </div> : '-'}
                            </td>
                          <td>
                            <td className="w-130 pl-13"><span className=" font-bold">{'P'}&nbsp;</span>{table_Detail.DeviceIMEI}<br />{table_Detail.SecondDeviceIMEI ? <div>
                              <span className=" font-bold">{'S'}&nbsp;</span>{table_Detail.SecondDeviceIMEI} </div> : ''}</td>
                          </td>
                          <td className="w-20 h-50 pl-13">{table_Detail.Make}</td>
                          <td className="h-50 w-120 pl-13">{table_Detail.Model}</td>
                          <td className="h-50 w-70 pl-13">{table_Detail.ProductType}</td>
                          <td className="w-20 h-50 pl-13">{table_Detail.Condition}</td>
                          <td className="w-50 h-50 pl-13">{table_Detail.StoreCode}</td>
                          <td className="h-50 w-160 pl-13">{table_Detail.StoreName}</td>
                          <td className="h-50 w-75 pl-13">{table_Detail.MemberId}</td>
                          <td className="h-50 w-120 pl-13">{table_Detail.TeamMember}</td>
                          <td className="h-50 w-20 pl-13">{capitalizeFirst(ToBeBatched)}</td>
                          <td className="text-red-1 h-50 w-60 pl-13 font-VodafoneRgBd">{}</td>
                          <td className="h-50 w-100 pl-13">{}</td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              ) : <div className="mt-5">No Records Found</div>}
              {DeviceList && DeviceList.length > 0 &&
                <div className="flex justify-between mt-17">
                  <div>Page {selectedPage + 1} of {pageCount}</div>
                  {_batchError ? <div className="error-text pl-130 ">{_batchError}</div> : null}
                  <Paginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={<a>...</a>}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    selectedPage={selectedPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={(page) => setSelectedPage(page.selected)}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                </div>
              }
            </div>
          )
      }
    </div >
  )
}

export default LogisticsOrderDetails
