import React, { useContext, useEffect } from 'react'
import Done from '../../assets/ic-done.png'
import NotDone from '../../assets/ic-cross.png'
import AdditionalMessagePopUp from './additional-message-popup'
import add from '../../assets/ic-add.png'
import edit from '../../assets/ic-edit_default.png'
import { store } from '../../Store'

const AssismentSummary = (props) => {
  const {
    FunctionalResult, PhysicalResult, actions, DeviceList, TradeId, StoreAgentRole, OrderTradeRoles
  } = props
  const { state } = useContext(store)
  const { IMEIVerificationResponse, primaryAMTAStatus, primaryFMIPStatus, secondaryAMTAStatus,
    secondaryFMIPStatus, primaryIMEIStatus, secondaryIMEIStatus, validCustomer,
    customerVerificationRequested, CustomerInfo, TradeInfo, addMessageFlag, additionalMsgPopUpContent, AMTAStatus, FMIPStatus, orderType } = state
  const openPopUp = state.additionalMsgPopUpOpen;
  const { TradeInStatus, Notes, AssetCatalogName } = TradeInfo
  const { ProceedTradeIn, ProceedTradeUp } = CustomerInfo
  const primaryIMEI = DeviceList && DeviceList.PrimarySerialNbr
  const secondaryIMEI = DeviceList && DeviceList.SecondarySerialNbr
  if (primaryIMEIStatus && secondaryIMEIStatus) {
    IMEIVerificationResponse && IMEIVerificationResponse.map(data => {
      if (data.IMEI === primaryIMEI) {
        actions.setPrimaryIMEIStatus(data.AMTA, data.FMIP)
      }
      else if (data.IMEI === secondaryIMEI) {
        actions.setSecondaryIMEIStatus(data.AMTA, data.FMIP)
      }
    })
  }
  const amtaStatus = secondaryAMTAStatus ? (primaryAMTAStatus === 'PASSED' && secondaryAMTAStatus === 'PASSED' ? 'PASSED' : 'FAILED') : (primaryAMTAStatus === 'PASSED' ? 'PASSED' : 'FAILED')
  const fmipStatus = secondaryFMIPStatus ? (primaryFMIPStatus === 'PASSED' && secondaryFMIPStatus === 'PASSED' ? 'PASSED' : 'FAILED') : (primaryFMIPStatus === 'PASSED' ? 'PASSED' : 'FAILED')
  if ((AMTAStatus === undefined && FMIPStatus === undefined) || (AMTAStatus !== amtaStatus) || (FMIPStatus !== fmipStatus)) {
    actions.setIMEIStatus(amtaStatus, fmipStatus);
  }
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase();

  const processTrade = orderType === 'Trade In' ? ProceedTradeIn : ProceedTradeUp
  const disabledForm = (!validCustomer && customerVerificationRequested) || processTrade === false || (TradeInStatus && (TradeInStatus.toUpperCase() !== "OPEN" && TradeInStatus.toUpperCase() !== "CANCELLED")) || customerVerificationRequested || orderType;
  const getImeiVerifications = (TradeId) => {
    if (OrderTradeRoles['FmipRecheck'][agentRole] === 'SHOW' && (AssetCatalogName && (AssetCatalogName.includes('Apple') || AssetCatalogName.includes('APPLE') || AssetCatalogName.includes('apple')))) {
      actions.getImeiVerifications(TradeId)
    }
  }
  return (
    <div className="assessment-Summary mt-20">
      <div className="">
        <p className="text-22 leading-26 font-VodafoneRgBd">Assessment Summary</p>
        <div className="flex flex-row justify-between items-center mx-auto mt-20">
          <div className="border border-solid border-blue-3 rounded-5 text-center w-1/2 mr-6">
            <p className="leading-16 text-14 font-VodafoneRgBd mt-2 mb-1">AMTA Results</p>
            {amtaStatus === 'PASSED' ? <p className="landing-28 font-17 font-VodafoneRgBd text-green-1 mt-1 mb-2">{amtaStatus}</p> : <p className="landing-28 font-17 font-VodafoneRgBd text-red-1 mt-1 mb-2">{amtaStatus}</p> }
          </div>
          <div className="border border-solid border-blue-3 rounded-5 text-center w-1/2 ml-6 mr-10">
            <p className="leading-16 text-14 font-VodafoneRgBd mt-2 mb-1">{ AssetCatalogName && (AssetCatalogName.includes('Apple') || AssetCatalogName.includes('APPLE') || AssetCatalogName.includes('apple')) ? 'FMIP Lock Results' : 'O/S Lock Results'}</p>
            {fmipStatus === 'PASSED' ? <p className="landing-28 font-17 font-VodafoneRgBd text-green-1 mt-1 mb-2 cursor-pointer" onClick={() => getImeiVerifications(TradeId)}>{fmipStatus}</p> : <p className="landing-28 font-17 font-VodafoneRgBd text-red-1 mt-1 mb-2 cursor-pointer" onClick={() => getImeiVerifications(TradeId)}>{fmipStatus}</p>}
          </div>
        </div>
        <div className="flex flex-row text-sm landing-4 mt-20">
          <div className="mr-40" name="functional">
            <p className="text-base font-VodafoneRgBd leading-19 mb-4 block h-20">Functional Results</p>
            {FunctionalResult
              && FunctionalResult.map((value, key) => (
                <div className="mt-3" key={key}>
                  <img className="inline-block align-top mr-2 xl:mr-m2" src={value.DiagnosticTestResult === 'PASSED' ? Done : NotDone} />
                  <p className="diagnostics-width font-VodafoneRg text-15 leading-18 inline-block">{value.DiagnosticTestName}</p>
                </div>
              ))}
          </div>
          <div className="" name="physical">
            <label className="Checkbox-Container xl:ml-m4">
              <input type="checkbox" id="physicalResult" name="physicalResultCheck" onChange={(e) => actions.setPhysicalResultCheck(e.target.checked)} disabled={disabledForm} />
              <span className={`Checkbox-Mark ${disabledForm ? ' opacity-25 cursor-not-allowed' : ''}`} />
            </label>
            <span className="text-base font-VodafoneRgBd leading-19 text-span w-150 h-5">Physical Results</span>
            {PhysicalResult
              && PhysicalResult.map((value, key) => (
                <div className="mt-3" key={key}>
                  <img className="inline-block align-top mr-2 xl:mr-m2" src={value.DiagnosticTestResult === 'PASSED' ? Done : NotDone} />
                  <p className="diagnostics-width font-VodafoneRg text-15 leading-18 inline-block">{value.DiagnosticTestName}</p>
                </div>
              ))}
            {(addMessageFlag || (TradeInStatus && (TradeInStatus.toUpperCase() !== "OPEN")))
              && !openPopUp &&
              <div className="mt-3">

                <img
                  className={`inline-block align-top mr-2 h-20 w-20${TradeInStatus && (TradeInStatus.toUpperCase() !== "OPEN") ? ' pointer-events-none opacity-25' : ''} ${OrderTradeRoles['AssismentSummaryAddMessage'][agentRole] === 'SHOW' ? '' : ' opacity-25 pointer-events-none'}`}
                  src={edit}
                  disabled={OrderTradeRoles['AssismentSummaryAddMessage'][agentRole] !== 'SHOW'}
                  onClick={() => actions.addFeatureOpenPopUp()} />

                <p className="diagnostics-width font-VodafoneRg text-15 leading-18 inline-block">{TradeInStatus && (TradeInStatus.toUpperCase() !== "OPEN") ? Notes : additionalMsgPopUpContent}</p>
              </div>
            }
            <AdditionalMessagePopUp
              additionalMsgPopUpOpen={openPopUp}
              actions={actions}
            />
            {(!addMessageFlag && (TradeInStatus && (TradeInStatus.toUpperCase() === "OPEN"))) &&
              <img className={`inline-block align-top mr-2 mt-3 ${OrderTradeRoles['AssismentSummaryAddMessage'][agentRole] === 'SHOW' ? '' : ' opacity-25 pointer-events-none'}`}
                onClick={() => actions.addFeatureOpenPopUp()}
                disabled={OrderTradeRoles['AssismentSummaryAddMessage'][agentRole] !== 'SHOW'}
                src={add} />
            }
            {(!addMessageFlag && (TradeInStatus && (TradeInStatus.toUpperCase() === "OPEN"))) &&
              <span>
                <p className="diagnostics-width text-purple-1 font-VodafoneRg text-15 leading-18 inline-block mt-3">For additional messages</p>
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default AssismentSummary
